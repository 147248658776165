@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_Bold.eot");
  src: url("../../fonts/eot/IRANSansWeb_Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_Bold.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_Bold.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_Bold.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_Medium.eot");
  src: url("../../fonts/eot/IRANSansWeb_Medium.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_Medium.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_Medium.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_Medium.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_Light.eot");
  src: url("../../fonts/eot/IRANSansWeb_Light.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_Light.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_Light.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_Light.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_UltraLight.eot");
  src: url("../../fonts/eot/IRANSansWeb_UltraLight.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_UltraLight.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_UltraLight.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_UltraLight.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb.eot");
  src: url("../../fonts/eot/IRANSansWeb.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb.woff") format("woff"); }

@font-face {
  font-family: Lalezar;
  font-display: swap;
  src: url("../../fonts/Lalezar-Regular.ttf") format("truetype"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_Bold.eot");
  src: url("../../fonts/eot/IRANSansWeb_Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_Bold.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_Bold.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_Bold.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_Medium.eot");
  src: url("../../fonts/eot/IRANSansWeb_Medium.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_Medium.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_Medium.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_Medium.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_Light.eot");
  src: url("../../fonts/eot/IRANSansWeb_Light.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_Light.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_Light.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_Light.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_UltraLight.eot");
  src: url("../../fonts/eot/IRANSansWeb_UltraLight.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_UltraLight.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_UltraLight.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_UltraLight.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb.eot");
  src: url("../../fonts/eot/IRANSansWeb.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb.woff") format("woff"); }

@font-face {
  font-family: Lalezar;
  font-display: swap;
  src: url("../../fonts/Lalezar-Regular.ttf") format("truetype"); }

@media screen and (min-width: 1200px) {
  .btn {
    width: 200px;
    height: 40px;
    line-height: 26px;
    box-sizing: border-box;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    background-color: #444444;
    color: white !important;
    padding: 5px 20px;
    border: 2px solid #444444;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    font-family: "IRANSans";
    outline: none; }
    .btn:hover {
      opacity: 0.8; }
    .btn:disabled {
      opacity: 0.5 !important;
      cursor: default !important; }
  .yellowBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important; }
  .yellowBorderBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important;
    border: none; }
  .En .inputWrapper {
    float: left !important; }
    .En .inputWrapper input[type='text'],
    .En .inputWrapper input[type='email'],
    .En .inputWrapper textarea {
      padding-right: 20px !important;
      padding-left: 35px !important; }
    .En .inputWrapper img {
      float: left !important;
      left: 10px;
      right: auto !important;
      top: 10px;
      filter: invert(1); }
  .inputWrapper {
    width: 300px;
    height: 40px;
    float: right;
    position: relative; }
    .inputWrapper input[type='text'],
    .inputWrapper input[type='email'],
    .inputWrapper input[type='number'],
    .inputWrapper textarea {
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-size: 14px;
      outline: none;
      border: none;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      padding: 0 20px;
      padding-right: 35px;
      font-family: "IRANSans";
      line-height: 36px; }
      .inputWrapper input[type='text'].success,
      .inputWrapper input[type='email'].success,
      .inputWrapper input[type='number'].success,
      .inputWrapper textarea.success {
        background-color: rgba(0, 100, 0, 0.5); }
      .inputWrapper input[type='text'].error,
      .inputWrapper input[type='email'].error,
      .inputWrapper input[type='number'].error,
      .inputWrapper textarea.error {
        background-color: rgba(100, 0, 0, 0.5); }
      .inputWrapper input[type='text']:focus ~ img,
      .inputWrapper input[type='email']:focus ~ img,
      .inputWrapper input[type='number']:focus ~ img,
      .inputWrapper textarea:focus ~ img {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1); }
      .inputWrapper input[type='text']::-webkit-input-placeholder,
      .inputWrapper input[type='email']::-webkit-input-placeholder,
      .inputWrapper input[type='number']::-webkit-input-placeholder,
      .inputWrapper textarea::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']::-moz-placeholder,
      .inputWrapper input[type='email']::-moz-placeholder,
      .inputWrapper input[type='number']::-moz-placeholder,
      .inputWrapper textarea::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-ms-input-placeholder,
      .inputWrapper input[type='email']:-ms-input-placeholder,
      .inputWrapper input[type='number']:-ms-input-placeholder,
      .inputWrapper textarea:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-moz-placeholder,
      .inputWrapper input[type='email']:-moz-placeholder,
      .inputWrapper input[type='number']:-moz-placeholder,
      .inputWrapper textarea:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.5); }
    .inputWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: right;
      right: 10px;
      top: 10px;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      filter: invert(1); }
  .selectWrapper {
    width: 100px;
    height: 40px;
    float: right;
    margin-left: 10px;
    position: relative; }
    .selectWrapper select {
      -moz-appearance: none;
      -webkit-appearance: none;
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border: none;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      outline: none;
      font-family: "IRANSans";
      padding: 0 20px;
      padding-left: 35px; }
    .selectWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: left;
      left: 10px;
      top: 10px; }
  .tooltip {
    display: none;
    height: 20px;
    position: absolute;
    padding: 0 10px;
    float: right;
    box-sizing: border-box;
    z-index: 90;
    line-height: 20px;
    white-space: nowrap;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    background-color: #444444;
    color: #ffcc00;
    top: calc(100%);
    right: 50%;
    text-align: center;
    font-size: 12px;
    -moz-transform: translate(50%, 0);
    -o-transform: translate(50%, 0);
    -ms-transform: translate(50%, 0);
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0); }
    .tooltip::before {
      width: 0;
      height: 0;
      content: '';
      position: absolute;
      float: right;
      top: -10px;
      right: 50%;
      border-bottom: 5px solid #444444;
      border-top: 5px solid transparent;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      -moz-transform: translate(50%, 0);
      -o-transform: translate(50%, 0);
      -ms-transform: translate(50%, 0);
      -webkit-transform: translate(50%, 0);
      transform: translate(50%, 0); }
  @keyframes downTooltip {
    0% {
      top: calc(100% - 10px); }
    100% {
      top: calc(100%); } } }

@media screen and (max-width: 1200px) and (min-width: 768px) and (orientation: landscape) {
  .btn {
    width: 200px;
    height: 40px;
    line-height: 26px;
    box-sizing: border-box;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    background-color: #444444;
    color: white !important;
    padding: 5px 20px;
    border: 2px solid #444444;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    font-family: "IRANSans";
    outline: none; }
    .btn:hover {
      opacity: 0.8; }
    .btn:disabled {
      opacity: 0.5 !important;
      cursor: default !important; }
  .yellowBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important; }
  .yellowBorderBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important;
    border: none; }
  .En .inputWrapper {
    float: left !important; }
    .En .inputWrapper input[type='text'],
    .En .inputWrapper input[type='email'],
    .En .inputWrapper textarea {
      padding-right: 20px !important;
      padding-left: 35px !important; }
    .En .inputWrapper img {
      float: left !important;
      left: 10px;
      right: auto !important;
      top: 10px;
      filter: invert(1); }
  .inputWrapper {
    width: 300px;
    height: 40px;
    float: right;
    position: relative; }
    .inputWrapper input[type='text'],
    .inputWrapper input[type='email'],
    .inputWrapper input[type='number'],
    .inputWrapper textarea {
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-size: 14px;
      outline: none;
      border: none;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      padding: 0 20px;
      padding-right: 35px;
      font-family: "IRANSans";
      line-height: 36px; }
      .inputWrapper input[type='text'].success,
      .inputWrapper input[type='email'].success,
      .inputWrapper input[type='number'].success,
      .inputWrapper textarea.success {
        background-color: rgba(0, 100, 0, 0.5); }
      .inputWrapper input[type='text'].error,
      .inputWrapper input[type='email'].error,
      .inputWrapper input[type='number'].error,
      .inputWrapper textarea.error {
        background-color: rgba(100, 0, 0, 0.5); }
      .inputWrapper input[type='text']:focus ~ img,
      .inputWrapper input[type='email']:focus ~ img,
      .inputWrapper input[type='number']:focus ~ img,
      .inputWrapper textarea:focus ~ img {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1); }
      .inputWrapper input[type='text']::-webkit-input-placeholder,
      .inputWrapper input[type='email']::-webkit-input-placeholder,
      .inputWrapper input[type='number']::-webkit-input-placeholder,
      .inputWrapper textarea::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']::-moz-placeholder,
      .inputWrapper input[type='email']::-moz-placeholder,
      .inputWrapper input[type='number']::-moz-placeholder,
      .inputWrapper textarea::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-ms-input-placeholder,
      .inputWrapper input[type='email']:-ms-input-placeholder,
      .inputWrapper input[type='number']:-ms-input-placeholder,
      .inputWrapper textarea:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-moz-placeholder,
      .inputWrapper input[type='email']:-moz-placeholder,
      .inputWrapper input[type='number']:-moz-placeholder,
      .inputWrapper textarea:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.5); }
    .inputWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: right;
      right: 10px;
      top: 10px;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      filter: invert(1); }
  .selectWrapper {
    width: 100px;
    height: 40px;
    float: right;
    margin-left: 10px;
    position: relative; }
    .selectWrapper select {
      -moz-appearance: none;
      -webkit-appearance: none;
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border: none;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      outline: none;
      font-family: "IRANSans";
      padding: 0 20px;
      padding-left: 35px; }
    .selectWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: left;
      left: 10px;
      top: 10px; }
  .tooltip {
    display: none;
    height: 20px;
    position: absolute;
    padding: 0 10px;
    float: right;
    box-sizing: border-box;
    z-index: 90;
    line-height: 20px;
    white-space: nowrap;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    background-color: #444444;
    color: #ffcc00;
    top: calc(100%);
    right: 50%;
    text-align: center;
    font-size: 12px;
    -moz-transform: translate(50%, 0);
    -o-transform: translate(50%, 0);
    -ms-transform: translate(50%, 0);
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0); }
    .tooltip::before {
      width: 0;
      height: 0;
      content: '';
      position: absolute;
      float: right;
      top: -10px;
      right: 50%;
      border-bottom: 5px solid #444444;
      border-top: 5px solid transparent;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      -moz-transform: translate(50%, 0);
      -o-transform: translate(50%, 0);
      -ms-transform: translate(50%, 0);
      -webkit-transform: translate(50%, 0);
      transform: translate(50%, 0); }
  @keyframes downTooltip {
    0% {
      top: calc(100% - 10px); }
    100% {
      top: calc(100%); } } }

@media screen and (max-width: 1200px) and (min-width: 768px) and (orientation: portrait) {
  .btn {
    width: 200px;
    height: 40px;
    line-height: 26px;
    box-sizing: border-box;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    background-color: #444444;
    color: white !important;
    padding: 5px 20px;
    border: 2px solid #444444;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    font-family: "IRANSans"; }
    .btn:hover {
      opacity: 0.8; }
    .btn:disabled {
      opacity: 0.5; }
  .yellowBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important; }
  .yellowBorderBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important;
    border: none; }
  .En .inputWrapper {
    float: left !important; }
    .En .inputWrapper input[type='text'],
    .En .inputWrapper input[type='email'],
    .En .inputWrapper input[type='number'],
    .En .inputWrapper textarea {
      padding-right: 20px !important;
      padding-left: 35px !important; }
    .En .inputWrapper img {
      float: left !important;
      left: 10px;
      right: auto !important;
      top: 10px;
      filter: invert(1); }
  .inputWrapper {
    width: 300px;
    height: 40px;
    float: right;
    position: relative; }
    .inputWrapper input[type='text'],
    .inputWrapper input[type='email'],
    .inputWrapper textarea {
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-size: 14px;
      outline: none;
      border: none;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      padding: 0 20px;
      padding-right: 35px;
      font-family: "IRANSans";
      line-height: 36px; }
      .inputWrapper input[type='text'].success,
      .inputWrapper input[type='email'].success,
      .inputWrapper textarea.success {
        background-color: rgba(0, 100, 0, 0.5); }
      .inputWrapper input[type='text'].error,
      .inputWrapper input[type='email'].error,
      .inputWrapper textarea.error {
        background-color: rgba(100, 0, 0, 0.5); }
      .inputWrapper input[type='text']:focus ~ img,
      .inputWrapper input[type='email']:focus ~ img,
      .inputWrapper textarea:focus ~ img {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1); }
      .inputWrapper input[type='text']::-webkit-input-placeholder,
      .inputWrapper input[type='email']::-webkit-input-placeholder,
      .inputWrapper textarea::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']::-moz-placeholder,
      .inputWrapper input[type='email']::-moz-placeholder,
      .inputWrapper textarea::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-ms-input-placeholder,
      .inputWrapper input[type='email']:-ms-input-placeholder,
      .inputWrapper textarea:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-moz-placeholder,
      .inputWrapper input[type='email']:-moz-placeholder,
      .inputWrapper textarea:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.5); }
    .inputWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: right;
      right: 10px;
      top: 10px;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      filter: invert(1); }
  .selectWrapper {
    width: 100px;
    height: 40px;
    float: right;
    margin-left: 10px;
    position: relative; }
    .selectWrapper select {
      -moz-appearance: none;
      -webkit-appearance: none;
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      border: none;
      color: white;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      outline: none;
      font-family: "IRANSans";
      padding: 0 20px;
      padding-left: 35px; }
      .selectWrapper select:focus ~ img {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1); }
      .selectWrapper select::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.5); }
      .selectWrapper select::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.5); }
      .selectWrapper select:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.5); }
      .selectWrapper select:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.5); }
    .selectWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: left;
      left: 10px;
      top: 10px;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear; } }

@media screen and (max-width: 767px) {
  .btn {
    width: 250px;
    height: 40px;
    line-height: 26px;
    box-sizing: border-box;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    background-color: #444444;
    color: white !important;
    padding: 5px 20px;
    border: 2px solid #444444;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    font-family: "IRANSans" !important; }
    .btn:active {
      opacity: 0.8; }
    .btn:disabled {
      opacity: 0.5; }
  .yellowBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important; }
  .yellowBorderBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important;
    border: none; }
  .En .inputWrapper {
    float: left !important; }
    .En .inputWrapper input[type='text'],
    .En .inputWrapper input[type='email'],
    .En .inputWrapper textarea {
      padding-right: 20px !important;
      padding-left: 35px !important; }
    .En .inputWrapper img {
      float: left !important;
      left: 10px;
      right: auto !important;
      top: 10px;
      filter: invert(1); }
  .inputWrapper {
    max-width: 300px;
    width: 100%;
    height: 40px;
    float: right;
    position: relative; }
    .inputWrapper input[type='text'],
    .inputWrapper input[type='email'],
    .inputWrapper input[type='number'],
    .inputWrapper textarea {
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-size: 12px;
      outline: none;
      border: none;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      padding: 0 20px;
      padding-right: 35px;
      font-family: "IRANSans";
      line-height: 36px; }
      .inputWrapper input[type='text'].success,
      .inputWrapper input[type='email'].success,
      .inputWrapper input[type='number'].success,
      .inputWrapper textarea.success {
        background-color: rgba(0, 100, 0, 0.5); }
      .inputWrapper input[type='text'].error,
      .inputWrapper input[type='email'].error,
      .inputWrapper input[type='number'].error,
      .inputWrapper textarea.error {
        background-color: rgba(100, 0, 0, 0.5); }
      .inputWrapper input[type='text']:focus ~ img,
      .inputWrapper input[type='email']:focus ~ img,
      .inputWrapper input[type='number']:focus ~ img,
      .inputWrapper textarea:focus ~ img {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1); }
      .inputWrapper input[type='text']::-webkit-input-placeholder,
      .inputWrapper input[type='email']::-webkit-input-placeholder,
      .inputWrapper input[type='number']::-webkit-input-placeholder,
      .inputWrapper textarea::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']::-moz-placeholder,
      .inputWrapper input[type='email']::-moz-placeholder,
      .inputWrapper input[type='number']::-moz-placeholder,
      .inputWrapper textarea::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-ms-input-placeholder,
      .inputWrapper input[type='email']:-ms-input-placeholder,
      .inputWrapper input[type='number']:-ms-input-placeholder,
      .inputWrapper textarea:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-moz-placeholder,
      .inputWrapper input[type='email']:-moz-placeholder,
      .inputWrapper input[type='number']:-moz-placeholder,
      .inputWrapper textarea:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.5); }
    .inputWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: right;
      right: 10px;
      top: 10px;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      filter: invert(1); }
  .selectWrapper {
    width: 100px;
    height: 40px;
    float: right;
    margin-left: 10px;
    position: relative; }
    .selectWrapper select {
      -moz-appearance: none;
      -webkit-appearance: none;
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      border: none;
      color: white;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      outline: none;
      font-family: "IRANSans";
      padding: 0 20px;
      padding-left: 35px; }
      .selectWrapper select::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.5); }
      .selectWrapper select::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.5); }
      .selectWrapper select:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.5); }
      .selectWrapper select:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.5); }
    .selectWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: left;
      left: 10px;
      top: 10px;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear; } }

@media screen and (min-width: 1200px) {
  ::-webkit-scrollbar {
    width: 10px; }
  ::-webkit-scrollbar-thumb {
    background-color: #ffcc00; }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */ }
  input[type='number'] {
    -moz-appearance: textfield; }
  body {
    width: 100vw;
    height: 100vh;
    float: left;
    position: relative;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    font-family: "IRANSans";
    direction: rtl;
    background-color: #ffcc00;
    background-image: url("../../media/bgTransparent.png");
    background-position: center center;
    color: #444444;
    font-size: 14px; }
    body a {
      color: inherit !important;
      text-decoration: none; }
    body .main {
      width: 100vw;
      height: 100vh;
      float: left;
      position: relative;
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      overflow-x: hidden;
      font-family: "IRANSans";
      direction: rtl; }
    body .En {
      width: 100vw;
      height: 100vh;
      float: left;
      position: relative;
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      overflow-x: hidden;
      font-family: "IRANSans";
      direction: ltr !important; }
      body .En header ul {
        float: left !important; }
        body .En header ul li {
          direction: ltr !important;
          float: left; }
      body .En header .btn {
        float: left !important; }
      body .En header .logo {
        float: right;
        margin-right: 25px; }
      body .En .menu {
        float: left !important;
        left: -100% !important;
        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important; }
        body .En .menu.open {
          left: 0 !important;
          right: auto !important; }
        body .En .menu .closeBtn {
          float: left !important;
          left: 25px !important;
          right: auto !important;
          filter: invert(0.22); }
        body .En .menu ul li:hover .title {
          -webkit-transform: translateX(15px) !important;
          -moz-transform: translateX(15px) !important;
          -ms-transform: translateX(15px) !important;
          -o-transform: translateX(15px) !important;
          transform: translateX(15px) !important; }
        body .En .menu ul li.pizzaIrPlus:hover .title {
          -webkit-transform: translateX(0px) !important;
          -moz-transform: translateX(0px) !important;
          -ms-transform: translateX(0px) !important;
          -o-transform: translateX(0px) !important;
          transform: translateX(0px) !important; }
        body .En .menu ul li.pizzaIrPlus .title {
          text-align: center !important; }
        body .En .menu ul li .title {
          float: left !important;
          text-align: left; }
        body .En .menu ul li .image {
          float: right !important; }
      body .En .basketNav {
        float: left !important;
        left: -100% !important;
        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important; }
        body .En .basketNav.open {
          left: 0 !important;
          right: auto !important; }
        body .En .basketNav .closeBtn {
          float: left !important;
          left: 25px !important;
          right: auto !important; }
        body .En .basketNav ul li .deleteBtn {
          float: left !important;
          left: 0;
          right: auto !important; }
        body .En .basketNav ul li .image {
          float: left !important; }
        body .En .basketNav ul li .title {
          float: left !important;
          text-align: left !important; }
        body .En .basketNav ul li .priceNCount {
          float: right !important; }
          body .En .basketNav ul li .priceNCount .price span {
            float: left; }
        body .En .basketNav .totalPrice {
          direction: ltr !important; }
          body .En .basketNav .totalPrice span {
            float: left !important; }
            body .En .basketNav .totalPrice span:first-child {
              text-align: left !important; }
            body .En .basketNav .totalPrice span:last-child {
              text-align: right !important; }
      body .En .logInSignUp .label {
        float: left !important;
        text-align: left !important; }
      body .En .logInSignUp .selectWrapper {
        float: right !important; }
        body .En .logInSignUp .selectWrapper select {
          direction: rtl !important; }
      body .En .copyRight {
        float: left !important;
        direction: ltr !important; }
      body .En .socials {
        float: right !important; }
    body .popBg {
      visibility: hidden;
      opacity: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      float: left;
      left: 0;
      z-index: 101;
      transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      -webkit-transition: all 0.3s; }
      body .popBg.show {
        visibility: visible;
        opacity: 1; }
    body .menu {
      overflow: auto;
      width: 400px;
      height: 100vh;
      position: fixed;
      float: right;
      right: -100%;
      background-color: #ffcc00;
      z-index: 102;
      transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      -webkit-transition: all 0.5s;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px; }
      body .menu.open {
        right: 0 !important; }
      body .menu .closeBtn {
        width: 30px;
        height: 30px;
        float: right;
        position: absolute;
        color: #444444;
        right: 25px;
        top: 10px;
        line-height: 50px;
        font-size: 20px;
        text-align: center;
        cursor: pointer;
        filter: invert(0.22); }
      body .menu ul {
        width: 300px;
        margin: 50px;
        padding: 0;
        float: right;
        list-style: none; }
        body .menu ul li {
          width: 100%;
          height: 60px;
          float: right;
          color: #444444;
          font-size: 14px;
          line-height: 60px;
          font-weight: bold; }
          body .menu ul li:hover .title {
            -webkit-transform: translateX(-15px);
            -moz-transform: translateX(-15px);
            -ms-transform: translateX(-15px);
            -o-transform: translateX(-15px);
            transform: translateX(-15px);
            color: #a80000; }
          body .menu ul li:hover .image {
            -webkit-transform: rotate(15deg);
            -moz-transform: rotate(15deg);
            -ms-transform: rotate(15deg);
            -o-transform: rotate(15deg);
            transform: rotate(15deg); }
          body .menu ul li.pizzaIrPlus {
            background-color: #a80000;
            text-align: center !important;
            color: white;
            border-radius: 30px;
            border: 2px solid #a80000;
            box-sizing: border-box; }
            body .menu ul li.pizzaIrPlus:hover {
              background-color: #ffcc00; }
              body .menu ul li.pizzaIrPlus:hover .title {
                -webkit-transform: none;
                -moz-transform: none;
                -ms-transform: none;
                -o-transform: none;
                transform: none;
                color: #a80000; }
            body .menu ul li.pizzaIrPlus .title {
              width: 100%; }
          body .menu ul li a {
            width: 100%;
            height: 100%;
            float: right; }
          body .menu ul li .title {
            float: right;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear; }
          body .menu ul li .image {
            height: 40px;
            float: left;
            margin: 10px 0;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear; }
            body .menu ul li .image img {
              height: 30px;
              float: left;
              filter: invert(0.22);
              margin: 5px; }
    body .basketNav {
      width: 500px;
      height: 100vh;
      position: fixed;
      float: right;
      right: -100%;
      background-color: #444444;
      z-index: 102;
      overflow: hidden;
      transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      -webkit-transition: all 0.5s;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px; }
      body .basketNav.open {
        right: 0 !important; }
      body .basketNav .closeBtn {
        width: 30px;
        height: 30px;
        float: right;
        position: absolute;
        color: white;
        right: 25px;
        top: 10px;
        line-height: 50px;
        font-size: 20px;
        text-align: center;
        cursor: pointer;
        filter: invert(1); }
      body .basketNav ul {
        width: 400px;
        height: calc(100% - 170px);
        margin: 0 50px;
        margin-top: 50px;
        padding: 0;
        float: right;
        list-style: none;
        overflow-x: hidden;
        direction: ltr;
        padding-right: 5px; }
        body .basketNav ul li {
          width: 100%;
          float: right;
          border-bottom: 1px solid #5e5e5e;
          color: white;
          font-size: 14px;
          line-height: 75px;
          position: relative; }
          body .basketNav ul li:last-child {
            border: none; }
          body .basketNav ul li .deleteBtn {
            width: 20px;
            height: 20px;
            color: white;
            float: right;
            position: absolute;
            top: 0;
            right: 0;
            line-height: 20px;
            text-align: center;
            font-size: 0.8em;
            cursor: pointer;
            z-index: 200;
            filter: invert(1); }
          body .basketNav ul li .image {
            height: 65px;
            float: right;
            margin: 5px 0;
            position: relative; }
            body .basketNav ul li .image .myoPlaceholder {
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%; }
              body .basketNav ul li .image .myoPlaceholderIcon {
                width: 90%;
                height: 90%;
                padding: 1px;
                filter: invert(1);
                transition: none; }
                body .basketNav ul li .image .myoPlaceholderIconWrapper {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: absolute;
                  top: 2px;
                  left: 2px;
                  width: 1.1rem;
                  height: 1.1rem;
                  background-color: #a80000;
                  border-radius: 50%;
                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%; }
            body .basketNav ul li .image img {
              height: 65px;
              float: left;
              transition: all 0.5s linear;
              -webkit-transition: all 0.5s linear;
              -moz-transition: all 0.5s linear; }
              body .basketNav ul li .image img:hover {
                -webkit-transform: rotate(360deg);
                -moz-transform: rotate(360deg);
                -ms-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg); }
            body .basketNav ul li .image .size {
              height: 15px;
              position: absolute;
              float: left;
              left: 50%;
              top: calc(100% - 15px);
              padding: 0 10px;
              line-height: 15px;
              font-size: 10px;
              -webkit-transform: translateX(-50%);
              -moz-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              -o-transform: translateX(-50%);
              transform: translateX(-50%);
              background-color: #ffcc00;
              border-radius: 7.5px;
              -webkit-border-radius: 7.5px;
              -moz-border-radius: 7.5px;
              color: #444444; }
          body .basketNav ul li .title {
            width: calc(100% - 175px);
            line-height: 75px;
            float: right;
            position: relative;
            padding: 0 10px;
            box-sizing: border-box;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: right; }
          body .basketNav ul li .priceNCount {
            width: 100px;
            height: 75px;
            float: left; }
            body .basketNav ul li .priceNCount .price {
              width: 100%;
              height: 50%;
              float: left; }
              body .basketNav ul li .priceNCount .price span {
                width: 50%;
                height: 100%;
                float: right;
                text-align: center;
                line-height: 37.5px; }
            body .basketNav ul li .priceNCount .count {
              width: 100%;
              height: 50%;
              float: left; }
              body .basketNav ul li .priceNCount .count .addBtn,
              body .basketNav ul li .priceNCount .count .minusBtn {
                width: 20px;
                height: 20px;
                float: right;
                background-color: #ffcc00;
                color: #444444;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                line-height: 20px;
                font-size: 12px;
                text-align: center;
                margin: calc((37.5px / 2) - 10px) 5px;
                cursor: pointer;
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
                -moz-transition: all 0.3s linear;
                box-sizing: border-box;
                padding: 2px;
                border: 1px solid #444444; }
                body .basketNav ul li .priceNCount .count .addBtn img,
                body .basketNav ul li .priceNCount .count .minusBtn img {
                  filter: invert(0.22); }
                body .basketNav ul li .priceNCount .count .addBtn:hover,
                body .basketNav ul li .priceNCount .count .minusBtn:hover {
                  color: #ffcc00;
                  background-color: #444444;
                  border: 1px solid #ffcc00; }
              body .basketNav ul li .priceNCount .count .numOfUnit {
                width: 40px;
                height: 100%;
                float: right;
                background-color: transparent;
                border: none;
                color: white;
                line-height: 100%;
                font-weight: bold;
                outline: none;
                text-align: center;
                padding: 0; }
      body .basketNav .total {
        width: 100%;
        height: 120px;
        float: right;
        position: absolute;
        bottom: 0;
        background-color: #444444;
        -webkit-box-shadow: 0 0 10px #000;
        -moz-box-shadow: 0 0 10px #000;
        box-shadow: 0 0 10px #000; }
        body .basketNav .total .totalPrice {
          width: 100%;
          height: 40px;
          float: right;
          line-height: 40px;
          padding: 0 50px;
          color: white;
          font-weight: bold;
          margin: 10px 0;
          box-sizing: border-box;
          text-align: center; }
          body .basketNav .total .totalPrice span {
            width: 50%;
            float: right; }
            body .basketNav .total .totalPrice span:first-child {
              text-align: right; }
            body .basketNav .total .totalPrice span:last-child {
              text-align: left; }
      body .basketNav a {
        line-height: 30px;
        position: absolute;
        bottom: 10px;
        float: right;
        right: 50%;
        -webkit-transform: translateX(50%);
        -moz-transform: translateX(50%);
        -ms-transform: translateX(50%);
        -o-transform: translateX(50%);
        transform: translateX(50%); }
    body .logInSignUp {
      display: none;
      width: 500px;
      float: right;
      position: absolute;
      z-index: 101;
      background-color: #ffcc00;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      right: 50%;
      top: 50%;
      -webkit-transform: translate(50%, -50%);
      -moz-transform: translate(50%, -50%);
      -ms-transform: translate(50%, -50%);
      -o-transform: translate(50%, -50%);
      transform: translate(50%, -50%); }
      body .logInSignUp .logIn {
        width: 100%;
        height: 100%;
        float: right; }
      body .logInSignUp .signUp {
        display: none;
        width: 100%;
        height: 100%;
        float: right; }
      body .logInSignUp .closeBtn {
        width: 40px;
        height: 40px;
        float: right;
        position: absolute;
        background-color: #ffcc00;
        text-align: center;
        line-height: 40px;
        top: -20px;
        right: -20px;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        cursor: pointer; }
      body .logInSignUp .title {
        width: 100%;
        float: right;
        text-align: center; }
      body .logInSignUp .label {
        width: 130px;
        height: 40px;
        float: right;
        text-align: right;
        line-height: 40px;
        margin: 10px 0; }
      body .logInSignUp .phoneField {
        width: 210px !important;
        margin: 10px 0;
        margin-left: 0px; }
      body .logInSignUp .emailField {
        width: 330px !important;
        margin: 10px 0; }
      body .logInSignUp .selectWrapper {
        margin: 10px 0;
        float: left !important; }
      body .logInSignUp button {
        position: relative;
        margin: 10px 0;
        margin-top: 20px;
        float: right;
        right: 50%;
        -webkit-transform: translateX(50%);
        -moz-transform: translateX(50%);
        -ms-transform: translateX(50%);
        -o-transform: translateX(50%);
        transform: translateX(50%);
        font-family: "IRANSans";
        clear: both; }
      body .logInSignUp .goToSignUp,
      body .logInSignUp .goToLogIn {
        position: relative;
        float: right;
        right: 50%;
        -webkit-transform: translateX(50%);
        -moz-transform: translateX(50%);
        -ms-transform: translateX(50%);
        -o-transform: translateX(50%);
        transform: translateX(50%);
        font-family: "IRANSans";
        clear: both;
        cursor: pointer;
        border-bottom: 1px solid #ffcc00; }
        body .logInSignUp .goToSignUp:hover,
        body .logInSignUp .goToLogIn:hover {
          border-bottom: 1px dashed #444444; }
    body header {
      width: 100%;
      float: right;
      position: fixed;
      top: 0;
      left: 0;
      padding: 0;
      margin: 0;
      height: 50px;
      color: #444444; }
      body header ul {
        height: 50px;
        float: right;
        padding: 0;
        margin-right: 20px;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        list-style: none; }
        body header ul li {
          width: 30px;
          height: 40px;
          float: right;
          line-height: 20px;
          font-size: 20px;
          text-align: center;
          margin: 10px 7px 0 7px;
          position: relative; }
  @keyframes heartBeat {
    0% {
      transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      background-color: #a80000; }
    50% {
      transform: scale(1.3, 1.3);
      -webkit-transform: scale(1.3, 1.3);
      -moz-transform: scale(1.3, 1.3);
      background-color: #a80000; }
    100% {
      transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      background-color: #a80000; } }
          body header ul li .shoppingCartIconWrapper {
            width: 100%;
            height: 100%;
            cursor: pointer;
            background-color: transparent !important; }
            body header ul li .shoppingCartIconWrapper.active {
              animation: heartBeat 0.3s infinite;
              -webkit-animation: heartBeat 0.3s infinite; }
          body header ul li .numOfOrder {
            min-width: 16px;
            height: 16px;
            position: absolute;
            float: right;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            background-color: #444444;
            color: #ffcc00;
            line-height: 7px;
            font-size: 10px;
            text-align: center;
            padding: 5px;
            box-sizing: border-box;
            border: 1px solid #ffcc00;
            top: calc(100% - 20px);
            left: calc(100% - 9px);
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear; }
            body header ul li .numOfOrder.active {
              animation: heartBeat 0.3s infinite;
              -webkit-animation: heartBeat 0.3s infinite; }
          body header ul li .userGreeting {
            min-width: 100px;
            position: absolute;
            float: left;
            top: calc(100% - 5px);
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
            font-size: 10px;
            text-align: center;
            white-space: nowrap; }
          body header ul li a {
            width: 100%;
            height: 100%;
            float: right;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear; }
            body header ul li a img {
              filter: invert(0.24); }
            body header ul li a:hover {
              opacity: 0.8; }
      body header > a {
        margin: 5px;
        float: right; }
      body header .logo {
        float: left;
        margin-left: 25px;
        margin-top: 5px;
        height: 50px; }
  @keyframes transformLogoType {
    0% {
      transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -webkit-transform: translateX(-100%); }
    100% {
      transform: translateX(0%);
      -moz-transform: translateX(0%);
      -ms-transform: translateX(0%);
      -webkit-transform: translateX(0%); } }
        body header .logo svg,
        body header .logo img {
          height: 100%;
          filter: invert(0.28); }
          body header .logo svg #logoType g,
          body header .logo img #logoType g {
            position: relative;
            float: left;
            transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            animation: transformLogoType 1s 1s linear forwards; }
    body .container {
      width: 100%;
      height: calc(100% - 100px);
      float: right;
      padding: 1rem 2rem;
      margin-top: 50px;
      box-sizing: border-box;
      position: relative;
      overflow-y: scroll; }
    body .popBg3 {
      display: none;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      float: left;
      left: 0;
      z-index: 99; }
    body .showSupportingRegion {
      display: none;
      width: 800px;
      height: 520px;
      float: right;
      position: absolute;
      background-color: #ffcc00;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      right: 50%;
      top: 50%;
      margin-right: -400px;
      margin-top: -300px;
      z-index: 110;
      padding: 10px; }
      body .showSupportingRegion .title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        float: right;
        text-align: center; }
      body .showSupportingRegion #map {
        width: 100%;
        height: 400px;
        background-color: #444444;
        float: right;
        margin: 10px 0; }
      body .showSupportingRegion .locationName {
        height: 40px;
        float: right;
        position: relative;
        margin: 10px 0;
        right: 50%;
        -moz-transform: translate(50%, 0);
        -o-transform: translate(50%, 0);
        -ms-transform: translate(50%, 0);
        -webkit-transform: translate(50%, 0);
        transform: translate(50%, 0); }
        body .showSupportingRegion .locationName img {
          width: 40px;
          height: 40px;
          float: right;
          padding: 10px;
          box-sizing: border-box; }
        body .showSupportingRegion .locationName span {
          line-height: 40px; }
      body .showSupportingRegion button {
        position: relative;
        clear: both;
        float: right;
        right: 50%;
        transform: translateX(50%);
        font-family: "IRANSans"; }
  @keyframes slideUp {
    0% {
      bottom: -50px; }
    100% {
      bottom: 0px; } }
  @keyframes slideDown {
    0% {
      bottom: 0px; }
    100% {
      bottom: -50px; } }
  @keyframes fadeInShadow {
    0% {
      -webkit-box-shadow: 0 0 0 #444;
      -moz-box-shadow: 0 0 0 #444;
      box-shadow: 0 0 0 #444; }
    100% {
      -webkit-box-shadow: 0 0 10px #444;
      -moz-box-shadow: 0 0 10px #444;
      box-shadow: 0 0 10px #444; } }
  @keyframes fadeOutShadow {
    0% {
      -webkit-box-shadow: 0 0 10px #444;
      -moz-box-shadow: 0 0 10px #444;
      box-shadow: 0 0 10px #444; }
    100% {
      -webkit-box-shadow: 0 0 0 #444;
      -moz-box-shadow: 0 0 0 #444;
      box-shadow: 0 0 0 #444; } }
    body .notifBottom {
      width: 100vw;
      height: 50px;
      position: fixed;
      float: right;
      bottom: -50px;
      right: 0;
      z-index: 100;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      /* animation: name duration timing-function delay iteration-count direction fill-mode play-state; */ }
      body .notifBottom .error {
        width: 100%;
        height: 100%;
        float: right;
        background-color: crimson;
        color: white;
        position: relative; }
        body .notifBottom .error .closeBtn {
          width: 30px;
          height: 30px;
          float: right;
          background-color: #c51236;
          color: white;
          text-align: center;
          line-height: 30px;
          box-sizing: border-box;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          position: absolute;
          right: 50px;
          top: 10px;
          cursor: pointer;
          transition: all 0.3s linear;
          -webkit-transition: all 0.3s linear;
          -moz-transition: all 0.3s linear; }
          body .notifBottom .error .closeBtn:hover {
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
          body .notifBottom .error .closeBtn i {
            line-height: 30px; }
        body .notifBottom .error .log {
          width: 100%;
          height: 50px;
          float: right;
          padding: 0 90px;
          box-sizing: border-box;
          text-align: center;
          line-height: 50px; }
      body .notifBottom .success {
        width: 100%;
        height: 100%;
        float: right;
        background-color: forestgreen;
        color: white;
        position: relative; }
        body .notifBottom .success .closeBtn {
          width: 30px;
          height: 30px;
          float: right;
          background-color: #1d771d;
          color: white;
          text-align: center;
          line-height: 30px;
          box-sizing: border-box;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          position: absolute;
          right: 50px;
          top: 10px;
          cursor: pointer;
          transition: all 0.3s linear;
          -webkit-transition: all 0.3s linear;
          -moz-transition: all 0.3s linear; }
          body .notifBottom .success .closeBtn:hover {
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
          body .notifBottom .success .closeBtn i {
            line-height: 30px; }
        body .notifBottom .success .log {
          width: 100%;
          height: 50px;
          float: right;
          padding: 0 90px;
          box-sizing: border-box;
          text-align: center;
          line-height: 50px; }
      body .notifBottom.active {
        animation: slideUp 0.6s 0.6s forwards; }
        body .notifBottom.active .log {
          animation: fadeInShadow 0.6s 0.6s forwards; }
      body .notifBottom.deactive {
        width: 100vw;
        height: 50px;
        position: fixed;
        float: right;
        bottom: 0;
        right: 0;
        z-index: 100;
        transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        animation: slideDown 0.4s 0.2s forwards; }
        body .notifBottom.deactive .log {
          animation: fadeOutShadow 0.3s 0.3s backwards; }
    body footer {
      width: 100vw;
      height: 50px;
      float: right;
      position: fixed;
      bottom: 0; }
      body footer .copyRight {
        float: right;
        line-height: 50px;
        padding: 0 10px;
        font-size: 12px; }
      body footer ul.socials {
        float: left;
        padding: 0;
        margin: 0;
        list-style: none; }
        body footer ul.socials li {
          width: 30px;
          height: 30px;
          float: left;
          text-align: center;
          display: inline-block;
          line-height: 30px;
          margin: 10px 5px;
          font-size: 20px; }
          body footer ul.socials li a {
            width: 100%;
            height: 100%;
            float: right;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear; }
            body footer ul.socials li a:hover {
              opacity: 0.8; }
    body .preLoader {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      float: left;
      -moz-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: 2;
      width: 40px; }
      body .preLoader.hidden {
        display: none; }
      body .preLoader.darkBtn {
        width: 200px;
        height: 40px;
        background-color: #444444;
        border-radius: 20px; }
        body .preLoader.darkBtn .spinner.spinner-1:after {
          border-bottom-color: #ffcc00 !important;
          border-right-color: #ffcc00 !important; }
        body .preLoader.darkBtn .spinner.spinner-1:before {
          border-bottom-color: #ffcc00 !important;
          border-right-color: #ffcc00 !important; }
      body .preLoader.lightBtn {
        width: 200px;
        height: 40px;
        background-color: #ffcc00;
        border-radius: 20px;
        border: 2px solid #444444; }
        body .preLoader.lightBtn .spinner.spinner-1:after {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
        body .preLoader.lightBtn .spinner.spinner-1:before {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
      body .preLoader.list {
        width: 100%;
        height: 40px;
        background-color: transparent;
        position: relative !important;
        top: auto !important;
        left: auto !important;
        transform: none;
        box-sizing: border-box;
        margin-top: 20px; }
        body .preLoader.list .spinner.spinner-1:after {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
        body .preLoader.list .spinner.spinner-1:before {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
      body .preLoader.fullScreen {
        position: fixed !important;
        float: left;
        top: 50% !important;
        left: 50% !important;
        background-color: transparent !important;
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
        body .preLoader.fullScreen .spinner.spinner-1:after {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
        body .preLoader.fullScreen .spinner.spinner-1:before {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
      body .preLoader .spinner-block {
        width: 40px;
        text-align: center;
        position: relative;
        float: left;
        left: 50%;
        top: 50%;
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        /* spinner-1 styles */ }
        body .preLoader .spinner-block .spinner {
          position: relative;
          width: 40px;
          height: 40px; }
          body .preLoader .spinner-block .spinner:before, body .preLoader .spinner-block .spinner:after {
            content: '';
            display: block;
            position: absolute;
            border-width: 2px;
            border-style: solid;
            border-radius: 50%; }
  @keyframes rotate-animation {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
  @keyframes anti-rotate-animation {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(-360deg); } }
        body .preLoader .spinner-block .spinner.spinner-1:before {
          width: 32px;
          height: 32px;
          border-bottom-color: #444444;
          border-right-color: #444444;
          border-top-color: rgba(68, 68, 68, 0);
          border-left-color: rgba(68, 68, 68, 0);
          top: 50%;
          left: 50%;
          float: left;
          position: absolute;
          margin-top: -18px;
          margin-left: -18px;
          animation: rotate-animation 1s linear 0s infinite; }
        body .preLoader .spinner-block .spinner.spinner-1:after {
          width: 16px;
          height: 16px;
          border-bottom-color: #444444;
          border-right-color: #444444;
          border-top-color: rgba(68, 68, 68, 0);
          border-left-color: rgba(68, 68, 68, 0);
          top: 50%;
          left: 50%;
          float: left;
          position: absolute;
          margin-top: -10px;
          margin-left: -10px;
          animation: anti-rotate-animation 0.85s linear 0s infinite; } }

@media screen and (max-width: 1200px) and (min-width: 768px) and (orientation: landscape) {
  ::-webkit-scrollbar {
    width: 10px; }
  ::-webkit-scrollbar-thumb {
    background-color: #ffcc00; }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */ }
  input[type='number'] {
    -moz-appearance: textfield; }
  body {
    width: 100vw;
    height: 100vh;
    float: left;
    position: relative;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    font-family: "IRANSans";
    direction: rtl;
    background-color: #ffcc00;
    background-image: url("../../media/bgTransparent.png");
    background-position: center center;
    color: #444444;
    font-size: 14px; }
    body a {
      color: inherit !important;
      text-decoration: none; }
    body .main {
      width: 100vw;
      height: 100vh;
      float: left;
      position: relative;
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      overflow-x: hidden;
      font-family: "IRANSans";
      direction: rtl; }
    body .En {
      width: 100vw;
      height: 100vh;
      float: left;
      position: relative;
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      overflow-x: hidden;
      font-family: "IRANSans";
      direction: ltr !important; }
      body .En header ul {
        float: left !important; }
        body .En header ul li {
          direction: ltr !important;
          float: left; }
      body .En header .btn {
        float: left !important; }
      body .En header .logo {
        float: right;
        margin-right: 25px; }
      body .En .menu {
        float: left !important;
        left: -100% !important;
        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important; }
        body .En .menu.open {
          left: 0 !important;
          right: auto !important; }
        body .En .menu .closeBtn {
          float: left !important;
          left: 25px !important;
          right: auto !important;
          filter: invert(0.22); }
        body .En .menu ul li:hover .title {
          -webkit-transform: translateX(15px) !important;
          -moz-transform: translateX(15px) !important;
          -ms-transform: translateX(15px) !important;
          -o-transform: translateX(15px) !important;
          transform: translateX(15px) !important; }
        body .En .menu ul li.pizzaIrPlus:hover .title {
          -webkit-transform: translateX(0px) !important;
          -moz-transform: translateX(0px) !important;
          -ms-transform: translateX(0px) !important;
          -o-transform: translateX(0px) !important;
          transform: translateX(0px) !important; }
        body .En .menu ul li.pizzaIrPlus .title {
          text-align: center !important; }
        body .En .menu ul li .title {
          float: left !important;
          text-align: left; }
        body .En .menu ul li .image {
          float: right !important; }
      body .En .basketNav {
        float: left !important;
        left: -100% !important;
        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important; }
        body .En .basketNav.open {
          left: 0 !important;
          right: auto !important; }
        body .En .basketNav .closeBtn {
          float: left !important;
          left: 25px !important;
          right: auto !important; }
        body .En .basketNav ul li .deleteBtn {
          float: left !important;
          left: 0;
          right: auto !important; }
        body .En .basketNav ul li .image {
          float: left !important; }
        body .En .basketNav ul li .title {
          float: left !important;
          text-align: left !important; }
        body .En .basketNav ul li .priceNCount {
          float: right !important; }
          body .En .basketNav ul li .priceNCount .price span {
            float: left; }
        body .En .basketNav .totalPrice {
          direction: ltr !important; }
          body .En .basketNav .totalPrice span {
            float: left !important; }
            body .En .basketNav .totalPrice span:first-child {
              text-align: left !important; }
            body .En .basketNav .totalPrice span:last-child {
              text-align: right !important; }
      body .En .logInSignUp .label {
        float: left !important;
        text-align: left !important; }
      body .En .logInSignUp .selectWrapper {
        float: right !important; }
        body .En .logInSignUp .selectWrapper select {
          direction: rtl !important; }
      body .En .copyRight {
        float: left !important;
        direction: ltr !important; }
      body .En .socials {
        float: right !important; }
    body .popBg {
      visibility: hidden;
      opacity: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      float: left;
      left: 0;
      z-index: 101;
      transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      -webkit-transition: all 0.3s; }
      body .popBg.show {
        visibility: visible;
        opacity: 1; }
    body .menu {
      overflow: auto;
      width: 400px;
      height: 100vh;
      position: fixed;
      float: right;
      right: -100%;
      background-color: #ffcc00;
      z-index: 102;
      transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      -webkit-transition: all 0.5s;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px; }
      body .menu.open {
        right: 0 !important; }
      body .menu .closeBtn {
        width: 30px;
        height: 30px;
        float: right;
        position: absolute;
        color: #444444;
        right: 25px;
        top: 10px;
        line-height: 50px;
        font-size: 20px;
        text-align: center;
        cursor: pointer;
        filter: invert(0.22); }
      body .menu ul {
        width: 300px;
        margin: 50px;
        padding: 0;
        float: right;
        list-style: none; }
        body .menu ul li {
          width: 100%;
          height: 60px;
          float: right;
          color: #444444;
          font-size: 14px;
          line-height: 60px;
          font-weight: bold; }
          body .menu ul li:hover .title {
            -webkit-transform: translateX(-15px);
            -moz-transform: translateX(-15px);
            -ms-transform: translateX(-15px);
            -o-transform: translateX(-15px);
            transform: translateX(-15px);
            color: #a80000; }
          body .menu ul li:hover .image {
            -webkit-transform: rotate(15deg);
            -moz-transform: rotate(15deg);
            -ms-transform: rotate(15deg);
            -o-transform: rotate(15deg);
            transform: rotate(15deg); }
          body .menu ul li.pizzaIrPlus {
            background-color: #a80000;
            text-align: center !important;
            color: white;
            border-radius: 30px;
            border: 2px solid #a80000;
            box-sizing: border-box; }
            body .menu ul li.pizzaIrPlus:hover {
              background-color: #ffcc00; }
              body .menu ul li.pizzaIrPlus:hover .title {
                -webkit-transform: none;
                -moz-transform: none;
                -ms-transform: none;
                -o-transform: none;
                transform: none;
                color: #a80000; }
            body .menu ul li.pizzaIrPlus .title {
              width: 100%; }
          body .menu ul li a {
            width: 100%;
            height: 100%;
            float: right; }
          body .menu ul li .title {
            float: right;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear; }
          body .menu ul li .image {
            height: 40px;
            float: left;
            margin: 10px 0;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear; }
            body .menu ul li .image img {
              height: 30px;
              float: left;
              filter: invert(0.22);
              margin: 5px; }
    body .basketNav {
      width: 500px;
      height: 100vh;
      position: fixed;
      float: right;
      right: -100%;
      background-color: #444444;
      z-index: 102;
      overflow: hidden;
      transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      -webkit-transition: all 0.5s;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px; }
      body .basketNav.open {
        right: 0 !important; }
      body .basketNav .closeBtn {
        width: 30px;
        height: 30px;
        float: right;
        position: absolute;
        color: white;
        right: 25px;
        top: 10px;
        line-height: 50px;
        font-size: 20px;
        text-align: center;
        cursor: pointer;
        filter: invert(1); }
      body .basketNav ul {
        width: 400px;
        height: calc(100% - 170px);
        margin: 0 50px;
        margin-top: 50px;
        padding: 0;
        float: right;
        list-style: none;
        overflow-x: hidden;
        direction: ltr;
        padding-right: 5px; }
        body .basketNav ul li {
          width: 100%;
          float: right;
          border-bottom: 1px solid #5e5e5e;
          color: white;
          font-size: 14px;
          line-height: 75px;
          position: relative; }
          body .basketNav ul li:last-child {
            border: none; }
          body .basketNav ul li .deleteBtn {
            width: 20px;
            height: 20px;
            color: white;
            float: right;
            position: absolute;
            top: 0;
            right: 0;
            line-height: 20px;
            text-align: center;
            font-size: 0.8em;
            cursor: pointer;
            z-index: 200;
            filter: invert(1); }
          body .basketNav ul li .image {
            height: 65px;
            float: right;
            margin: 5px 0;
            position: relative; }
            body .basketNav ul li .image .myoPlaceholder {
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%; }
              body .basketNav ul li .image .myoPlaceholderIcon {
                width: 90%;
                height: 90%;
                padding: 1px;
                filter: invert(1);
                transition: none; }
                body .basketNav ul li .image .myoPlaceholderIconWrapper {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: absolute;
                  top: 2px;
                  left: 2px;
                  width: 1.1rem;
                  height: 1.1rem;
                  background-color: #a80000;
                  border-radius: 50%;
                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%; }
            body .basketNav ul li .image img {
              height: 65px;
              float: left;
              transition: all 0.5s linear;
              -webkit-transition: all 0.5s linear;
              -moz-transition: all 0.5s linear; }
              body .basketNav ul li .image img:hover {
                -webkit-transform: rotate(360deg);
                -moz-transform: rotate(360deg);
                -ms-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg); }
            body .basketNav ul li .image .size {
              height: 15px;
              position: absolute;
              float: left;
              left: 50%;
              top: calc(100% - 15px);
              padding: 0 10px;
              line-height: 15px;
              font-size: 10px;
              -webkit-transform: translateX(-50%);
              -moz-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              -o-transform: translateX(-50%);
              transform: translateX(-50%);
              background-color: #ffcc00;
              border-radius: 7.5px;
              -webkit-border-radius: 7.5px;
              -moz-border-radius: 7.5px;
              color: #444444; }
          body .basketNav ul li .title {
            width: calc(100% - 175px);
            line-height: 75px;
            float: right;
            position: relative;
            padding: 0 10px;
            box-sizing: border-box;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: right; }
          body .basketNav ul li .priceNCount {
            width: 100px;
            height: 75px;
            float: left; }
            body .basketNav ul li .priceNCount .price {
              width: 100%;
              height: 50%;
              float: left; }
              body .basketNav ul li .priceNCount .price span {
                width: 50%;
                height: 100%;
                float: right;
                text-align: center;
                line-height: 37.5px; }
            body .basketNav ul li .priceNCount .count {
              width: 100%;
              height: 50%;
              float: left; }
              body .basketNav ul li .priceNCount .count .addBtn,
              body .basketNav ul li .priceNCount .count .minusBtn {
                width: 20px;
                height: 20px;
                float: right;
                background-color: #ffcc00;
                color: #444444;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                line-height: 20px;
                font-size: 12px;
                text-align: center;
                margin: calc((37.5px / 2) - 10px) 5px;
                cursor: pointer;
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
                -moz-transition: all 0.3s linear;
                box-sizing: border-box;
                padding: 2px;
                border: 1px solid #444444; }
                body .basketNav ul li .priceNCount .count .addBtn img,
                body .basketNav ul li .priceNCount .count .minusBtn img {
                  filter: invert(0.22); }
                body .basketNav ul li .priceNCount .count .addBtn:hover,
                body .basketNav ul li .priceNCount .count .minusBtn:hover {
                  color: #ffcc00;
                  background-color: #444444;
                  border: 1px solid #ffcc00; }
              body .basketNav ul li .priceNCount .count .numOfUnit {
                width: 40px;
                height: 100%;
                float: right;
                background-color: transparent;
                border: none;
                color: white;
                line-height: 100%;
                font-weight: bold;
                outline: none;
                text-align: center;
                padding: 0; }
      body .basketNav .total {
        width: 100%;
        height: 120px;
        float: right;
        position: absolute;
        bottom: 0;
        background-color: #444444;
        -webkit-box-shadow: 0 0 10px #000;
        -moz-box-shadow: 0 0 10px #000;
        box-shadow: 0 0 10px #000; }
        body .basketNav .total .totalPrice {
          width: 100%;
          height: 40px;
          float: right;
          line-height: 40px;
          padding: 0 50px;
          color: white;
          font-weight: bold;
          margin: 10px 0;
          box-sizing: border-box;
          text-align: center; }
          body .basketNav .total .totalPrice span {
            width: 50%;
            float: right; }
            body .basketNav .total .totalPrice span:first-child {
              text-align: right; }
            body .basketNav .total .totalPrice span:last-child {
              text-align: left; }
      body .basketNav a {
        line-height: 30px;
        position: absolute;
        bottom: 10px;
        float: right;
        right: 50%;
        -webkit-transform: translateX(50%);
        -moz-transform: translateX(50%);
        -ms-transform: translateX(50%);
        -o-transform: translateX(50%);
        transform: translateX(50%); }
    body .logInSignUp {
      display: none;
      width: 500px;
      float: right;
      position: absolute;
      z-index: 101;
      background-color: #ffcc00;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      right: 50%;
      top: 50%;
      -webkit-transform: translate(50%, -50%);
      -moz-transform: translate(50%, -50%);
      -ms-transform: translate(50%, -50%);
      -o-transform: translate(50%, -50%);
      transform: translate(50%, -50%); }
      body .logInSignUp .logIn {
        width: 100%;
        height: 100%;
        float: right; }
      body .logInSignUp .signUp {
        display: none;
        width: 100%;
        height: 100%;
        float: right; }
      body .logInSignUp .closeBtn {
        width: 40px;
        height: 40px;
        float: right;
        position: absolute;
        background-color: #ffcc00;
        text-align: center;
        line-height: 40px;
        top: -20px;
        right: -20px;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        cursor: pointer; }
      body .logInSignUp .title {
        width: 100%;
        float: right;
        text-align: center; }
      body .logInSignUp .label {
        width: 130px;
        height: 40px;
        float: right;
        text-align: right;
        line-height: 40px;
        margin: 10px 0; }
      body .logInSignUp .phoneField {
        width: 210px !important;
        margin: 10px 0;
        margin-left: 0px; }
      body .logInSignUp .emailField {
        width: 330px !important;
        margin: 10px 0; }
      body .logInSignUp .selectWrapper {
        margin: 10px 0;
        float: left !important; }
      body .logInSignUp button {
        position: relative;
        margin: 10px 0;
        margin-top: 20px;
        float: right;
        right: 50%;
        -webkit-transform: translateX(50%);
        -moz-transform: translateX(50%);
        -ms-transform: translateX(50%);
        -o-transform: translateX(50%);
        transform: translateX(50%);
        font-family: "IRANSans";
        clear: both; }
      body .logInSignUp .goToSignUp,
      body .logInSignUp .goToLogIn {
        position: relative;
        float: right;
        right: 50%;
        -webkit-transform: translateX(50%);
        -moz-transform: translateX(50%);
        -ms-transform: translateX(50%);
        -o-transform: translateX(50%);
        transform: translateX(50%);
        font-family: "IRANSans";
        clear: both;
        cursor: pointer;
        border-bottom: 1px solid #ffcc00; }
        body .logInSignUp .goToSignUp:hover,
        body .logInSignUp .goToLogIn:hover {
          border-bottom: 1px dashed #444444; }
    body header {
      width: 100%;
      float: right;
      position: fixed;
      top: 0;
      left: 0;
      padding: 0;
      margin: 0;
      height: 50px;
      color: #444444; }
      body header ul {
        height: 50px;
        float: right;
        padding: 0;
        margin-right: 20px;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        list-style: none; }
        body header ul li {
          width: 30px;
          height: 40px;
          float: right;
          line-height: 20px;
          font-size: 20px;
          text-align: center;
          margin: 10px 7px 0 7px;
          position: relative; }
  @keyframes heartBeat {
    0% {
      transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      background-color: #a80000; }
    50% {
      transform: scale(1.3, 1.3);
      -webkit-transform: scale(1.3, 1.3);
      -moz-transform: scale(1.3, 1.3);
      background-color: #a80000; }
    100% {
      transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      background-color: #a80000; } }
          body header ul li .shoppingCartIconWrapper {
            width: 100%;
            height: 100%;
            cursor: pointer;
            background-color: transparent !important; }
            body header ul li .shoppingCartIconWrapper.active {
              animation: heartBeat 0.3s infinite;
              -webkit-animation: heartBeat 0.3s infinite; }
          body header ul li .numOfOrder {
            min-width: 16px;
            height: 16px;
            position: absolute;
            float: right;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            background-color: #444444;
            color: #ffcc00;
            line-height: 7px;
            font-size: 10px;
            text-align: center;
            padding: 5px;
            box-sizing: border-box;
            border: 1px solid #ffcc00;
            top: calc(100% - 20px);
            left: calc(100% - 9px);
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear; }
            body header ul li .numOfOrder.active {
              animation: heartBeat 0.3s infinite;
              -webkit-animation: heartBeat 0.3s infinite; }
          body header ul li .userGreeting {
            min-width: 100px;
            position: absolute;
            float: left;
            top: calc(100% - 5px);
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
            font-size: 10px;
            text-align: center;
            white-space: nowrap; }
          body header ul li a {
            width: 100%;
            height: 100%;
            float: right;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear; }
            body header ul li a img {
              filter: invert(0.24); }
            body header ul li a:hover {
              opacity: 0.8; }
      body header > a {
        margin: 5px;
        float: right; }
      body header .logo {
        float: left;
        margin-left: 25px;
        margin-top: 5px;
        height: 50px; }
  @keyframes transformLogoType {
    0% {
      transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -webkit-transform: translateX(-100%); }
    100% {
      transform: translateX(0%);
      -moz-transform: translateX(0%);
      -ms-transform: translateX(0%);
      -webkit-transform: translateX(0%); } }
        body header .logo svg,
        body header .logo img {
          height: 100%;
          filter: invert(0.28); }
          body header .logo svg #logoType g,
          body header .logo img #logoType g {
            position: relative;
            float: left;
            transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            animation: transformLogoType 1s 1s linear forwards; }
    body .container {
      width: 100%;
      height: calc(100% - 100px);
      float: right;
      padding: 1rem 2rem;
      margin-top: 50px;
      box-sizing: border-box;
      position: relative;
      overflow-y: scroll; }
    body .popBg3 {
      display: none;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      float: left;
      left: 0;
      z-index: 99; }
    body .showSupportingRegion {
      display: none;
      width: 800px;
      height: 520px;
      float: right;
      position: absolute;
      background-color: #ffcc00;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      right: 50%;
      top: 50%;
      margin-right: -400px;
      margin-top: -300px;
      z-index: 110;
      padding: 10px; }
      body .showSupportingRegion .title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        float: right;
        text-align: center; }
      body .showSupportingRegion #map {
        width: 100%;
        height: 400px;
        background-color: #444444;
        float: right;
        margin: 10px 0; }
      body .showSupportingRegion .locationName {
        height: 40px;
        float: right;
        position: relative;
        margin: 10px 0;
        right: 50%;
        -moz-transform: translate(50%, 0);
        -o-transform: translate(50%, 0);
        -ms-transform: translate(50%, 0);
        -webkit-transform: translate(50%, 0);
        transform: translate(50%, 0); }
        body .showSupportingRegion .locationName img {
          width: 40px;
          height: 40px;
          float: right;
          padding: 10px;
          box-sizing: border-box; }
        body .showSupportingRegion .locationName span {
          line-height: 40px; }
      body .showSupportingRegion button {
        position: relative;
        clear: both;
        float: right;
        right: 50%;
        transform: translateX(50%);
        font-family: "IRANSans"; }
  @keyframes slideUp {
    0% {
      bottom: -50px; }
    100% {
      bottom: 0px; } }
  @keyframes slideDown {
    0% {
      bottom: 0px; }
    100% {
      bottom: -50px; } }
  @keyframes fadeInShadow {
    0% {
      -webkit-box-shadow: 0 0 0 #444;
      -moz-box-shadow: 0 0 0 #444;
      box-shadow: 0 0 0 #444; }
    100% {
      -webkit-box-shadow: 0 0 10px #444;
      -moz-box-shadow: 0 0 10px #444;
      box-shadow: 0 0 10px #444; } }
  @keyframes fadeOutShadow {
    0% {
      -webkit-box-shadow: 0 0 10px #444;
      -moz-box-shadow: 0 0 10px #444;
      box-shadow: 0 0 10px #444; }
    100% {
      -webkit-box-shadow: 0 0 0 #444;
      -moz-box-shadow: 0 0 0 #444;
      box-shadow: 0 0 0 #444; } }
    body .notifBottom {
      width: 100vw;
      height: 50px;
      position: fixed;
      float: right;
      bottom: -50px;
      right: 0;
      z-index: 100;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      /* animation: name duration timing-function delay iteration-count direction fill-mode play-state; */ }
      body .notifBottom .error {
        width: 100%;
        height: 100%;
        float: right;
        background-color: crimson;
        color: white;
        position: relative; }
        body .notifBottom .error .closeBtn {
          width: 30px;
          height: 30px;
          float: right;
          background-color: #c51236;
          color: white;
          text-align: center;
          line-height: 30px;
          box-sizing: border-box;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          position: absolute;
          right: 50px;
          top: 10px;
          cursor: pointer;
          transition: all 0.3s linear;
          -webkit-transition: all 0.3s linear;
          -moz-transition: all 0.3s linear; }
          body .notifBottom .error .closeBtn:hover {
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
          body .notifBottom .error .closeBtn i {
            line-height: 30px; }
        body .notifBottom .error .log {
          width: 100%;
          height: 50px;
          float: right;
          padding: 0 90px;
          box-sizing: border-box;
          text-align: center;
          line-height: 50px; }
      body .notifBottom .success {
        width: 100%;
        height: 100%;
        float: right;
        background-color: forestgreen;
        color: white;
        position: relative; }
        body .notifBottom .success .closeBtn {
          width: 30px;
          height: 30px;
          float: right;
          background-color: #1d771d;
          color: white;
          text-align: center;
          line-height: 30px;
          box-sizing: border-box;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          position: absolute;
          right: 50px;
          top: 10px;
          cursor: pointer;
          transition: all 0.3s linear;
          -webkit-transition: all 0.3s linear;
          -moz-transition: all 0.3s linear; }
          body .notifBottom .success .closeBtn:hover {
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
          body .notifBottom .success .closeBtn i {
            line-height: 30px; }
        body .notifBottom .success .log {
          width: 100%;
          height: 50px;
          float: right;
          padding: 0 90px;
          box-sizing: border-box;
          text-align: center;
          line-height: 50px; }
      body .notifBottom.active {
        animation: slideUp 0.6s 0.6s forwards; }
        body .notifBottom.active .log {
          animation: fadeInShadow 0.6s 0.6s forwards; }
      body .notifBottom.deactive {
        width: 100vw;
        height: 50px;
        position: fixed;
        float: right;
        bottom: 0;
        right: 0;
        z-index: 100;
        transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        animation: slideDown 0.4s 0.2s forwards; }
        body .notifBottom.deactive .log {
          animation: fadeOutShadow 0.3s 0.3s backwards; }
    body footer {
      width: 100vw;
      height: 50px;
      float: right;
      position: fixed;
      bottom: 0; }
      body footer .copyRight {
        float: right;
        line-height: 50px;
        padding: 0 10px;
        font-size: 12px; }
      body footer ul.socials {
        float: left;
        padding: 0;
        margin: 0;
        list-style: none; }
        body footer ul.socials li {
          width: 30px;
          height: 30px;
          float: left;
          text-align: center;
          display: inline-block;
          line-height: 30px;
          margin: 10px 5px;
          font-size: 20px; }
          body footer ul.socials li a {
            width: 100%;
            height: 100%;
            float: right;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear; }
            body footer ul.socials li a:hover {
              opacity: 0.8; }
    body .preLoader {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      float: left;
      -moz-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: 2;
      width: 40px; }
      body .preLoader.hidden {
        display: none; }
      body .preLoader.darkBtn {
        width: 200px;
        height: 40px;
        background-color: #444444;
        border-radius: 20px; }
        body .preLoader.darkBtn .spinner.spinner-1:after {
          border-bottom-color: #ffcc00 !important;
          border-right-color: #ffcc00 !important; }
        body .preLoader.darkBtn .spinner.spinner-1:before {
          border-bottom-color: #ffcc00 !important;
          border-right-color: #ffcc00 !important; }
      body .preLoader.lightBtn {
        width: 200px;
        height: 40px;
        background-color: #ffcc00;
        border-radius: 20px;
        border: 2px solid #444444; }
        body .preLoader.lightBtn .spinner.spinner-1:after {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
        body .preLoader.lightBtn .spinner.spinner-1:before {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
      body .preLoader.list {
        width: 100%;
        height: 40px;
        background-color: transparent;
        position: relative !important;
        top: auto !important;
        left: auto !important;
        transform: none;
        box-sizing: border-box;
        margin-top: 20px; }
        body .preLoader.list .spinner.spinner-1:after {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
        body .preLoader.list .spinner.spinner-1:before {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
      body .preLoader.fullScreen {
        position: fixed !important;
        float: left;
        top: 50% !important;
        left: 50% !important;
        background-color: transparent !important;
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
        body .preLoader.fullScreen .spinner.spinner-1:after {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
        body .preLoader.fullScreen .spinner.spinner-1:before {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
      body .preLoader .spinner-block {
        width: 40px;
        text-align: center;
        position: relative;
        float: left;
        left: 50%;
        top: 50%;
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        /* spinner-1 styles */ }
        body .preLoader .spinner-block .spinner {
          position: relative;
          width: 40px;
          height: 40px; }
          body .preLoader .spinner-block .spinner:before, body .preLoader .spinner-block .spinner:after {
            content: '';
            display: block;
            position: absolute;
            border-width: 2px;
            border-style: solid;
            border-radius: 50%; }
  @keyframes rotate-animation {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
  @keyframes anti-rotate-animation {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(-360deg); } }
        body .preLoader .spinner-block .spinner.spinner-1:before {
          width: 32px;
          height: 32px;
          border-bottom-color: #444444;
          border-right-color: #444444;
          border-top-color: rgba(68, 68, 68, 0);
          border-left-color: rgba(68, 68, 68, 0);
          top: 50%;
          left: 50%;
          float: left;
          position: absolute;
          margin-top: -18px;
          margin-left: -18px;
          animation: rotate-animation 1s linear 0s infinite; }
        body .preLoader .spinner-block .spinner.spinner-1:after {
          width: 16px;
          height: 16px;
          border-bottom-color: #444444;
          border-right-color: #444444;
          border-top-color: rgba(68, 68, 68, 0);
          border-left-color: rgba(68, 68, 68, 0);
          top: 50%;
          left: 50%;
          float: left;
          position: absolute;
          margin-top: -10px;
          margin-left: -10px;
          animation: anti-rotate-animation 0.85s linear 0s infinite; } }

@media screen and (max-width: 1200px) and (min-width: 768px) and (orientation: portrait) {
  ::-webkit-scrollbar {
    width: 5px; }
  ::-webkit-scrollbar-thumb {
    background-color: #ffcc00; }
  input[type='number'] {
    -moz-appearance: textfield; }
  body {
    width: 100vw;
    height: 100vh;
    float: left;
    position: relative;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    font-family: "IRANSans";
    direction: rtl;
    background-color: #ffcc00;
    background-image: url("../../media/bgPhone.png");
    background-position: center center;
    background-size: 70%;
    color: #444444;
    font-size: 14px; }
    body a {
      color: inherit !important;
      text-decoration: none; }
    body .main {
      direction: rtl;
      width: 100vw;
      height: 100vh;
      float: left;
      position: relative;
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      overflow-x: hidden;
      font-family: "IRANSans"; }
    body .En {
      direction: ltr !important;
      width: 100vw;
      height: 100vh;
      float: left;
      position: relative;
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      overflow-x: hidden;
      font-family: "IRANSans"; }
      body .En header ul {
        float: left !important; }
        body .En header ul li {
          direction: ltr !important;
          float: left; }
      body .En header .btn {
        float: left !important; }
      body .En header .logo {
        float: right;
        margin-right: 25px; }
        body .En header .logo img {
          filter: invert(0.28); }
      body .En .menu {
        float: left !important;
        left: -100% !important;
        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important; }
        body .En .menu.open {
          left: 0 !important;
          right: auto !important; }
        body .En .menu .closeBtn {
          float: left !important;
          left: 25px !important;
          right: auto !important;
          filter: invert(0.22); }
        body .En .menu ul li:active .title {
          -webkit-transform: translateX(15px) !important;
          -moz-transform: translateX(15px) !important;
          -ms-transform: translateX(15px) !important;
          -o-transform: translateX(15px) !important;
          transform: translateX(15px) !important; }
        body .En .menu ul li .title {
          float: left !important; }
        body .En .menu ul li .image {
          float: right !important; }
      body .En .basketNav {
        float: left !important;
        left: -100% !important;
        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important; }
        body .En .basketNav.open {
          left: 0 !important;
          right: auto !important; }
        body .En .basketNav .closeBtn {
          float: left !important;
          left: 25px !important;
          right: auto !important; }
        body .En .basketNav ul li .deleteBtn {
          float: left !important;
          left: 0;
          right: auto !important; }
        body .En .basketNav ul li .image {
          float: left !important; }
        body .En .basketNav ul li .title {
          float: left !important;
          text-align: left !important; }
        body .En .basketNav ul li .priceNCount {
          float: right !important; }
          body .En .basketNav ul li .priceNCount .price span {
            float: left; }
        body .En .basketNav .totalPrice {
          direction: ltr !important; }
          body .En .basketNav .totalPrice span {
            float: left !important; }
            body .En .basketNav .totalPrice span:first-child {
              text-align: left !important; }
            body .En .basketNav .totalPrice span:last-child {
              text-align: right !important; }
      body .En .logInSignUp .label {
        float: left !important;
        text-align: left !important; }
      body .En .logInSignUp .selectWrapper {
        float: right !important; }
        body .En .logInSignUp .selectWrapper select {
          direction: rtl !important; }
      body .En .copyRight {
        float: left !important;
        direction: ltr !important; }
      body .En .socials {
        float: right !important; }
    body .popBg {
      display: none;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      float: left;
      left: 0;
      z-index: 99;
      transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      -webkit-transition: all 0.3s; }
      body .popBg.show {
        display: block;
        opacity: 1; }
    body .menu {
      width: 400px;
      height: 100vh;
      position: fixed;
      float: right;
      right: -100%;
      background-color: #ffcc00;
      z-index: 101;
      transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      -webkit-transition: all 0.5s;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px; }
      body .menu.open {
        right: 0 !important; }
      body .menu .closeBtn {
        width: 30px;
        height: 30px;
        float: right;
        position: absolute;
        color: #444444;
        right: 25px;
        top: 15px;
        line-height: 50px;
        font-size: 20px;
        text-align: center;
        cursor: pointer;
        filter: invert(0.22); }
      body .menu ul {
        width: 300px;
        margin: 0 50px;
        margin-top: 50px;
        padding: 0;
        float: right;
        list-style: none; }
        body .menu ul li {
          width: 100%;
          height: 60px;
          float: right;
          color: white;
          font-size: 14px;
          line-height: 60px; }
          body .menu ul li.pizzaIrPlus {
            background-color: #a80000;
            text-align: center !important;
            color: white;
            border-radius: 30px;
            border: 2px solid #a80000;
            box-sizing: border-box; }
            body .menu ul li.pizzaIrPlus:hover {
              background-color: #ffcc00; }
              body .menu ul li.pizzaIrPlus:hover .title {
                -webkit-transform: none;
                -moz-transform: none;
                -ms-transform: none;
                -o-transform: none;
                transform: none;
                color: #a80000 !important; }
            body .menu ul li.pizzaIrPlus .title {
              width: 100%;
              color: white !important; }
          body .menu ul li a {
            width: 100%;
            height: 100%;
            float: right; }
          body .menu ul li .title {
            float: right;
            color: #444444; }
          body .menu ul li .image {
            height: 40px;
            float: left;
            margin: 10px 0; }
            body .menu ul li .image img {
              height: 30px;
              float: left;
              margin: 5px;
              filter: invert(0.22);
              -webkit-filter: invert(0.22); }
    body .basketNav {
      width: 500px;
      height: 100vh;
      position: fixed;
      float: right;
      right: -100%;
      background-color: #444444;
      z-index: 101;
      overflow: hidden;
      transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      -webkit-transition: all 0.5s;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px; }
      body .basketNav.open {
        right: 0 !important; }
      body .basketNav .closeBtn {
        width: 30px;
        height: 30px;
        float: right;
        position: absolute;
        color: white;
        right: 25px;
        top: 15px;
        line-height: 50px;
        font-size: 20px;
        text-align: center;
        cursor: pointer;
        filter: invert(1); }
      body .basketNav ul {
        width: 400px;
        height: calc(100% - 170px);
        margin: 0 50px;
        margin-top: 50px;
        padding: 0;
        float: right;
        list-style: none;
        overflow-x: hidden;
        direction: ltr;
        padding-right: 5px; }
        body .basketNav ul li {
          width: 100%;
          float: right;
          border-bottom: 1px solid #5e5e5e;
          color: white;
          font-size: 14px;
          line-height: 75px;
          position: relative; }
          body .basketNav ul li:last-child {
            border: none; }
          body .basketNav ul li .deleteBtn {
            width: 20px;
            height: 20px;
            color: white;
            float: right;
            position: absolute;
            top: 0;
            right: 0;
            line-height: 20px;
            text-align: center;
            font-size: 0.8em;
            cursor: pointer;
            z-index: 200;
            filter: invert(1); }
          body .basketNav ul li .image {
            height: 65px;
            float: right;
            margin: 5px 0;
            position: relative; }
            body .basketNav ul li .image .myoPlaceholder {
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%; }
              body .basketNav ul li .image .myoPlaceholderIcon {
                width: 90%;
                height: 90%;
                padding: 1px;
                filter: invert(1);
                transition: none; }
                body .basketNav ul li .image .myoPlaceholderIconWrapper {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: absolute;
                  top: 2px;
                  left: 2px;
                  width: 1.1rem;
                  height: 1.1rem;
                  background-color: #a80000;
                  border-radius: 50%;
                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%; }
            body .basketNav ul li .image img {
              height: 65px;
              float: left; }
            body .basketNav ul li .image .size {
              height: 15px;
              position: absolute;
              float: left;
              left: 50%;
              top: calc(100% - 15px);
              padding: 0 10px;
              line-height: 15px;
              font-size: 10px;
              -webkit-transform: translateX(-50%);
              -moz-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              -o-transform: translateX(-50%);
              transform: translateX(-50%);
              background-color: #ffcc00;
              border-radius: 7.5px;
              -webkit-border-radius: 7.5px;
              -moz-border-radius: 7.5px;
              color: #444444; }
          body .basketNav ul li .title {
            width: calc(100% - 175px);
            line-height: 75px;
            float: right;
            position: relative;
            padding: 0 10px;
            box-sizing: border-box;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: right; }
          body .basketNav ul li .priceNCount {
            width: 100px;
            height: 75px;
            float: left; }
            body .basketNav ul li .priceNCount .price {
              width: 100%;
              height: 50%;
              float: left; }
              body .basketNav ul li .priceNCount .price span {
                width: 50%;
                height: 100%;
                float: right;
                text-align: center;
                line-height: 37.5px; }
            body .basketNav ul li .priceNCount .count {
              width: 100%;
              height: 50%;
              float: left; }
              body .basketNav ul li .priceNCount .count .addBtn,
              body .basketNav ul li .priceNCount .count .minusBtn {
                width: 20px;
                height: 20px;
                float: right;
                background-color: #ffcc00;
                color: #444444;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                line-height: 20px;
                font-size: 12px;
                text-align: center;
                margin: calc((37.5px / 2) - 10px) 5px;
                cursor: pointer;
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
                -moz-transition: all 0.3s linear;
                box-sizing: border-box;
                padding: 2px;
                border: 1px solid #444444; }
                body .basketNav ul li .priceNCount .count .addBtn img,
                body .basketNav ul li .priceNCount .count .minusBtn img {
                  filter: invert(0.22); }
                body .basketNav ul li .priceNCount .count .addBtn:active,
                body .basketNav ul li .priceNCount .count .minusBtn:active {
                  color: #ffcc00;
                  background-color: #444444;
                  border: 1px solid #ffcc00; }
              body .basketNav ul li .priceNCount .count .numOfUnit {
                width: 40px;
                height: 100%;
                float: right;
                background-color: transparent;
                border: none;
                color: white;
                line-height: 100%;
                font-weight: bold;
                outline: none;
                text-align: center;
                padding: 0; }
      body .basketNav .total {
        width: 100%;
        height: 120px;
        float: right;
        position: absolute;
        bottom: 0;
        background-color: #444444;
        -webkit-box-shadow: 0 0 10px #000;
        -moz-box-shadow: 0 0 10px #000;
        box-shadow: 0 0 10px #000; }
        body .basketNav .total .totalPrice {
          width: 100%;
          height: 40px;
          float: right;
          line-height: 40px;
          padding: 0 50px;
          color: white;
          font-weight: bold;
          margin: 10px 0;
          box-sizing: border-box;
          text-align: center; }
          body .basketNav .total .totalPrice span {
            width: 50%;
            float: right; }
            body .basketNav .total .totalPrice span:first-child {
              text-align: right; }
            body .basketNav .total .totalPrice span:last-child {
              text-align: left; }
      body .basketNav a {
        line-height: 30px;
        position: absolute;
        bottom: 10px;
        float: right;
        right: 50%;
        -webkit-transform: translateX(50%);
        -moz-transform: translateX(50%);
        -ms-transform: translateX(50%);
        -o-transform: translateX(50%);
        transform: translateX(50%); }
    body .logInSignUp {
      display: none;
      width: 500px;
      float: right;
      position: absolute;
      z-index: 101;
      background-color: #ffcc00;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      right: 50%;
      top: 50%;
      -webkit-transform: translate(50%, -50%);
      -moz-transform: translate(50%, -50%);
      -ms-transform: translate(50%, -50%);
      -o-transform: translate(50%, -50%);
      transform: translate(50%, -50%); }
      body .logInSignUp .logIn {
        width: 100%;
        height: 100%;
        float: right; }
      body .logInSignUp .signUp {
        display: none;
        width: 100%;
        height: 100%;
        float: right; }
      body .logInSignUp .closeBtn {
        width: 40px;
        height: 40px;
        float: right;
        position: absolute;
        background-color: #ffcc00;
        text-align: center;
        line-height: 40px;
        top: -20px;
        right: -20px;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        cursor: pointer; }
      body .logInSignUp .title {
        width: 100%;
        float: right;
        text-align: center; }
      body .logInSignUp .label {
        width: 130px;
        height: 40px;
        float: right;
        text-align: right;
        line-height: 40px;
        margin: 10px 0; }
      body .logInSignUp .phoneField {
        width: 210px !important;
        margin: 10px 0;
        margin-left: 0px; }
      body .logInSignUp .emailField {
        width: 330px !important; }
      body .logInSignUp .selectWrapper {
        margin: 10px 0;
        float: left !important; }
      body .logInSignUp button {
        position: relative;
        margin: 10px 0;
        margin-top: 20px;
        float: right;
        right: 50%;
        -webkit-transform: translateX(50%);
        -moz-transform: translateX(50%);
        -ms-transform: translateX(50%);
        -o-transform: translateX(50%);
        transform: translateX(50%);
        font-family: "IRANSans";
        clear: both; }
      body .logInSignUp .goToSignUp,
      body .logInSignUp .goToLogIn {
        position: relative;
        float: right;
        right: 50%;
        -webkit-transform: translateX(50%);
        -moz-transform: translateX(50%);
        -ms-transform: translateX(50%);
        -o-transform: translateX(50%);
        transform: translateX(50%);
        font-family: "IRANSans";
        clear: both;
        cursor: pointer;
        border-bottom: 1px solid #ffcc00; }
        body .logInSignUp .goToSignUp:active,
        body .logInSignUp .goToLogIn:active {
          border-bottom: 1px dashed #444444; }
    body header {
      width: 100%;
      float: right;
      position: fixed;
      top: 0;
      left: 0;
      padding: 0;
      margin: 0;
      height: 50px;
      color: #444444; }
      body header ul {
        height: 50px;
        float: right;
        padding: 0;
        margin-right: 20px;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        list-style: none; }
        body header ul li {
          width: 35px;
          height: 50px;
          float: right;
          line-height: 20px;
          font-size: 20px;
          text-align: center;
          margin: 7.5px 5px;
          position: relative; }
  @keyframes heartBeat {
    0% {
      transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      background-color: #a80000; }
    50% {
      transform: scale(1.3, 1.3);
      -webkit-transform: scale(1.3, 1.3);
      -moz-transform: scale(1.3, 1.3);
      background-color: #a80000; }
    100% {
      transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      background-color: #a80000; } }
          body header ul li .shoppingCartIconWrapper {
            width: 100%;
            height: 100%;
            cursor: pointer;
            background-color: transparent !important; }
            body header ul li .shoppingCartIconWrapper.active {
              animation: heartBeat 0.3s infinite;
              -webkit-animation: heartBeat 0.3s infinite; }
          body header ul li .numOfOrder {
            min-width: 16px;
            height: 16px;
            position: absolute;
            float: right;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            background-color: #444444;
            color: #ffcc00;
            line-height: 7px;
            font-size: 10px;
            text-align: center;
            padding: 5px;
            box-sizing: border-box;
            border: 1px solid #ffcc00;
            top: calc(100% - 25px);
            left: calc(100% - 11px);
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear; }
            body header ul li .numOfOrder.active {
              animation: heartBeat 0.3s infinite;
              -webkit-animation: heartBeat 0.3s infinite; }
          body header ul li .userGreeting {
            min-width: 50px;
            position: absolute;
            float: left;
            top: calc(100% - 13px);
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
            font-size: 10px;
            text-align: center;
            white-space: nowrap; }
          body header ul li a {
            width: 100%;
            height: 100%;
            float: right;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear; }
            body header ul li a img {
              filter: invert(0.24); }
            body header ul li a:active {
              opacity: 0.8; }
      body header > a {
        display: none;
        margin: 5px;
        float: right; }
      body header .logo {
        display: block !important;
        float: left;
        margin-left: 25px;
        margin-top: 5px;
        height: 50px; }
  @keyframes transformLogoType {
    0% {
      transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -webkit-transform: translateX(-100%); }
    100% {
      transform: translateX(0%);
      -moz-transform: translateX(0%);
      -ms-transform: translateX(0%);
      -webkit-transform: translateX(0%); } }
        body header .logo svg,
        body header .logo img {
          height: 100%;
          filter: invert(0.28); }
          body header .logo svg #logoType g,
          body header .logo img #logoType g {
            position: relative;
            float: left;
            transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            animation: transformLogoType 1s 1s linear forwards; }
    body .container {
      width: 100%;
      height: calc(100% - 100px);
      float: right;
      padding: 2rem 2rem;
      margin-top: 50px;
      box-sizing: border-box;
      position: relative; }
    body .popBg3 {
      display: none;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      float: left;
      left: 0;
      z-index: 99; }
    body .showSupportingRegion {
      display: none;
      width: 90%;
      height: 420px;
      float: right;
      position: absolute;
      background-color: #ffcc00;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      right: 50%;
      top: 50%;
      -moz-transform: translate(50%, -50%);
      -o-transform: translate(50%, -50%);
      -ms-transform: translate(50%, -50%);
      -webkit-transform: translate(50%, -50%);
      transform: translate(50%, -50%);
      z-index: 110;
      padding: 10px; }
      body .showSupportingRegion .title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        float: right;
        text-align: center; }
      body .showSupportingRegion #map {
        width: 100%;
        height: 300px;
        background-color: #444444;
        float: right;
        margin: 10px 0; }
      body .showSupportingRegion .locationName {
        height: 40px;
        float: right;
        position: relative;
        margin: 10px 0;
        right: 50%;
        -moz-transform: translate(50%, 0);
        -o-transform: translate(50%, 0);
        -ms-transform: translate(50%, 0);
        -webkit-transform: translate(50%, 0);
        transform: translate(50%, 0); }
        body .showSupportingRegion .locationName img {
          width: 40px;
          height: 40px;
          float: right;
          padding: 10px;
          box-sizing: border-box; }
        body .showSupportingRegion .locationName span {
          line-height: 40px; }
      body .showSupportingRegion button {
        position: relative;
        clear: both;
        float: right;
        right: 50%;
        transform: translateX(50%);
        font-family: "IRANSans"; }
  @keyframes slideUp {
    0% {
      bottom: -50px; }
    100% {
      bottom: 0px; } }
  @keyframes slideDown {
    0% {
      bottom: 0px; }
    100% {
      bottom: -50px; } }
  @keyframes fadeInShadow {
    0% {
      -webkit-box-shadow: 0 0 0 #444;
      -moz-box-shadow: 0 0 0 #444;
      box-shadow: 0 0 0 #444; }
    100% {
      -webkit-box-shadow: 0 0 10px #444;
      -moz-box-shadow: 0 0 10px #444;
      box-shadow: 0 0 10px #444; } }
  @keyframes fadeOutShadow {
    0% {
      -webkit-box-shadow: 0 0 10px #444;
      -moz-box-shadow: 0 0 10px #444;
      box-shadow: 0 0 10px #444; }
    100% {
      -webkit-box-shadow: 0 0 0 #444;
      -moz-box-shadow: 0 0 0 #444;
      box-shadow: 0 0 0 #444; } }
    body .notifBottom {
      width: 100vw;
      height: 50px;
      position: fixed;
      float: right;
      bottom: -50px;
      right: 0;
      z-index: 100; }
      body .notifBottom .error {
        width: 100%;
        height: 100%;
        float: right;
        background-color: crimson;
        color: white;
        position: relative; }
        body .notifBottom .error .closeBtn {
          width: 30px;
          height: 30px;
          float: right;
          background-color: #c51236;
          color: white;
          text-align: center;
          line-height: 30px;
          box-sizing: border-box;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          position: absolute;
          right: 50px;
          top: 10px;
          cursor: pointer;
          transition: all 0.3s linear;
          -webkit-transition: all 0.3s linear;
          -moz-transition: all 0.3s linear; }
          body .notifBottom .error .closeBtn:hover {
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
          body .notifBottom .error .closeBtn i {
            line-height: 30px; }
        body .notifBottom .error .log {
          width: 100%;
          height: 50px;
          float: right;
          padding: 0 90px;
          box-sizing: border-box;
          text-align: center;
          line-height: 50px; }
      body .notifBottom .success {
        width: 100%;
        height: 100%;
        float: right;
        background-color: forestgreen;
        color: white;
        position: relative; }
        body .notifBottom .success .closeBtn {
          width: 30px;
          height: 30px;
          float: right;
          background-color: #1d771d;
          color: white;
          text-align: center;
          line-height: 30px;
          box-sizing: border-box;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          position: absolute;
          right: 50px;
          top: 10px;
          cursor: pointer;
          transition: all 0.3s linear;
          -webkit-transition: all 0.3s linear;
          -moz-transition: all 0.3s linear; }
          body .notifBottom .success .closeBtn:hover {
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
          body .notifBottom .success .closeBtn i {
            line-height: 30px; }
        body .notifBottom .success .log {
          width: 100%;
          height: 50px;
          float: right;
          padding: 0 90px;
          box-sizing: border-box;
          text-align: center;
          line-height: 50px; }
      body .notifBottom.active {
        animation: slideUp 0.6s 0.6s forwards; }
        body .notifBottom.active .log {
          animation: fadeInShadow 0.6s 0.6s forwards; }
      body .notifBottom.deactive {
        width: 100vw;
        height: 50px;
        position: fixed;
        float: right;
        bottom: 0;
        right: 0;
        z-index: 100;
        transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        animation: slideDown 0.4s 0.2s forwards; }
        body .notifBottom.deactive .log {
          animation: fadeOutShadow 0.3s 0.3s backwards; }
    body footer {
      width: 100%;
      height: 50px;
      float: right;
      position: fixed;
      bottom: 0; }
      body footer .copyRight {
        float: right;
        line-height: 50px;
        padding: 0 10px;
        font-size: 10px; }
      body footer ul.socials {
        float: left;
        padding: 0;
        margin: 0;
        list-style: none; }
        body footer ul.socials li {
          width: 30px;
          height: 30px;
          float: left;
          text-align: center;
          display: inline-block;
          line-height: 30px;
          margin: 10px 5px;
          font-size: 20px; }
          body footer ul.socials li a {
            width: 100%;
            height: 100%;
            float: right;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear; }
            body footer ul.socials li a:active {
              opacity: 0.8; }
    body .preLoader {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      float: left;
      -moz-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: 2;
      width: 40px; }
      body .preLoader.hidden {
        display: none; }
      body .preLoader.darkBtn {
        width: 200px;
        height: 40px;
        background-color: #444444;
        border-radius: 20px; }
        body .preLoader.darkBtn .spinner.spinner-1:after {
          border-bottom-color: #ffcc00 !important;
          border-right-color: #ffcc00 !important; }
        body .preLoader.darkBtn .spinner.spinner-1:before {
          border-bottom-color: #ffcc00 !important;
          border-right-color: #ffcc00 !important; }
      body .preLoader.lightBtn {
        width: 200px;
        height: 40px;
        background-color: #ffcc00;
        border-radius: 20px;
        border: 2px solid #444444; }
        body .preLoader.lightBtn .spinner.spinner-1:after {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
        body .preLoader.lightBtn .spinner.spinner-1:before {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
      body .preLoader.list {
        width: 100%;
        height: 40px;
        background-color: transparent;
        position: relative !important;
        top: auto !important;
        left: auto !important;
        transform: none;
        box-sizing: border-box;
        margin-top: 20px; }
        body .preLoader.list .spinner.spinner-1:after {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
        body .preLoader.list .spinner.spinner-1:before {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
      body .preLoader.fullScreen {
        position: fixed !important;
        float: left;
        top: 50% !important;
        left: 50% !important;
        background-color: transparent !important;
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
        body .preLoader.fullScreen .spinner.spinner-1:after {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
        body .preLoader.fullScreen .spinner.spinner-1:before {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
      body .preLoader .spinner-block {
        width: 40px;
        text-align: center;
        position: relative;
        float: left;
        left: 50%;
        top: 50%;
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        /* spinner-1 styles */ }
        body .preLoader .spinner-block .spinner {
          position: relative;
          width: 40px;
          height: 40px; }
          body .preLoader .spinner-block .spinner:before, body .preLoader .spinner-block .spinner:after {
            content: '';
            display: block;
            position: absolute;
            border-width: 2px;
            border-style: solid;
            border-radius: 50%; }
  @keyframes rotate-animation {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
  @keyframes anti-rotate-animation {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(-360deg); } }
        body .preLoader .spinner-block .spinner.spinner-1:before {
          width: 32px;
          height: 32px;
          border-bottom-color: #444444;
          border-right-color: #444444;
          border-top-color: rgba(68, 68, 68, 0);
          border-left-color: rgba(68, 68, 68, 0);
          top: 0px;
          left: 0px;
          animation: rotate-animation 1s linear 0s infinite; }
        body .preLoader .spinner-block .spinner.spinner-1:after {
          width: 16px;
          height: 16px;
          border-bottom-color: #444444;
          border-right-color: #444444;
          border-top-color: rgba(68, 68, 68, 0);
          border-left-color: rgba(68, 68, 68, 0);
          top: 8px;
          left: 8px;
          animation: anti-rotate-animation 0.85s linear 0s infinite; } }

@media screen and (max-width: 767px) and (max-aspect-ratio: 13 / 9) {
  ::-webkit-scrollbar {
    width: 0px; }
  ::-webkit-scrollbar-thumb {
    background-color: #ffcc00; }
  input[type='number'] {
    -moz-appearance: textfield; }
  body {
    width: 100vw;
    height: 100vh;
    /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    float: left;
    position: relative;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    font-family: "IRANSans";
    direction: rtl;
    background-color: #ffcc00;
    background-image: url("../../media/bgPhone.png");
    background-position: center center;
    color: #444444;
    font-size: 12px; }
    body a {
      color: inherit !important;
      text-decoration: none; }
    body .main {
      width: 100vw;
      height: 100%;
      float: left;
      position: relative;
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      overflow-x: hidden;
      font-family: "IRANSans";
      direction: rtl; }
    body .En {
      width: 100vw;
      height: 100vh;
      /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
      float: left;
      position: relative;
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      overflow-x: hidden;
      font-family: "IRANSans";
      direction: ltr !important; }
      body .En header ul {
        float: left !important; }
        body .En header ul li {
          direction: ltr !important;
          float: left; }
      body .En header .btn {
        float: left !important; }
      body .En header .logo {
        float: right;
        margin-right: 25px; }
        body .En header .logo img {
          filter: invert(0.28); }
      body .En .menu {
        float: left !important;
        left: -100% !important;
        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important; }
        body .En .menu.open {
          left: 0 !important;
          right: auto !important; }
        body .En .menu .closeBtn {
          float: left !important;
          left: 20px !important;
          right: auto !important;
          filter: invert(0.22); }
        body .En .menu ul li:active .title {
          -webkit-transform: translateX(15px) !important;
          -moz-transform: translateX(15px) !important;
          -ms-transform: translateX(15px) !important;
          -o-transform: translateX(15px) !important;
          transform: translateX(15px) !important; }
        body .En .menu ul li .title {
          float: left !important; }
        body .En .menu ul li .image {
          float: right !important; }
      body .En .basketNav {
        float: left !important;
        left: -100% !important;
        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important; }
        body .En .basketNav.open {
          left: 0 !important;
          right: auto !important; }
        body .En .basketNav .closeBtn {
          float: left !important;
          left: 25px !important;
          right: auto !important; }
        body .En .basketNav ul li .deleteBtn {
          float: left !important;
          left: 0;
          right: auto !important; }
        body .En .basketNav ul li .image {
          float: left !important; }
        body .En .basketNav ul li .title {
          float: left !important;
          text-align: left !important; }
        body .En .basketNav ul li .priceNCount {
          float: right !important; }
          body .En .basketNav ul li .priceNCount .price span {
            float: left; }
        body .En .basketNav .totalPrice {
          direction: ltr !important; }
          body .En .basketNav .totalPrice span {
            float: left !important; }
            body .En .basketNav .totalPrice span:first-child {
              text-align: left !important; }
            body .En .basketNav .totalPrice span:last-child {
              text-align: right !important; }
      body .En .logInSignUp .selectWrapper {
        float: right !important; }
        body .En .logInSignUp .selectWrapper select {
          direction: rtl !important; }
    body .popBg {
      display: none;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      float: left;
      left: 0;
      z-index: 99;
      transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      -webkit-transition: all 0.3s; }
      body .popBg.show {
        display: block;
        opacity: 1; }
    body .menu {
      width: 90%;
      height: 100%;
      position: fixed;
      float: right;
      right: -100%;
      background-color: #ffcc00;
      z-index: 100;
      transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      -webkit-transition: all 0.5s;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px; }
      body .menu.open {
        right: 0 !important; }
      body .menu .closeBtn {
        width: 20px;
        height: 20px;
        float: right;
        position: absolute;
        color: #444444;
        right: 20px;
        top: 20px;
        line-height: 20px;
        font-size: 18px;
        text-align: center;
        cursor: pointer;
        filter: invert(0.22); }
      body .menu ul {
        width: 90%;
        margin: 0 5%;
        height: calc(100% - 75px);
        overflow: auto;
        padding: 0 10px;
        box-sizing: border-box;
        margin-top: 50px;
        float: right;
        list-style: none; }
        body .menu ul::-webkit-scrollbar-thumb {
          background-color: #444444; }
        body .menu ul li {
          width: 100%;
          height: 50px;
          float: right;
          color: white;
          font-size: 12px;
          line-height: 50px; }
          body .menu ul li.pizzaIrPlus {
            background-color: #a80000;
            text-align: center !important;
            color: white;
            border-radius: 30px;
            border: 2px solid #a80000;
            box-sizing: border-box; }
            body .menu ul li.pizzaIrPlus:active {
              background-color: #ffcc00; }
              body .menu ul li.pizzaIrPlus:active .title {
                -webkit-transform: none;
                -moz-transform: none;
                -ms-transform: none;
                -o-transform: none;
                transform: none;
                color: #a80000 !important; }
            body .menu ul li.pizzaIrPlus .title {
              width: 100%;
              color: white !important; }
          body .menu ul li a {
            width: 100%;
            height: 100%;
            float: right; }
          body .menu ul li .title {
            float: right;
            font-weight: bold;
            color: #444444; }
          body .menu ul li .image {
            height: 40px;
            float: left;
            margin: 5px 0px; }
            body .menu ul li .image img {
              height: 30px;
              float: left;
              margin: 5px;
              filter: invert(0.22);
              -webkit-filter: invert(0.22); }
    body .basketNav {
      width: 90%;
      height: 100%;
      position: fixed;
      float: right;
      right: -100%;
      background-color: #444444;
      z-index: 101;
      overflow: hidden;
      transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      -webkit-transition: all 0.5s;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px; }
      body .basketNav.open {
        right: 0 !important; }
      body .basketNav .closeBtn {
        width: 20px;
        height: 20px;
        float: right;
        position: absolute;
        color: white;
        right: 15px;
        top: 10px;
        line-height: 20px;
        font-size: 18px;
        text-align: center;
        cursor: pointer;
        filter: invert(1); }
      body .basketNav ul {
        width: 85%;
        height: calc(100% - 200px);
        margin: 0 7.5%;
        margin-top: 50px;
        padding: 0;
        float: right;
        list-style: none;
        overflow-x: hidden;
        direction: rtl;
        padding-left: 15px;
        box-sizing: border-box; }
        body .basketNav ul ::-webkit-scrollbar-thumb {
          background-color: #ffcc00 !important; }
        body .basketNav ul li {
          width: 100%;
          float: right;
          border-bottom: 1px solid #5e5e5e;
          color: white;
          font-size: 8px;
          line-height: 75px;
          position: relative; }
          body .basketNav ul li:last-child {
            border: none; }
          body .basketNav ul li .deleteBtn {
            width: 20px;
            height: 20px;
            color: white;
            float: right;
            position: absolute;
            top: 0;
            right: 0;
            line-height: 20px;
            text-align: center;
            font-size: 1em;
            cursor: pointer;
            z-index: 200;
            filter: invert(1); }
          body .basketNav ul li .image {
            height: 50px;
            float: right;
            margin: 5px 0;
            position: relative;
            min-width: 50px; }
            body .basketNav ul li .image .myoPlaceholder {
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%; }
              body .basketNav ul li .image .myoPlaceholderIcon {
                width: 90%;
                height: 90%;
                padding: 1px;
                filter: invert(1);
                transition: none; }
                body .basketNav ul li .image .myoPlaceholderIconWrapper {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: absolute;
                  top: 2px;
                  left: 2px;
                  width: 1rem;
                  height: 1rem;
                  background-color: #a80000;
                  border-radius: 50%;
                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%; }
            body .basketNav ul li .image img {
              height: 50px;
              float: left; }
            body .basketNav ul li .image .size {
              height: 15px;
              position: absolute;
              float: left;
              left: 50%;
              top: calc(100% - 15px);
              padding: 0 10px;
              line-height: 15px;
              font-size: 8px;
              -webkit-transform: translateX(-50%);
              -moz-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              -o-transform: translateX(-50%);
              transform: translateX(-50%);
              background-color: #ffcc00;
              border-radius: 7.5px;
              -webkit-border-radius: 7.5px;
              -moz-border-radius: 7.5px;
              color: #444444; }
          body .basketNav ul li .title {
            width: calc(100% - 140px);
            line-height: 60px;
            float: right;
            position: relative;
            padding: 0 10px;
            box-sizing: border-box;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: right; }
          body .basketNav ul li .priceNCount {
            width: 90px;
            height: 60px;
            float: left; }
            body .basketNav ul li .priceNCount .price {
              width: 100%;
              height: 50%;
              float: left; }
              body .basketNav ul li .priceNCount .price span {
                width: 50%;
                height: 100%;
                float: right;
                text-align: center;
                line-height: 30px;
                font-weight: bold;
                box-sizing: border-box;
                padding: 0 5px; }
                body .basketNav ul li .priceNCount .price span:first-child {
                  text-align: left; }
                body .basketNav ul li .priceNCount .price span:last-child {
                  text-align: right; }
            body .basketNav ul li .priceNCount .count {
              width: 100%;
              height: 50%;
              float: left; }
              body .basketNav ul li .priceNCount .count .addBtn,
              body .basketNav ul li .priceNCount .count .minusBtn {
                width: 20px;
                height: 20px;
                float: right;
                background-color: #ffcc00;
                color: #444444;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                line-height: 20px;
                font-size: 10px;
                text-align: center;
                margin: 5px;
                cursor: pointer;
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
                -moz-transition: all 0.3s linear;
                box-sizing: border-box;
                padding: 2px;
                border: 1px solid #444444; }
                body .basketNav ul li .priceNCount .count .addBtn img,
                body .basketNav ul li .priceNCount .count .minusBtn img {
                  filter: invert(0.22); }
                body .basketNav ul li .priceNCount .count .addBtn:active,
                body .basketNav ul li .priceNCount .count .minusBtn:active {
                  color: #ffcc00;
                  background-color: #444444;
                  border: 1px solid #ffcc00; }
              body .basketNav ul li .priceNCount .count .numOfUnit {
                width: 30px;
                height: 100%;
                float: right;
                background-color: transparent;
                border: none;
                color: white;
                line-height: 100%;
                font-weight: bold;
                outline: none;
                text-align: center;
                padding: 0; }
      body .basketNav .total {
        width: 100%;
        height: 120px;
        float: right;
        position: absolute;
        bottom: 0px;
        background-color: #444444;
        -webkit-box-shadow: 0px -15px 15px 0px black;
        -moz-box-shadow: 0px -15px 15px 0px black;
        box-shadow: 0px -15px 15px 0px black; }
        body .basketNav .total .totalPrice {
          width: 100%;
          height: 40px;
          float: right;
          line-height: 40px;
          padding: 0 50px;
          color: white;
          font-weight: bold;
          margin: 10px 0;
          box-sizing: border-box;
          text-align: center; }
          body .basketNav .total .totalPrice span {
            width: 50%;
            float: right; }
            body .basketNav .total .totalPrice span:first-child {
              text-align: right; }
            body .basketNav .total .totalPrice span:last-child {
              text-align: left; }
      body .basketNav a {
        position: absolute;
        bottom: 10px;
        float: right;
        right: 50%;
        -webkit-transform: translateX(50%);
        -moz-transform: translateX(50%);
        -ms-transform: translateX(50%);
        -o-transform: translateX(50%);
        transform: translateX(50%);
        line-height: 30px; }
    body .logInSignUp {
      display: none;
      width: 300px;
      float: right;
      position: absolute;
      z-index: 101;
      background-color: #ffcc00;
      padding: 20px;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      right: 50%;
      top: 50%;
      -webkit-transform: translate(50%, -50%);
      -moz-transform: translate(50%, -50%);
      -ms-transform: translate(50%, -50%);
      -o-transform: translate(50%, -50%);
      transform: translate(50%, -50%); }
      body .logInSignUp .closeBtn {
        width: 40px;
        height: 40px;
        float: right;
        position: absolute;
        background-color: #ffcc00;
        text-align: center;
        line-height: 40px;
        top: -20px;
        right: 10px;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        cursor: pointer; }
      body .logInSignUp .title {
        width: 100%;
        float: right;
        text-align: center; }
      body .logInSignUp .label {
        width: 100%;
        height: 40px;
        float: right;
        text-align: center;
        line-height: 40px; }
      body .logInSignUp .phoneField {
        width: 180px !important;
        margin: 10px 0;
        margin-left: 0; }
      body .logInSignUp .selectWrapper {
        margin: 10px 0;
        float: left !important; }
      body .logInSignUp button {
        position: relative;
        margin: 10px 0;
        margin-top: 20px;
        float: right;
        right: 50%;
        -webkit-transform: translateX(50%);
        -moz-transform: translateX(50%);
        -ms-transform: translateX(50%);
        -o-transform: translateX(50%);
        transform: translateX(50%);
        font-family: "IRANSans";
        clear: both; }
      body .logInSignUp .goToSignUp {
        position: relative;
        float: right;
        right: 50%;
        -webkit-transform: translateX(50%);
        -moz-transform: translateX(50%);
        -ms-transform: translateX(50%);
        -o-transform: translateX(50%);
        transform: translateX(50%);
        font-family: "IRANSans";
        clear: both;
        cursor: pointer;
        border-bottom: 1px solid #ffcc00; }
        body .logInSignUp .goToSignUp:active {
          border-bottom: 1px dashed #444444; }
      body .logInSignUp .goToLogIn {
        position: relative;
        float: right;
        right: 50%;
        -webkit-transform: translateX(50%);
        -moz-transform: translateX(50%);
        -ms-transform: translateX(50%);
        -o-transform: translateX(50%);
        transform: translateX(50%);
        font-family: "IRANSans";
        clear: both;
        cursor: pointer;
        border-bottom: 1px solid #ffcc00; }
        body .logInSignUp .goToLogIn:active {
          border-bottom: 1px dashed #444444; }
    body header {
      width: 100%;
      float: right;
      position: fixed;
      top: 0;
      left: 0;
      margin: 0;
      height: 60px;
      color: #444444;
      background: #ffcc00;
      padding-right: 5px;
      padding-top: 5px;
      padding-bottom: 0;
      padding-left: 0;
      box-sizing: border-box;
      z-index: 50; }
      body header ul {
        height: 100%;
        padding: 10px 0;
        box-sizing: border-box;
        float: right;
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        list-style: none; }
        body header ul li {
          width: 25px;
          height: 23px;
          float: right;
          line-height: 20px;
          text-align: center;
          margin: 4px 10px;
          font-size: 18px;
          position: relative; }
  @keyframes heartBeat {
    0% {
      transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      background-color: #a80000; }
    50% {
      transform: scale(1.3, 1.3);
      -webkit-transform: scale(1.3, 1.3);
      -moz-transform: scale(1.3, 1.3);
      background-color: #a80000; }
    100% {
      transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      background-color: #a80000; } }
          body header ul li .shoppingCartIconWrapper {
            width: 100%;
            height: 100%;
            cursor: pointer;
            background-color: transparent !important; }
            body header ul li .shoppingCartIconWrapper.active {
              animation: heartBeat 0.3s infinite;
              -webkit-animation: heartBeat 0.3s infinite; }
          body header ul li .numOfOrder {
            min-width: 16px;
            height: 16px;
            position: absolute;
            float: right;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            background-color: #444444;
            color: #ffcc00;
            line-height: 7px;
            font-size: 8px;
            text-align: center;
            padding: 5px;
            box-sizing: border-box;
            border: 1px solid #ffcc00;
            top: calc(100% - 5px);
            left: calc(100% - 10px);
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear; }
            body header ul li .numOfOrder.active {
              animation: heartBeat 0.3s infinite;
              -webkit-animation: heartBeat 0.3s infinite; }
          body header ul li .userGreeting {
            min-width: 50px;
            position: absolute;
            float: left;
            top: calc(100% - 2px);
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
            font-size: 8px;
            text-align: center;
            white-space: nowrap; }
          body header ul li a {
            width: 100%;
            height: 100%;
            float: right;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear; }
            body header ul li a img {
              filter: invert(0.24); }
            body header ul li a:active {
              opacity: 0.8; }
      body header > a {
        display: none;
        margin: 5px;
        float: right; }
      body header .logo {
        display: block !important;
        float: left;
        margin-left: 15px;
        margin-top: 5px;
        height: 40px; }
  @keyframes transformLogoType {
    0% {
      transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -webkit-transform: translateX(-100%); }
    100% {
      transform: translateX(0%);
      -moz-transform: translateX(0%);
      -ms-transform: translateX(0%);
      -webkit-transform: translateX(0%); } }
        body header .logo svg,
        body header .logo img {
          height: 100%;
          filter: invert(0.28); }
          body header .logo svg #logoType g,
          body header .logo img #logoType g {
            position: relative;
            float: left;
            transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            animation: transformLogoType 1s 1s linear forwards; }
    body .container {
      width: 100%;
      height: calc(100% - 50px);
      float: right;
      padding: 20px;
      margin-top: 50px;
      box-sizing: border-box;
      position: relative; }
    body .popBg3 {
      display: none;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      float: left;
      left: 0;
      z-index: 99; }
    body .showSupportingRegion {
      display: none;
      width: 90%;
      height: 420px;
      float: right;
      position: absolute;
      background-color: #ffcc00;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      right: 50%;
      top: 50%;
      -moz-transform: translate(50%, -50%);
      -o-transform: translate(50%, -50%);
      -ms-transform: translate(50%, -50%);
      -webkit-transform: translate(50%, -50%);
      transform: translate(50%, -50%);
      z-index: 110;
      padding: 10px; }
      body .showSupportingRegion .title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        float: right;
        text-align: center; }
      body .showSupportingRegion #map {
        width: 300px;
        height: 300px;
        background-color: #444444;
        float: right;
        margin: 10px calc(50% - 150px); }
      body .showSupportingRegion .locationName {
        height: 40px;
        float: right;
        position: relative;
        margin: 10px 0;
        right: 50%;
        -moz-transform: translate(50%, 0);
        -o-transform: translate(50%, 0);
        -ms-transform: translate(50%, 0);
        -webkit-transform: translate(50%, 0);
        transform: translate(50%, 0); }
        body .showSupportingRegion .locationName img {
          width: 40px;
          height: 40px;
          float: right;
          padding: 10px;
          box-sizing: border-box; }
        body .showSupportingRegion .locationName span {
          line-height: 40px; }
      body .showSupportingRegion button {
        position: relative;
        clear: both;
        float: right;
        right: 50%;
        transform: translateX(50%);
        font-family: "IRANSans"; }
  @keyframes slideUp {
    0% {
      bottom: -50px; }
    100% {
      bottom: 0px; } }
  @keyframes slideDown {
    0% {
      bottom: 0px; }
    100% {
      bottom: -50px; } }
  @keyframes fadeInShadow {
    0% {
      -webkit-box-shadow: 0 0 0 #444;
      -moz-box-shadow: 0 0 0 #444;
      box-shadow: 0 0 0 #444; }
    100% {
      -webkit-box-shadow: 0 0 10px #444;
      -moz-box-shadow: 0 0 10px #444;
      box-shadow: 0 0 10px #444; } }
  @keyframes fadeOutShadow {
    0% {
      -webkit-box-shadow: 0 0 10px #444;
      -moz-box-shadow: 0 0 10px #444;
      box-shadow: 0 0 10px #444; }
    100% {
      -webkit-box-shadow: 0 0 0 #444;
      -moz-box-shadow: 0 0 0 #444;
      box-shadow: 0 0 0 #444; } }
    body .notifBottom {
      width: 100vw;
      height: 50px;
      position: fixed;
      float: right;
      bottom: -50px;
      right: 0;
      z-index: 99;
      font-size: 0.9em; }
      body .notifBottom .error {
        width: 100%;
        height: 100%;
        float: right;
        background-color: crimson;
        color: white;
        position: relative; }
        body .notifBottom .error .closeBtn {
          width: 30px;
          height: 30px;
          float: right;
          background-color: #c51236;
          color: white;
          text-align: center;
          line-height: 30px;
          box-sizing: border-box;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
          transition: all 0.3s linear;
          -webkit-transition: all 0.3s linear;
          -moz-transition: all 0.3s linear; }
          body .notifBottom .error .closeBtn:hover {
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
          body .notifBottom .error .closeBtn i {
            line-height: 30px; }
        body .notifBottom .error .log {
          width: 100%;
          height: 50px;
          float: right;
          padding: 0 50px;
          box-sizing: border-box;
          text-align: center;
          line-height: 50px; }
      body .notifBottom .success {
        width: 100%;
        height: 100%;
        float: right;
        background-color: forestgreen;
        color: white;
        position: relative; }
        body .notifBottom .success .closeBtn {
          width: 30px;
          height: 30px;
          float: right;
          background-color: #1d771d;
          color: white;
          text-align: center;
          line-height: 30px;
          box-sizing: border-box;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
          transition: all 0.3s linear;
          -webkit-transition: all 0.3s linear;
          -moz-transition: all 0.3s linear; }
          body .notifBottom .success .closeBtn:hover {
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
          body .notifBottom .success .closeBtn i {
            line-height: 30px; }
        body .notifBottom .success .log {
          width: 100%;
          height: 50px;
          float: right;
          padding: 0 50px;
          box-sizing: border-box;
          text-align: center;
          line-height: 50px; }
      body .notifBottom.active {
        animation: slideUp 0.6s 0.6s forwards; }
        body .notifBottom.active .log {
          animation: fadeInShadow 0.6s 0.6s forwards; }
      body .notifBottom.deactive {
        width: 100vw;
        height: 50px;
        position: fixed;
        float: right;
        bottom: 0;
        right: 0;
        z-index: 100;
        transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        animation: slideDown 0.4s 0.2s forwards; }
        body .notifBottom.deactive .log {
          animation: fadeOutShadow 0.3s 0.3s backwards; }
    body footer {
      display: none;
      width: 100%;
      float: right;
      position: fixed;
      bottom: 0;
      padding-bottom: 5px; }
      body footer .copyRight {
        width: 100%;
        float: right;
        line-height: 20px;
        padding: 0 10px;
        font-size: 10px;
        text-align: center; }
      body footer ul.socials {
        float: left;
        position: relative;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        padding: 0;
        margin: 0;
        list-style: none; }
        body footer ul.socials li {
          width: 20px;
          height: 20px;
          float: left;
          text-align: center;
          display: inline-block;
          line-height: 20px;
          margin: 0 5px;
          font-size: 18px; }
          body footer ul.socials li a {
            width: 100%;
            height: 100%;
            float: right;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear; }
            body footer ul.socials li a:active {
              opacity: 0.8; }
    body .preLoader {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      float: left;
      -moz-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: 2;
      width: 40px; }
      body .preLoader.hidden {
        display: none; }
      body .preLoader.darkBtn {
        width: 200px;
        height: 40px;
        background-color: #444444;
        border-radius: 20px; }
        body .preLoader.darkBtn .spinner.spinner-1:after {
          border-bottom-color: #ffcc00 !important;
          border-right-color: #ffcc00 !important; }
        body .preLoader.darkBtn .spinner.spinner-1:before {
          border-bottom-color: #ffcc00 !important;
          border-right-color: #ffcc00 !important; }
      body .preLoader.lightBtn {
        width: 200px;
        height: 40px;
        background-color: #ffcc00;
        border-radius: 20px;
        border: 2px solid #444444; }
        body .preLoader.lightBtn .spinner.spinner-1:after {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
        body .preLoader.lightBtn .spinner.spinner-1:before {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
      body .preLoader.list {
        width: 100%;
        height: 40px;
        background-color: transparent;
        position: relative !important;
        top: auto !important;
        left: auto !important;
        transform: none;
        box-sizing: border-box;
        margin-top: 20px; }
        body .preLoader.list .spinner.spinner-1:after {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
        body .preLoader.list .spinner.spinner-1:before {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
      body .preLoader.fullScreen {
        position: fixed !important;
        float: left;
        top: 50% !important;
        left: 50% !important;
        background-color: transparent !important;
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
        body .preLoader.fullScreen .spinner.spinner-1:after {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
        body .preLoader.fullScreen .spinner.spinner-1:before {
          border-bottom-color: #444444 !important;
          border-right-color: #444444 !important; }
      body .preLoader .spinner-block {
        width: 40px;
        text-align: center;
        position: relative;
        float: left;
        left: 50%;
        top: 50%;
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        /* spinner-1 styles */ }
        body .preLoader .spinner-block .spinner {
          position: relative;
          width: 40px;
          height: 40px; }
          body .preLoader .spinner-block .spinner:before, body .preLoader .spinner-block .spinner:after {
            content: '';
            display: block;
            position: absolute;
            border-width: 2px;
            border-style: solid;
            border-radius: 50%; }
  @keyframes rotate-animation {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
  @keyframes anti-rotate-animation {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(-360deg); } }
        body .preLoader .spinner-block .spinner.spinner-1:before {
          width: 32px;
          height: 32px;
          border-bottom-color: #444444;
          border-right-color: #444444;
          border-top-color: rgba(68, 68, 68, 0);
          border-left-color: rgba(68, 68, 68, 0);
          top: 0px;
          left: 0px;
          animation: rotate-animation 1s linear 0s infinite; }
        body .preLoader .spinner-block .spinner.spinner-1:after {
          width: 16px;
          height: 16px;
          border-bottom-color: #444444;
          border-right-color: #444444;
          border-top-color: rgba(68, 68, 68, 0);
          border-left-color: rgba(68, 68, 68, 0);
          top: 8px;
          left: 8px;
          animation: anti-rotate-animation 0.85s linear 0s infinite; } }

@media screen and (max-width: 767px) and (min-aspect-ratio: 13 / 9) {
  .rotateMessage {
    display: block !important;
    color: #444;
    width: 100vw;
    height: 100vh;
    background-color: #ffcc00;
    text-align: center;
    line-height: 100vh;
    position: fixed;
    float: left;
    left: 0;
    top: 0;
    z-index: 1000; } }

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .rotateMessage {
    display: block !important;
    color: #444;
    width: 100vw;
    height: 100vh;
    background-color: #ffcc00;
    text-align: center;
    line-height: 100vh;
    position: fixed;
    float: left;
    left: 0;
    top: 0;
    z-index: 1000; } }
