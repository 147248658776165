@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_Bold.eot");
  src: url("../../fonts/eot/IRANSansWeb_Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_Bold.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_Bold.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_Bold.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_Medium.eot");
  src: url("../../fonts/eot/IRANSansWeb_Medium.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_Medium.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_Medium.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_Medium.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_Light.eot");
  src: url("../../fonts/eot/IRANSansWeb_Light.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_Light.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_Light.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_Light.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_UltraLight.eot");
  src: url("../../fonts/eot/IRANSansWeb_UltraLight.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_UltraLight.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_UltraLight.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_UltraLight.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb.eot");
  src: url("../../fonts/eot/IRANSansWeb.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb.woff") format("woff"); }

@font-face {
  font-family: Lalezar;
  font-display: swap;
  src: url("../../fonts/Lalezar-Regular.ttf") format("truetype"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_Bold.eot");
  src: url("../../fonts/eot/IRANSansWeb_Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_Bold.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_Bold.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_Bold.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_Medium.eot");
  src: url("../../fonts/eot/IRANSansWeb_Medium.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_Medium.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_Medium.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_Medium.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_Light.eot");
  src: url("../../fonts/eot/IRANSansWeb_Light.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_Light.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_Light.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_Light.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_UltraLight.eot");
  src: url("../../fonts/eot/IRANSansWeb_UltraLight.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_UltraLight.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_UltraLight.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_UltraLight.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb.eot");
  src: url("../../fonts/eot/IRANSansWeb.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb.woff") format("woff"); }

@font-face {
  font-family: Lalezar;
  font-display: swap;
  src: url("../../fonts/Lalezar-Regular.ttf") format("truetype"); }

@media screen and (min-width: 1200px) {
  .btn {
    width: 200px;
    height: 40px;
    line-height: 26px;
    box-sizing: border-box;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    background-color: #444444;
    color: white !important;
    padding: 5px 20px;
    border: 2px solid #444444;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    font-family: "IRANSans";
    outline: none; }
    .btn:hover {
      opacity: 0.8; }
    .btn:disabled {
      opacity: 0.5 !important;
      cursor: default !important; }
  .yellowBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important; }
  .yellowBorderBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important;
    border: none; }
  .En .inputWrapper {
    float: left !important; }
    .En .inputWrapper input[type='text'],
    .En .inputWrapper input[type='email'],
    .En .inputWrapper textarea {
      padding-right: 20px !important;
      padding-left: 35px !important; }
    .En .inputWrapper img {
      float: left !important;
      left: 10px;
      right: auto !important;
      top: 10px;
      filter: invert(1); }
  .inputWrapper {
    width: 300px;
    height: 40px;
    float: right;
    position: relative; }
    .inputWrapper input[type='text'],
    .inputWrapper input[type='email'],
    .inputWrapper input[type='number'],
    .inputWrapper textarea {
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-size: 14px;
      outline: none;
      border: none;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      padding: 0 20px;
      padding-right: 35px;
      font-family: "IRANSans";
      line-height: 36px; }
      .inputWrapper input[type='text'].success,
      .inputWrapper input[type='email'].success,
      .inputWrapper input[type='number'].success,
      .inputWrapper textarea.success {
        background-color: rgba(0, 100, 0, 0.5); }
      .inputWrapper input[type='text'].error,
      .inputWrapper input[type='email'].error,
      .inputWrapper input[type='number'].error,
      .inputWrapper textarea.error {
        background-color: rgba(100, 0, 0, 0.5); }
      .inputWrapper input[type='text']:focus ~ img,
      .inputWrapper input[type='email']:focus ~ img,
      .inputWrapper input[type='number']:focus ~ img,
      .inputWrapper textarea:focus ~ img {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1); }
      .inputWrapper input[type='text']::-webkit-input-placeholder,
      .inputWrapper input[type='email']::-webkit-input-placeholder,
      .inputWrapper input[type='number']::-webkit-input-placeholder,
      .inputWrapper textarea::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']::-moz-placeholder,
      .inputWrapper input[type='email']::-moz-placeholder,
      .inputWrapper input[type='number']::-moz-placeholder,
      .inputWrapper textarea::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-ms-input-placeholder,
      .inputWrapper input[type='email']:-ms-input-placeholder,
      .inputWrapper input[type='number']:-ms-input-placeholder,
      .inputWrapper textarea:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-moz-placeholder,
      .inputWrapper input[type='email']:-moz-placeholder,
      .inputWrapper input[type='number']:-moz-placeholder,
      .inputWrapper textarea:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.5); }
    .inputWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: right;
      right: 10px;
      top: 10px;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      filter: invert(1); }
  .selectWrapper {
    width: 100px;
    height: 40px;
    float: right;
    margin-left: 10px;
    position: relative; }
    .selectWrapper select {
      -moz-appearance: none;
      -webkit-appearance: none;
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border: none;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      outline: none;
      font-family: "IRANSans";
      padding: 0 20px;
      padding-left: 35px; }
    .selectWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: left;
      left: 10px;
      top: 10px; }
  .tooltip {
    display: none;
    height: 20px;
    position: absolute;
    padding: 0 10px;
    float: right;
    box-sizing: border-box;
    z-index: 90;
    line-height: 20px;
    white-space: nowrap;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    background-color: #444444;
    color: #ffcc00;
    top: calc(100%);
    right: 50%;
    text-align: center;
    font-size: 12px;
    -moz-transform: translate(50%, 0);
    -o-transform: translate(50%, 0);
    -ms-transform: translate(50%, 0);
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0); }
    .tooltip::before {
      width: 0;
      height: 0;
      content: '';
      position: absolute;
      float: right;
      top: -10px;
      right: 50%;
      border-bottom: 5px solid #444444;
      border-top: 5px solid transparent;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      -moz-transform: translate(50%, 0);
      -o-transform: translate(50%, 0);
      -ms-transform: translate(50%, 0);
      -webkit-transform: translate(50%, 0);
      transform: translate(50%, 0); }
  @keyframes downTooltip {
    0% {
      top: calc(100% - 10px); }
    100% {
      top: calc(100%); } } }

@media screen and (max-width: 1200px) and (min-width: 768px) and (orientation: landscape) {
  .btn {
    width: 200px;
    height: 40px;
    line-height: 26px;
    box-sizing: border-box;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    background-color: #444444;
    color: white !important;
    padding: 5px 20px;
    border: 2px solid #444444;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    font-family: "IRANSans";
    outline: none; }
    .btn:hover {
      opacity: 0.8; }
    .btn:disabled {
      opacity: 0.5 !important;
      cursor: default !important; }
  .yellowBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important; }
  .yellowBorderBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important;
    border: none; }
  .En .inputWrapper {
    float: left !important; }
    .En .inputWrapper input[type='text'],
    .En .inputWrapper input[type='email'],
    .En .inputWrapper textarea {
      padding-right: 20px !important;
      padding-left: 35px !important; }
    .En .inputWrapper img {
      float: left !important;
      left: 10px;
      right: auto !important;
      top: 10px;
      filter: invert(1); }
  .inputWrapper {
    width: 300px;
    height: 40px;
    float: right;
    position: relative; }
    .inputWrapper input[type='text'],
    .inputWrapper input[type='email'],
    .inputWrapper input[type='number'],
    .inputWrapper textarea {
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-size: 14px;
      outline: none;
      border: none;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      padding: 0 20px;
      padding-right: 35px;
      font-family: "IRANSans";
      line-height: 36px; }
      .inputWrapper input[type='text'].success,
      .inputWrapper input[type='email'].success,
      .inputWrapper input[type='number'].success,
      .inputWrapper textarea.success {
        background-color: rgba(0, 100, 0, 0.5); }
      .inputWrapper input[type='text'].error,
      .inputWrapper input[type='email'].error,
      .inputWrapper input[type='number'].error,
      .inputWrapper textarea.error {
        background-color: rgba(100, 0, 0, 0.5); }
      .inputWrapper input[type='text']:focus ~ img,
      .inputWrapper input[type='email']:focus ~ img,
      .inputWrapper input[type='number']:focus ~ img,
      .inputWrapper textarea:focus ~ img {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1); }
      .inputWrapper input[type='text']::-webkit-input-placeholder,
      .inputWrapper input[type='email']::-webkit-input-placeholder,
      .inputWrapper input[type='number']::-webkit-input-placeholder,
      .inputWrapper textarea::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']::-moz-placeholder,
      .inputWrapper input[type='email']::-moz-placeholder,
      .inputWrapper input[type='number']::-moz-placeholder,
      .inputWrapper textarea::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-ms-input-placeholder,
      .inputWrapper input[type='email']:-ms-input-placeholder,
      .inputWrapper input[type='number']:-ms-input-placeholder,
      .inputWrapper textarea:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-moz-placeholder,
      .inputWrapper input[type='email']:-moz-placeholder,
      .inputWrapper input[type='number']:-moz-placeholder,
      .inputWrapper textarea:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.5); }
    .inputWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: right;
      right: 10px;
      top: 10px;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      filter: invert(1); }
  .selectWrapper {
    width: 100px;
    height: 40px;
    float: right;
    margin-left: 10px;
    position: relative; }
    .selectWrapper select {
      -moz-appearance: none;
      -webkit-appearance: none;
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border: none;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      outline: none;
      font-family: "IRANSans";
      padding: 0 20px;
      padding-left: 35px; }
    .selectWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: left;
      left: 10px;
      top: 10px; }
  .tooltip {
    display: none;
    height: 20px;
    position: absolute;
    padding: 0 10px;
    float: right;
    box-sizing: border-box;
    z-index: 90;
    line-height: 20px;
    white-space: nowrap;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    background-color: #444444;
    color: #ffcc00;
    top: calc(100%);
    right: 50%;
    text-align: center;
    font-size: 12px;
    -moz-transform: translate(50%, 0);
    -o-transform: translate(50%, 0);
    -ms-transform: translate(50%, 0);
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0); }
    .tooltip::before {
      width: 0;
      height: 0;
      content: '';
      position: absolute;
      float: right;
      top: -10px;
      right: 50%;
      border-bottom: 5px solid #444444;
      border-top: 5px solid transparent;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      -moz-transform: translate(50%, 0);
      -o-transform: translate(50%, 0);
      -ms-transform: translate(50%, 0);
      -webkit-transform: translate(50%, 0);
      transform: translate(50%, 0); }
  @keyframes downTooltip {
    0% {
      top: calc(100% - 10px); }
    100% {
      top: calc(100%); } } }

@media screen and (max-width: 1200px) and (min-width: 768px) and (orientation: portrait) {
  .btn {
    width: 200px;
    height: 40px;
    line-height: 26px;
    box-sizing: border-box;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    background-color: #444444;
    color: white !important;
    padding: 5px 20px;
    border: 2px solid #444444;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    font-family: "IRANSans"; }
    .btn:hover {
      opacity: 0.8; }
    .btn:disabled {
      opacity: 0.5; }
  .yellowBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important; }
  .yellowBorderBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important;
    border: none; }
  .En .inputWrapper {
    float: left !important; }
    .En .inputWrapper input[type='text'],
    .En .inputWrapper input[type='email'],
    .En .inputWrapper input[type='number'],
    .En .inputWrapper textarea {
      padding-right: 20px !important;
      padding-left: 35px !important; }
    .En .inputWrapper img {
      float: left !important;
      left: 10px;
      right: auto !important;
      top: 10px;
      filter: invert(1); }
  .inputWrapper {
    width: 300px;
    height: 40px;
    float: right;
    position: relative; }
    .inputWrapper input[type='text'],
    .inputWrapper input[type='email'],
    .inputWrapper textarea {
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-size: 14px;
      outline: none;
      border: none;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      padding: 0 20px;
      padding-right: 35px;
      font-family: "IRANSans";
      line-height: 36px; }
      .inputWrapper input[type='text'].success,
      .inputWrapper input[type='email'].success,
      .inputWrapper textarea.success {
        background-color: rgba(0, 100, 0, 0.5); }
      .inputWrapper input[type='text'].error,
      .inputWrapper input[type='email'].error,
      .inputWrapper textarea.error {
        background-color: rgba(100, 0, 0, 0.5); }
      .inputWrapper input[type='text']:focus ~ img,
      .inputWrapper input[type='email']:focus ~ img,
      .inputWrapper textarea:focus ~ img {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1); }
      .inputWrapper input[type='text']::-webkit-input-placeholder,
      .inputWrapper input[type='email']::-webkit-input-placeholder,
      .inputWrapper textarea::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']::-moz-placeholder,
      .inputWrapper input[type='email']::-moz-placeholder,
      .inputWrapper textarea::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-ms-input-placeholder,
      .inputWrapper input[type='email']:-ms-input-placeholder,
      .inputWrapper textarea:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-moz-placeholder,
      .inputWrapper input[type='email']:-moz-placeholder,
      .inputWrapper textarea:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.5); }
    .inputWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: right;
      right: 10px;
      top: 10px;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      filter: invert(1); }
  .selectWrapper {
    width: 100px;
    height: 40px;
    float: right;
    margin-left: 10px;
    position: relative; }
    .selectWrapper select {
      -moz-appearance: none;
      -webkit-appearance: none;
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      border: none;
      color: white;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      outline: none;
      font-family: "IRANSans";
      padding: 0 20px;
      padding-left: 35px; }
      .selectWrapper select:focus ~ img {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1); }
      .selectWrapper select::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.5); }
      .selectWrapper select::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.5); }
      .selectWrapper select:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.5); }
      .selectWrapper select:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.5); }
    .selectWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: left;
      left: 10px;
      top: 10px;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear; } }

@media screen and (max-width: 767px) {
  .btn {
    width: 250px;
    height: 40px;
    line-height: 26px;
    box-sizing: border-box;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    background-color: #444444;
    color: white !important;
    padding: 5px 20px;
    border: 2px solid #444444;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    font-family: "IRANSans" !important; }
    .btn:active {
      opacity: 0.8; }
    .btn:disabled {
      opacity: 0.5; }
  .yellowBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important; }
  .yellowBorderBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important;
    border: none; }
  .En .inputWrapper {
    float: left !important; }
    .En .inputWrapper input[type='text'],
    .En .inputWrapper input[type='email'],
    .En .inputWrapper textarea {
      padding-right: 20px !important;
      padding-left: 35px !important; }
    .En .inputWrapper img {
      float: left !important;
      left: 10px;
      right: auto !important;
      top: 10px;
      filter: invert(1); }
  .inputWrapper {
    max-width: 300px;
    width: 100%;
    height: 40px;
    float: right;
    position: relative; }
    .inputWrapper input[type='text'],
    .inputWrapper input[type='email'],
    .inputWrapper input[type='number'],
    .inputWrapper textarea {
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-size: 12px;
      outline: none;
      border: none;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      padding: 0 20px;
      padding-right: 35px;
      font-family: "IRANSans";
      line-height: 36px; }
      .inputWrapper input[type='text'].success,
      .inputWrapper input[type='email'].success,
      .inputWrapper input[type='number'].success,
      .inputWrapper textarea.success {
        background-color: rgba(0, 100, 0, 0.5); }
      .inputWrapper input[type='text'].error,
      .inputWrapper input[type='email'].error,
      .inputWrapper input[type='number'].error,
      .inputWrapper textarea.error {
        background-color: rgba(100, 0, 0, 0.5); }
      .inputWrapper input[type='text']:focus ~ img,
      .inputWrapper input[type='email']:focus ~ img,
      .inputWrapper input[type='number']:focus ~ img,
      .inputWrapper textarea:focus ~ img {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1); }
      .inputWrapper input[type='text']::-webkit-input-placeholder,
      .inputWrapper input[type='email']::-webkit-input-placeholder,
      .inputWrapper input[type='number']::-webkit-input-placeholder,
      .inputWrapper textarea::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']::-moz-placeholder,
      .inputWrapper input[type='email']::-moz-placeholder,
      .inputWrapper input[type='number']::-moz-placeholder,
      .inputWrapper textarea::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-ms-input-placeholder,
      .inputWrapper input[type='email']:-ms-input-placeholder,
      .inputWrapper input[type='number']:-ms-input-placeholder,
      .inputWrapper textarea:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-moz-placeholder,
      .inputWrapper input[type='email']:-moz-placeholder,
      .inputWrapper input[type='number']:-moz-placeholder,
      .inputWrapper textarea:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.5); }
    .inputWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: right;
      right: 10px;
      top: 10px;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      filter: invert(1); }
  .selectWrapper {
    width: 100px;
    height: 40px;
    float: right;
    margin-left: 10px;
    position: relative; }
    .selectWrapper select {
      -moz-appearance: none;
      -webkit-appearance: none;
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      border: none;
      color: white;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      outline: none;
      font-family: "IRANSans";
      padding: 0 20px;
      padding-left: 35px; }
      .selectWrapper select::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.5); }
      .selectWrapper select::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.5); }
      .selectWrapper select:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.5); }
      .selectWrapper select:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.5); }
    .selectWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: left;
      left: 10px;
      top: 10px;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear; } }

@media screen and (min-width: 1200px) {
  ::-webkit-scrollbar {
    width: 10px; }
  ::-webkit-scrollbar-thumb {
    background-color: #444444;
    border-radius: 2.5px;
    -webkit-border-radius: 2.5px;
    -moz-border-radius: 2.5px; }
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 2.5px;
    -webkit-border-radius: 2.5px;
    -moz-border-radius: 2.5px; }
  .En #profile {
    direction: ltr; }
    .En #profile .locationName img {
      float: left !important; }
    .En #profile .wrapper .nav {
      float: left !important;
      left: 0 !important; }
      .En #profile .wrapper .nav .profilePic .fileBtn {
        float: left !important;
        left: 0 !important;
        right: auto !important; }
      .En #profile .wrapper .nav ul li.active .title {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px; }
      .En #profile .wrapper .nav ul li .title {
        right: calc(100% - 20px) !important;
        left: auto !important;
        text-align: center !important; }
      .En #profile .wrapper .nav ul span.desktop {
        left: -100px !important;
        right: auto !important;
        float: left !important; }
    .En #profile .wrapper .left {
      float: right !important; }
      .En #profile .wrapper .left .creditValue,
      .En #profile .wrapper .left .addCreditBtn,
      .En #profile .wrapper .left .addCredit {
        float: left !important; }
        .En #profile .wrapper .left .creditValue span,
        .En #profile .wrapper .left .addCreditBtn span,
        .En #profile .wrapper .left .addCredit span {
          float: left !important; }
        .En #profile .wrapper .left .creditValue .toman,
        .En #profile .wrapper .left .addCreditBtn .toman,
        .En #profile .wrapper .left .addCredit .toman {
          float: right !important;
          right: 15px !important;
          left: auto !important; }
      .En #profile .wrapper .left .info .field {
        float: left !important; }
        .En #profile .wrapper .left .info .field:first-child, .En #profile .wrapper .left .info .field:nth-child(3) {
          margin-left: 0 !important; }
        .En #profile .wrapper .left .info .field .label {
          float: left !important;
          text-align: left !important; }
      .En #profile .wrapper .left .editAddresses .selectAddress .label {
        text-align: left !important; }
      .En #profile .wrapper .left .editAddresses .addresses li .radioWrapper {
        float: left !important; }
      .En #profile .wrapper .left .editAddresses .addresses li .addressText {
        float: left !important;
        padding-left: 20px !important; }
      .En #profile .wrapper .left .editAddresses .addresses li .editBtn,
      .En #profile .wrapper .left .editAddresses .addresses li .deleteBtn {
        float: right !important; }
      .En #profile .wrapper .left .editAddresses .addAddress .label {
        float: left !important; }
      .En #profile .wrapper .left .editAddresses .addAddress .mapBtn {
        float: left !important;
        width: 300px !important; }
      .En #profile .wrapper .left .editAddresses .addAddress .inputWrapper.no2Field {
        margin-left: 0 !important;
        margin-right: calc(20px / 3) !important; }
      .En #profile .wrapper .left .myOrders .shippingLevel > div {
        float: left !important; }
      .En #profile .wrapper .left .myOrders .shippingLevel > span {
        float: left !important; }
      .En #profile .wrapper .left .myOrders ul li .title {
        padding-left: 20px !important;
        padding-right: 0 !important; }
        .En #profile .wrapper .left .myOrders ul li .title span {
          float: left !important; }
        .En #profile .wrapper .left .myOrders ul li .title .downBtn {
          float: right !important; }
      .En #profile .wrapper .left .myOrders ul img {
        float: left !important; }
      .En #profile .wrapper .left .myOrders ul .matName {
        float: left !important; }
      .En #profile .wrapper .left .myOrders ul .priceNCount .count span:first-child {
        text-align: left !important;
        padding: 0 2px !important;
        box-sizing: border-box !important; }
      .En #profile .wrapper .left .myOrders ul .priceNCount .count span:last-child {
        text-align: right !important;
        padding: 0 2px !important;
        box-sizing: border-box !important; }
      .En #profile .wrapper .left .myOrders ul .priceNCount .price span:first-child {
        float: left !important;
        text-align: right !important;
        padding: 0 2px !important;
        box-sizing: border-box !important; }
      .En #profile .wrapper .left .myOrders ul .priceNCount .price span:last-child {
        float: left !important;
        text-align: left !important;
        padding: 0 2px !important;
        box-sizing: border-box !important; }
      .En #profile .wrapper .left .invitation .label,
      .En #profile .wrapper .left .setting .label {
        float: left !important;
        margin-left: 0 !important;
        margin-right: 10px !important; }
      .En #profile .wrapper .left .invitation .invitationCode,
      .En #profile .wrapper .left .invitation .shareBtns,
      .En #profile .wrapper .left .invitation .selectOptionWrapper,
      .En #profile .wrapper .left .invitation .title,
      .En #profile .wrapper .left .invitation .checkBtnWrapper,
      .En #profile .wrapper .left .setting .invitationCode,
      .En #profile .wrapper .left .setting .shareBtns,
      .En #profile .wrapper .left .setting .selectOptionWrapper,
      .En #profile .wrapper .left .setting .title,
      .En #profile .wrapper .left .setting .checkBtnWrapper {
        float: left !important; }
  #profile {
    width: 100vw;
    height: 100vh; }
    #profile .popBg2 {
      display: none;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      float: left;
      left: 0;
      z-index: 99; }
    #profile .setLocation {
      width: 800px;
      height: 570px;
      float: right;
      position: absolute;
      background-color: #ffcc00;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      right: 50%;
      top: 50%;
      margin-right: -400px;
      margin-top: -300px;
      z-index: 110;
      padding: 10px; }
      #profile .setLocation .title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        float: right;
        text-align: center; }
      #profile .setLocation #map {
        width: 100%;
        height: 400px;
        background-color: #444444;
        float: right;
        margin: 10px 0; }
      #profile .setLocation .locationName {
        height: 40px;
        float: right;
        position: relative;
        margin: 10px 0;
        right: 50%;
        -moz-transform: translate(50%, 0);
        -o-transform: translate(50%, 0);
        -ms-transform: translate(50%, 0);
        -webkit-transform: translate(50%, 0);
        transform: translate(50%, 0); }
        #profile .setLocation .locationName img {
          width: 40px;
          height: 40px;
          float: right;
          padding: 10px;
          box-sizing: border-box; }
        #profile .setLocation .locationName span {
          line-height: 40px; }
      #profile .setLocation button {
        position: relative;
        clear: both;
        float: right;
        right: 50%;
        transform: translateX(50%);
        font-family: "IRANSans"; }
    #profile .container {
      overflow: auto; }
      #profile .container .wrapper {
        width: 900px;
        height: 100%;
        float: right;
        position: relative;
        right: 50%;
        -moz-transform: translate(50%, 0);
        -o-transform: translate(50%, 0);
        -ms-transform: translate(50%, 0);
        -webkit-transform: translate(50%, 0);
        transform: translate(50%, 0);
        overflow: visible; }
        #profile .container .wrapper .nav {
          float: right;
          width: 150px;
          height: 100%;
          position: fixed;
          margin: 0 12.5px; }
          #profile .container .wrapper .nav .profilePic {
            width: 100%;
            height: 150px;
            float: right;
            margin-bottom: 10px;
            position: relative;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border: 2px solid #444444;
            box-sizing: border-box; }
            #profile .container .wrapper .nav .profilePic > img {
              width: 100%;
              height: 100%;
              float: right;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              overflow: hidden; }
            #profile .container .wrapper .nav .profilePic label {
              width: 40px;
              height: 40px;
              position: absolute;
              background-color: #ffcc00;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              border: 2px solid #444444;
              box-sizing: border-box;
              top: 0;
              float: right;
              right: 0;
              cursor: pointer;
              transition: all 0.3s linear;
              -webkit-transition: all 0.3s linear;
              -moz-transition: all 0.3s linear; }
              #profile .container .wrapper .nav .profilePic label:hover {
                -moz-transform: scale(1.05);
                -o-transform: scale(1.05);
                -ms-transform: scale(1.05);
                -webkit-transform: scale(1.05);
                transform: scale(1.05); }
              #profile .container .wrapper .nav .profilePic label input {
                display: none; }
              #profile .container .wrapper .nav .profilePic label img {
                width: 100%;
                height: 100%;
                padding: 5px;
                box-sizing: border-box;
                filter: invert(0.22); }
          #profile .container .wrapper .nav ul {
            float: right;
            right: 50%;
            height: calc(100% - 160px);
            position: relative;
            -moz-transform: translate(50%, 0);
            -o-transform: translate(50%, 0);
            -ms-transform: translate(50%, 0);
            -webkit-transform: translate(50%, 0);
            transform: translate(50%, 0);
            margin: 0;
            padding: 0;
            list-style: none; }
            #profile .container .wrapper .nav ul li {
              width: 40px;
              height: 40px;
              float: right;
              clear: both;
              margin: 5px 0;
              position: relative;
              transition: all 0.3s linear;
              -webkit-transition: all 0.3s linear;
              -moz-transition: all 0.3s linear;
              cursor: pointer; }
              #profile .container .wrapper .nav ul li.active .title {
                display: block;
                color: white;
                z-index: -1;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                text-align: center;
                left: calc(100% - 20px); }
              #profile .container .wrapper .nav ul li.active img {
                filter: brightness(0) invert(1);
                -webkit-filter: brightness(0) invert(1); }
              #profile .container .wrapper .nav ul li:hover .title {
                display: block; }
              #profile .container .wrapper .nav ul li:hover img {
                opacity: 0.8; }
              #profile .container .wrapper .nav ul li img {
                width: 100%;
                height: 100%;
                float: right;
                padding: 7.5px;
                box-sizing: border-box;
                filter: invert(0.22); }
              #profile .container .wrapper .nav ul li .title {
                display: none;
                width: 150px;
                height: 40px;
                line-height: 40px;
                position: absolute;
                left: calc(100% + 5px);
                top: 0;
                white-space: nowrap;
                font-weight: bold;
                font-size: 0.8em;
                text-align: left; }
            #profile .container .wrapper .nav ul span.desktop,
            #profile .container .wrapper .nav ul span.phone {
              width: 150px;
              height: 40px;
              float: right;
              position: absolute;
              top: 5px;
              right: -110px;
              border: 2px solid #444444;
              border-radius: 20px;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              box-sizing: border-box;
              background-color: #444444;
              z-index: -2; }
            #profile .container .wrapper .nav ul span.phone {
              display: none; }
        #profile .container .wrapper .left {
          float: left;
          position: relative;
          width: 700px;
          margin: 0 12.5px;
          height: 100%; }
          #profile .container .wrapper .left .nameNCredit {
            width: 100%;
            height: 150px;
            float: right; }
            #profile .container .wrapper .left .nameNCredit .name {
              width: 100%;
              height: 100px;
              float: right;
              line-height: 100px;
              font-weight: bold;
              font-size: 18px; }
            #profile .container .wrapper .left .nameNCredit .credit {
              width: 100%;
              height: 40px;
              line-height: 40px;
              float: right;
              margin-bottom: 10px; }
              #profile .container .wrapper .left .nameNCredit .credit .creditValue {
                max-width: 200px;
                height: 40px;
                line-height: 40px;
                margin-bottom: 10px;
                float: right; }
  @keyframes burst {
    100% {
      -moz-transform: scale(2);
      -o-transform: scale(2);
      -ms-transform: scale(2);
      -webkit-transform: scale(2);
      transform: scale(2);
      opacity: 0; } }
              #profile .container .wrapper .left .nameNCredit .credit .addCreditBtn {
                width: 40px;
                height: 40px;
                float: right;
                border: 2px solid #444444;
                box-sizing: border-box;
                text-align: center;
                line-height: 40px;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                background-color: #ffcc00;
                margin: 0 10px;
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
                -moz-transition: all 0.3s linear;
                cursor: pointer; }
                #profile .container .wrapper .left .nameNCredit .credit .addCreditBtn:hover {
                  -moz-transform: rotate(-180deg);
                  -o-transform: rotate(-180deg);
                  -ms-transform: rotate(-180deg);
                  -webkit-transform: rotate(-180deg);
                  transform: rotate(-180deg); }
                #profile .container .wrapper .left .nameNCredit .credit .addCreditBtn.clicked {
                  animation: burst 0.6s forwards; }
              #profile .container .wrapper .left .nameNCredit .credit .addCredit {
                display: none;
                height: 40px;
                float: right; }
                #profile .container .wrapper .left .nameNCredit .credit .addCredit .inputWrapper {
                  margin: 0 5px; }
                  #profile .container .wrapper .left .nameNCredit .credit .addCredit .inputWrapper input {
                    padding-left: 50px; }
                  #profile .container .wrapper .left .nameNCredit .credit .addCredit .inputWrapper .toman {
                    position: absolute;
                    float: left;
                    left: 15px;
                    color: white; }
                #profile .container .wrapper .left .nameNCredit .credit .addCredit button {
                  font-family: "IRANSans";
                  float: right;
                  position: relative; }
          #profile .container .wrapper .left .info {
            width: 100%;
            float: right;
            height: calc(100% - 150px);
            overflow: auto; }
            #profile .container .wrapper .left .info .field {
              width: 300px;
              float: right;
              margin: 10px 0;
              margin-left: 10px; }
              #profile .container .wrapper .left .info .field .label {
                width: 300px;
                height: 40px;
                float: right;
                line-height: 40px;
                text-align: right; }
              #profile .container .wrapper .left .info .field .selectOptionWrapper {
                width: 75px;
                float: right;
                position: relative;
                text-align: center; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper.day {
                  width: 70px; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper.year {
                  width: 90px; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper.month {
                  width: 120px; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper .title {
                  height: 40px;
                  line-height: 40px;
                  width: 100%;
                  float: left;
                  text-align: center; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper select {
                  -moz-appearance: none;
                  -webkit-appearance: none;
                  width: 100%;
                  height: 40px;
                  background-color: rgba(0, 0, 0, 0.5);
                  color: white;
                  font-size: 14px;
                  outline: none;
                  border: none;
                  border-radius: 20px;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  box-sizing: border-box;
                  padding: 0 20px;
                  padding-left: 35px;
                  font-family: "IRANSans";
                  line-height: 36px;
                  text-align: center;
                  background-image: url("../../media/down-arrow - white.png");
                  background-size: 20px 20px;
                  background-repeat: no-repeat;
                  background-position: 10px center;
                  cursor: pointer; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper img {
                  display: none;
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  float: left;
                  left: 10px;
                  bottom: 10px; }
              #profile .container .wrapper .left .info .field span {
                float: right;
                width: 10px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-weight: bold; }
            #profile .container .wrapper .left .info button {
              margin: 20px 0;
              font-family: "IRANSans";
              position: relative; }
          #profile .container .wrapper .left .editAddresses {
            width: 100%;
            float: right;
            height: calc(100% - 150px);
            overflow: auto; }
            #profile .container .wrapper .left .editAddresses .addresses {
              width: 100%;
              float: right;
              position: relative;
              margin: 10px 0;
              padding: 0;
              list-style: none; }
              #profile .container .wrapper .left .editAddresses .addresses .emptyText {
                width: 100%;
                float: left; }
                #profile .container .wrapper .left .editAddresses .addresses .emptyText img {
                  width: 100px;
                  height: auto;
                  float: left;
                  left: 50%;
                  position: relative;
                  -moz-transform: translate(-50%, 0);
                  -o-transform: translate(-50%, 0);
                  -ms-transform: translate(-50%, 0);
                  -webkit-transform: translate(-50%, 0);
                  transform: translate(-50%, 0); }
                #profile .container .wrapper .left .editAddresses .addresses .emptyText p {
                  text-align: center;
                  margin: 20px 0;
                  width: 100%;
                  text-align: center;
                  float: left; }
              #profile .container .wrapper .left .editAddresses .addresses li {
                width: 100%;
                height: 60px;
                float: right;
                background-color: #ffcc00;
                color: #444444;
                border: 2px solid #444444;
                border-top: none;
                overflow: hidden;
                box-sizing: border-box; }
                #profile .container .wrapper .left .editAddresses .addresses li.active {
                  background-color: #444444;
                  color: #ffcc00; }
                  #profile .container .wrapper .left .editAddresses .addresses li.active .editBtn,
                  #profile .container .wrapper .left .editAddresses .addresses li.active .deleteBtn {
                    background-color: #ffcc00; }
                    #profile .container .wrapper .left .editAddresses .addresses li.active .editBtn img.off,
                    #profile .container .wrapper .left .editAddresses .addresses li.active .deleteBtn img.off {
                      display: none; }
                    #profile .container .wrapper .left .editAddresses .addresses li.active .editBtn img.on,
                    #profile .container .wrapper .left .editAddresses .addresses li.active .deleteBtn img.on {
                      display: block; }
                #profile .container .wrapper .left .editAddresses .addresses li:first-child {
                  border-top-left-radius: 20px;
                  border-top-right-radius: 20px;
                  border-top: 2px solid #444444; }
                #profile .container .wrapper .left .editAddresses .addresses li:last-child {
                  border-bottom-left-radius: 20px;
                  border-bottom-right-radius: 20px; }
                #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper {
                  width: 20px;
                  height: 20px;
                  float: right;
                  background-color: #ffcc00;
                  border: 2px solid #444444;
                  box-sizing: border-box;
                  position: relative;
                  margin: 20px;
                  border-radius: 50%;
                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%; }
                  #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper input {
                    position: relative;
                    width: 0;
                    height: 0;
                    visibility: hidden;
                    top: 0;
                    right: 0; }
                    #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper input:checked ~ label img {
                      display: block; }
                  #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper label {
                    width: 100%;
                    height: 100%;
                    float: right;
                    position: absolute;
                    top: 0;
                    right: 0;
                    cursor: pointer; }
                    #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper label img {
                      display: none;
                      width: 16px;
                      height: 16px;
                      padding: 2px;
                      box-sizing: border-box;
                      float: right;
                      top: 0;
                      right: 0; }
                #profile .container .wrapper .left .editAddresses .addresses li .addressText {
                  width: calc(100% - 120px);
                  padding-right: 20px;
                  line-height: 60px;
                  float: right;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  cursor: pointer;
                  box-sizing: border-box; }
                #profile .container .wrapper .left .editAddresses .addresses li .editBtn,
                #profile .container .wrapper .left .editAddresses .addresses li .deleteBtn {
                  width: 22px;
                  height: 22px;
                  float: right;
                  background-color: #444444;
                  box-sizing: border-box;
                  position: relative;
                  margin: 18px;
                  border-radius: 50%;
                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%;
                  transition: all 0.3s linear;
                  -webkit-transition: all 0.3s linear;
                  -moz-transition: all 0.3s linear; }
                  #profile .container .wrapper .left .editAddresses .addresses li .editBtn img.on,
                  #profile .container .wrapper .left .editAddresses .addresses li .deleteBtn img.on {
                    display: none; }
                  #profile .container .wrapper .left .editAddresses .addresses li .editBtn img,
                  #profile .container .wrapper .left .editAddresses .addresses li .deleteBtn img {
                    width: 22px;
                    height: 22px;
                    padding: 3px;
                    box-sizing: border-box;
                    float: right;
                    top: 0;
                    right: 0;
                    cursor: pointer;
                    filter: invert(1); }
                  #profile .container .wrapper .left .editAddresses .addresses li .editBtn:hover,
                  #profile .container .wrapper .left .editAddresses .addresses li .deleteBtn:hover {
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg); }
            #profile .container .wrapper .left .editAddresses .addAddress {
              width: 100%;
              float: right;
              position: relative;
              margin: 10px 0;
              padding: 0;
              list-style: none;
              margin-top: 20px; }
              #profile .container .wrapper .left .editAddresses .addAddress .label {
                width: auto !important;
                float: right;
                height: 40px;
                position: relative;
                text-align: right;
                line-height: 40px; }
              #profile .container .wrapper .left .editAddresses .addAddress .mapBtn {
                width: auto !important;
                height: auto !important;
                float: right;
                margin: 0 10px; }
                #profile .container .wrapper .left .editAddresses .addAddress .mapBtn img {
                  width: 20px;
                  height: 20px;
                  float: left; }
              #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper {
                margin: 10px 0; }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.alleyField {
                  clear: both; }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.alleyField, #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.noField {
                  width: calc((100% / 3) - (20px / 4)); }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.no2Field, #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.no3Field {
                  width: calc((100% / 6) - (20px / 4)); }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.no2Field {
                  margin-left: calc(20px / 3); }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.noField {
                  margin: 10px calc(20px / 3); }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.nameFiled {
                  width: calc(50% - 5px);
                  margin-left: 10px; }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.phoneField {
                  width: calc(50% - 5px); }
            #profile .container .wrapper .left .editAddresses button {
              position: relative;
              font-family: "IRANSans";
              float: right;
              right: 50%;
              -moz-transform: translate(50%, 0);
              -o-transform: translate(50%, 0);
              -ms-transform: translate(50%, 0);
              -webkit-transform: translate(50%, 0);
              transform: translate(50%, 0); }
          #profile .container .wrapper .left .myOrders {
            width: 100%;
            float: right;
            height: calc(100% - 150px);
            overflow: auto; }
            #profile .container .wrapper .left .myOrders ul {
              width: calc(100% - 20px);
              float: right;
              margin: 0 10px;
              padding: 0;
              list-style: none; }
              #profile .container .wrapper .left .myOrders ul .emptyText {
                width: 100%;
                float: left; }
                #profile .container .wrapper .left .myOrders ul .emptyText img {
                  width: 100px;
                  height: auto;
                  float: left;
                  left: 50%;
                  position: relative;
                  -moz-transform: translate(-50%, 0);
                  -o-transform: translate(-50%, 0);
                  -ms-transform: translate(-50%, 0);
                  -webkit-transform: translate(-50%, 0);
                  transform: translate(-50%, 0); }
                #profile .container .wrapper .left .myOrders ul .emptyText p {
                  text-align: center;
                  margin: 20px 0;
                  width: 100%;
                  text-align: center;
                  float: left; }
              #profile .container .wrapper .left .myOrders ul li {
                width: 100%;
                float: right;
                box-sizing: border-box; }
                #profile .container .wrapper .left .myOrders ul li:first-child .title {
                  border-top-left-radius: 20px;
                  border-top-right-radius: 20px;
                  border-top: 2px solid #444444; }
                #profile .container .wrapper .left .myOrders ul li:last-child .title {
                  border-bottom-left-radius: 20px;
                  border-bottom-right-radius: 20px;
                  border-bottom: 2px solid #444444; }
                #profile .container .wrapper .left .myOrders ul li .title {
                  width: 100%;
                  height: 60px;
                  float: right;
                  border: 2px solid #444444;
                  border-top: 1px solid #444444;
                  border-bottom: 1px solid #444444;
                  background-color: #ffcc00;
                  line-height: 60px;
                  box-sizing: border-box;
                  padding-right: 20px;
                  cursor: pointer; }
                  #profile .container .wrapper .left .myOrders ul li .title span {
                    float: right;
                    line-height: 60px;
                    margin: 0 5px; }
                    #profile .container .wrapper .left .myOrders ul li .title span:first-child {
                      font-weight: bold; }
                    #profile .container .wrapper .left .myOrders ul li .title span:last-child {
                      font-size: 0.8em; }
                  #profile .container .wrapper .left .myOrders ul li .title .downBtn {
                    width: 20px;
                    height: 20px;
                    margin: 20px;
                    float: left;
                    cursor: pointer;
                    transition: all 0.3s linear;
                    -webkit-transition: all 0.3s linear;
                    -moz-transition: all 0.3s linear; }
                    #profile .container .wrapper .left .myOrders ul li .title .downBtn img {
                      width: 100%;
                      height: 100%;
                      float: left; }
                    #profile .container .wrapper .left .myOrders ul li .title .downBtn:hover {
                      -moz-transform: translate(0, 5px);
                      -o-transform: translate(0, 5px);
                      -ms-transform: translate(0, 5px);
                      -webkit-transform: translate(0, 5px);
                      transform: translate(0, 5px); }
                #profile .container .wrapper .left .myOrders ul li .details {
                  display: none;
                  width: 600px;
                  float: right;
                  margin: 0 calc(50% - 300px);
                  background-color: rgba(0, 0, 0, 0.5);
                  opacity: 0;
                  transition: opacity 1s; }
                  #profile .container .wrapper .left .myOrders ul li .details.show {
                    display: block !important;
                    opacity: 1;
                    animation: detailsFadeIn 350ms; }
  @keyframes detailsFadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
                  #profile .container .wrapper .left .myOrders ul li .details .shippingLevel {
                    width: 560px;
                    height: 50px;
                    float: right;
                    right: 50%;
                    position: relative;
                    margin-right: -280px;
                    margin-top: 20px;
                    clear: both;
                    z-index: 5;
                    color: #ffcc00; }
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1,
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2,
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3,
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4,
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5 {
                      width: 50px;
                      height: 50px;
                      float: right;
                      background-color: transparent;
                      border: 2px solid #ffcc00;
                      border-radius: 50%;
                      -webkit-border-radius: 50%;
                      -moz-border-radius: 50%;
                      box-sizing: border-box;
                      position: relative; }
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1.done,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2.done,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3.done,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4.done,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5.done {
                        background-color: #ffcc00; }
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1.done img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2.done img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3.done img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4.done img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5.done img.on {
                          display: none; }
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1.done img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2.done img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3.done img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4.done img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5.done img.off {
                          display: block;
                          filter: invert(0.22); }
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1.active:before,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2.active:before,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3.active:before,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4.active:before,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5.active:before {
                        content: '';
                        position: absolute;
                        width: 55px;
                        height: 55px;
                        float: right;
                        border: 2px dashed #ffcc00;
                        top: -6px;
                        right: -6px;
                        border-radius: 50%;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%; }
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1 img,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2 img,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3 img,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4 img,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5 img {
                        width: 30px;
                        height: 30px;
                        margin: 8px;
                        position: absolute;
                        top: 0;
                        float: right;
                        right: 0;
                        filter: invert(0.7) sepia(1) saturate(5) hue-rotate(362deg) brightness(1); }
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1 img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2 img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3 img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4 img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5 img.off {
                          display: none; }
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1 .levelName,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2 .levelName,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3 .levelName,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4 .levelName,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5 .levelName {
                        width: 150px;
                        height: 20px;
                        line-height: 20px;
                        position: absolute;
                        bottom: -30px;
                        float: right;
                        right: 50%;
                        margin-right: -75px;
                        text-align: center;
                        font-size: 12.6px; }
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel span {
                      width: calc(310px / 4);
                      height: 2px;
                      background-color: #ffcc00;
                      float: right;
                      margin: 23px 0; }
                  #profile .container .wrapper .left .myOrders ul li .details ul {
                    width: calc(100% - 20px);
                    float: right;
                    padding: 0 20px;
                    margin: 30px 0;
                    list-style: none;
                    box-sizing: border-box; }
                    #profile .container .wrapper .left .myOrders ul li .details ul li {
                      width: 100%;
                      height: 70px;
                      float: right;
                      padding: 5px;
                      box-sizing: border-box;
                      position: relative;
                      margin: 5px 0; }
                      #profile .container .wrapper .left .myOrders ul li .details ul li .deleteBtn {
                        display: none;
                        width: 20px;
                        height: 20px;
                        text-align: center;
                        line-height: 20px;
                        float: right;
                        color: white;
                        position: absolute;
                        top: 0;
                        right: 0;
                        cursor: pointer; }
                      #profile .container .wrapper .left .myOrders ul li .details ul li img {
                        width: 60px;
                        height: 60px;
                        float: right; }
                      #profile .container .wrapper .left .myOrders ul li .details ul li .matName {
                        width: calc(100% - 180px);
                        height: 60px;
                        float: right;
                        margin: 0 10px;
                        line-height: 60px;
                        color: white; }
                      #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount {
                        width: 100px;
                        height: 60px;
                        float: left;
                        color: white; }
                        #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount .price,
                        #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount .count {
                          width: 100%;
                          height: 50%;
                          float: left; }
                          #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount .price span,
                          #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount .count span {
                            width: 50%;
                            height: 100%;
                            float: right;
                            text-align: center;
                            line-height: 37.5px; }
                  #profile .container .wrapper .left .myOrders ul li .details .btns {
                    height: 40px;
                    float: right;
                    position: relative;
                    right: 50%;
                    margin: 10px 0;
                    -moz-transform: translate(50%, 0);
                    -o-transform: translate(50%, 0);
                    -ms-transform: translate(50%, 0);
                    -webkit-transform: translate(50%, 0);
                    transform: translate(50%, 0); }
                    #profile .container .wrapper .left .myOrders ul li .details .btns a {
                      float: right;
                      margin: 0 5px;
                      line-height: 30px; }
          #profile .container .wrapper .left .myPizzas {
            width: 100%;
            float: right;
            height: calc(100% - 150px);
            overflow: auto; }
            #profile .container .wrapper .left .myPizzas .pizzas {
              width: 100%;
              float: right;
              margin: 0;
              padding: 0;
              list-style: none; }
              #profile .container .wrapper .left .myPizzas .pizzas .emptyText {
                width: 100%;
                float: left; }
                #profile .container .wrapper .left .myPizzas .pizzas .emptyText img {
                  width: 100px;
                  height: auto;
                  float: left;
                  left: 50%;
                  position: relative;
                  -moz-transform: translate(-50%, 0);
                  -o-transform: translate(-50%, 0);
                  -ms-transform: translate(-50%, 0);
                  -webkit-transform: translate(-50%, 0);
                  transform: translate(-50%, 0); }
                #profile .container .wrapper .left .myPizzas .pizzas .emptyText p {
                  text-align: center;
                  margin: 20px 0;
                  width: 100%;
                  text-align: center;
                  float: left; }
              #profile .container .wrapper .left .myPizzas .pizzas li {
                width: 300px;
                height: 225px;
                margin: 10px calc(((100% / 2) - 300px) / 2);
                float: right;
                display: inline-block;
                background-color: rgba(0, 0, 0, 0.5);
                position: relative;
                margin-top: 75px;
                padding: 10px;
                padding-top: 85px;
                box-sizing: border-box;
                border-radius: 20px;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                color: white; }
                #profile .container .wrapper .left .myPizzas .pizzas li img {
                  width: 150px;
                  height: 150px;
                  position: absolute;
                  top: -75px;
                  right: 50%;
                  -moz-transform: translate(50%, 0);
                  -o-transform: translate(50%, 0);
                  -ms-transform: translate(50%, 0);
                  -webkit-transform: translate(50%, 0);
                  transform: translate(50%, 0); }
                #profile .container .wrapper .left .myPizzas .pizzas li .pizzaName {
                  width: 100%;
                  line-height: 40px;
                  font-weight: bold;
                  float: right;
                  text-align: center; }
                #profile .container .wrapper .left .myPizzas .pizzas li .ingredients {
                  width: 100%;
                  height: 50px;
                  float: right;
                  font-size: 0.7em;
                  text-align: center;
                  overflow: hidden; }
                #profile .container .wrapper .left .myPizzas .pizzas li button {
                  font-family: "IRANSans";
                  float: right;
                  right: 50%;
                  position: relative;
                  -moz-transform: translate(50%, 0);
                  -o-transform: translate(50%, 0);
                  -ms-transform: translate(50%, 0);
                  -webkit-transform: translate(50%, 0);
                  transform: translate(50%, 0); }
          #profile .container .wrapper .left .invitation {
            width: 100%;
            float: right;
            margin-top: 20px; }
            #profile .container .wrapper .left .invitation .label {
              float: right;
              height: 40px;
              line-height: 40px;
              margin-left: 10px;
              font-weight: bold; }
            #profile .container .wrapper .left .invitation .invitationCode {
              width: 200px;
              height: 40px;
              float: right;
              border: 2px solid #444444;
              box-sizing: border-box;
              border-radius: 20px;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              background-color: #ffcc00;
              text-align: center;
              line-height: 36px;
              padding: 0 20px; }
            #profile .container .wrapper .left .invitation .shareBtns {
              float: right;
              height: 40px;
              margin: 0 10px; }
              #profile .container .wrapper .left .invitation .shareBtns .copyBtn,
              #profile .container .wrapper .left .invitation .shareBtns .shareBtn {
                width: 40px;
                height: 40px;
                float: right;
                margin: 0 5px;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                background-color: #444444;
                cursor: pointer;
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
                -moz-transition: all 0.3s linear; }
                #profile .container .wrapper .left .invitation .shareBtns .copyBtn:hover,
                #profile .container .wrapper .left .invitation .shareBtns .shareBtn:hover {
                  opacity: 0.8; }
                #profile .container .wrapper .left .invitation .shareBtns .copyBtn img,
                #profile .container .wrapper .left .invitation .shareBtns .shareBtn img {
                  width: 100%;
                  height: 100%;
                  padding: 10px;
                  box-sizing: border-box; }
              #profile .container .wrapper .left .invitation .shareBtns .copyBtn img {
                filter: invert(1); }
            #profile .container .wrapper .left .invitation .desc {
              width: 100%;
              float: right;
              margin-top: 10px; }
          #profile .container .wrapper .left .setting {
            width: 100%;
            float: right;
            margin-top: 20px; }
            #profile .container .wrapper .left .setting button {
              position: relative; }
            #profile .container .wrapper .left .setting .setLang {
              width: 100%;
              float: right;
              height: 40px;
              margin: 10px 0; }
              #profile .container .wrapper .left .setting .setLang .label {
                width: 100px;
                float: right;
                margin-left: 10px;
                line-height: 40px; }
              #profile .container .wrapper .left .setting .setLang .selectOptionWrapper {
                width: 200px;
                float: right;
                position: relative;
                text-align: center; }
                #profile .container .wrapper .left .setting .setLang .selectOptionWrapper select {
                  -moz-appearance: none;
                  -webkit-appearance: none;
                  width: 100%;
                  height: 40px;
                  background-color: rgba(0, 0, 0, 0.5);
                  color: white;
                  font-size: 14px;
                  outline: none;
                  border: none;
                  border-radius: 20px;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  box-sizing: border-box;
                  padding: 0 20px;
                  padding-left: 35px;
                  font-family: "IRANSans";
                  line-height: 36px;
                  text-align: center;
                  background-image: url("../../media/down-arrow - white.png");
                  background-size: 20px 20px;
                  background-repeat: no-repeat;
                  background-position: 10px center;
                  cursor: pointer; }
                #profile .container .wrapper .left .setting .setLang .selectOptionWrapper img {
                  display: none;
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  float: left;
                  left: 10px;
                  bottom: 10px; }
            #profile .container .wrapper .left .setting .settingOption {
              width: 100%;
              float: right;
              margin: 10px 0;
              height: 40px; }
              #profile .container .wrapper .left .setting .settingOption .label {
                float: right;
                width: 200px;
                line-height: 40px; }
              #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper {
                width: 50px;
                height: 30px;
                float: right;
                margin-right: 60px !important;
                margin: 5px 0; }
                #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper input {
                  width: 0;
                  height: 0;
                  padding: 0;
                  margin: 0;
                  visibility: hidden; }
                  #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper input:checked ~ label {
                    opacity: 1; }
                    #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper input:checked ~ label .circle {
                      right: 2px; }
                #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper label {
                  width: 100%;
                  height: 100%;
                  float: right;
                  position: relative;
                  background-color: #ffcc00;
                  border: 2px solid #444444;
                  border-radius: 15px;
                  -webkit-border-radius: 15px;
                  -moz-border-radius: 15px;
                  box-sizing: border-box;
                  opacity: 0.5;
                  cursor: pointer;
                  transition: all 0.3s linear;
                  -webkit-transition: all 0.3s linear;
                  -moz-transition: all 0.3s linear; }
                  #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper label .circle {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    float: right;
                    top: 50%;
                    margin-top: -10px;
                    background-color: #444444;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    right: calc(100% - 22px); } }

@media screen and (max-width: 1200px) and (min-width: 768px) and (orientation: landscape) {
  ::-webkit-scrollbar {
    width: 10px; }
  ::-webkit-scrollbar-thumb {
    background-color: #444444;
    border-radius: 2.5px;
    -webkit-border-radius: 2.5px;
    -moz-border-radius: 2.5px; }
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 2.5px;
    -webkit-border-radius: 2.5px;
    -moz-border-radius: 2.5px; }
  .En #profile {
    direction: ltr; }
    .En #profile .locationName img {
      float: left !important; }
    .En #profile .wrapper .nav {
      float: left !important;
      left: 0 !important; }
      .En #profile .wrapper .nav .profilePic .fileBtn {
        float: left !important;
        left: 0 !important;
        right: auto !important; }
      .En #profile .wrapper .nav ul li.active .title {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px; }
      .En #profile .wrapper .nav ul li .title {
        right: calc(100% - 20px) !important;
        left: auto !important;
        text-align: center !important; }
      .En #profile .wrapper .nav ul span.desktop {
        left: -100px !important;
        right: auto !important;
        float: left !important; }
    .En #profile .wrapper .left {
      float: right !important; }
      .En #profile .wrapper .left .creditValue,
      .En #profile .wrapper .left .addCreditBtn,
      .En #profile .wrapper .left .addCredit {
        float: left !important; }
        .En #profile .wrapper .left .creditValue span,
        .En #profile .wrapper .left .addCreditBtn span,
        .En #profile .wrapper .left .addCredit span {
          float: left !important; }
        .En #profile .wrapper .left .creditValue .toman,
        .En #profile .wrapper .left .addCreditBtn .toman,
        .En #profile .wrapper .left .addCredit .toman {
          float: right !important;
          right: 15px !important;
          left: auto !important; }
      .En #profile .wrapper .left .info .field {
        float: left !important; }
        .En #profile .wrapper .left .info .field:first-child, .En #profile .wrapper .left .info .field:nth-child(3) {
          margin-left: 0 !important; }
        .En #profile .wrapper .left .info .field .label {
          float: left !important;
          text-align: left !important; }
      .En #profile .wrapper .left .editAddresses .selectAddress .label {
        text-align: left !important; }
      .En #profile .wrapper .left .editAddresses .addresses li .radioWrapper {
        float: left !important; }
      .En #profile .wrapper .left .editAddresses .addresses li .addressText {
        float: left !important;
        padding-left: 20px !important; }
      .En #profile .wrapper .left .editAddresses .addresses li .editBtn,
      .En #profile .wrapper .left .editAddresses .addresses li .deleteBtn {
        float: right !important; }
      .En #profile .wrapper .left .editAddresses .addAddress .label {
        float: left !important; }
      .En #profile .wrapper .left .editAddresses .addAddress .mapBtn {
        float: left !important;
        width: 300px !important; }
      .En #profile .wrapper .left .editAddresses .addAddress .inputWrapper.no2Field {
        margin-left: 0 !important;
        margin-right: calc(20px / 3) !important; }
      .En #profile .wrapper .left .myOrders .shippingLevel > div {
        float: left !important; }
      .En #profile .wrapper .left .myOrders .shippingLevel > span {
        float: left !important; }
      .En #profile .wrapper .left .myOrders ul li .title {
        padding-left: 20px !important;
        padding-right: 0 !important; }
        .En #profile .wrapper .left .myOrders ul li .title span {
          float: left !important; }
        .En #profile .wrapper .left .myOrders ul li .title .downBtn {
          float: right !important; }
      .En #profile .wrapper .left .myOrders ul img {
        float: left !important; }
      .En #profile .wrapper .left .myOrders ul .matName {
        float: left !important; }
      .En #profile .wrapper .left .myOrders ul .priceNCount .count span:first-child {
        text-align: left !important;
        padding: 0 2px !important;
        box-sizing: border-box !important; }
      .En #profile .wrapper .left .myOrders ul .priceNCount .count span:last-child {
        text-align: right !important;
        padding: 0 2px !important;
        box-sizing: border-box !important; }
      .En #profile .wrapper .left .myOrders ul .priceNCount .price span:first-child {
        float: left !important;
        text-align: right !important;
        padding: 0 2px !important;
        box-sizing: border-box !important; }
      .En #profile .wrapper .left .myOrders ul .priceNCount .price span:last-child {
        float: left !important;
        text-align: left !important;
        padding: 0 2px !important;
        box-sizing: border-box !important; }
      .En #profile .wrapper .left .invitation .label,
      .En #profile .wrapper .left .setting .label {
        float: left !important;
        margin-left: 0 !important;
        margin-right: 10px !important; }
      .En #profile .wrapper .left .invitation .invitationCode,
      .En #profile .wrapper .left .invitation .shareBtns,
      .En #profile .wrapper .left .invitation .selectOptionWrapper,
      .En #profile .wrapper .left .invitation .title,
      .En #profile .wrapper .left .invitation .checkBtnWrapper,
      .En #profile .wrapper .left .setting .invitationCode,
      .En #profile .wrapper .left .setting .shareBtns,
      .En #profile .wrapper .left .setting .selectOptionWrapper,
      .En #profile .wrapper .left .setting .title,
      .En #profile .wrapper .left .setting .checkBtnWrapper {
        float: left !important; }
  #profile {
    width: 100vw;
    height: 100vh; }
    #profile .popBg2 {
      display: none;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      float: left;
      left: 0;
      z-index: 99; }
    #profile .setLocation {
      width: 800px;
      height: 570px;
      float: right;
      position: absolute;
      background-color: #ffcc00;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      right: 50%;
      top: 50%;
      margin-right: -400px;
      margin-top: -300px;
      z-index: 110;
      padding: 10px; }
      #profile .setLocation .title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        float: right;
        text-align: center; }
      #profile .setLocation #map {
        width: 100%;
        height: 400px;
        background-color: #444444;
        float: right;
        margin: 10px 0; }
      #profile .setLocation .locationName {
        height: 40px;
        float: right;
        position: relative;
        margin: 10px 0;
        right: 50%;
        -moz-transform: translate(50%, 0);
        -o-transform: translate(50%, 0);
        -ms-transform: translate(50%, 0);
        -webkit-transform: translate(50%, 0);
        transform: translate(50%, 0); }
        #profile .setLocation .locationName img {
          width: 40px;
          height: 40px;
          float: right;
          padding: 10px;
          box-sizing: border-box; }
        #profile .setLocation .locationName span {
          line-height: 40px; }
      #profile .setLocation button {
        position: relative;
        clear: both;
        float: right;
        right: 50%;
        transform: translateX(50%);
        font-family: "IRANSans"; }
    #profile .container {
      overflow: auto; }
      #profile .container .wrapper {
        width: 900px;
        height: 100%;
        float: right;
        position: relative;
        right: 50%;
        -moz-transform: translate(50%, 0);
        -o-transform: translate(50%, 0);
        -ms-transform: translate(50%, 0);
        -webkit-transform: translate(50%, 0);
        transform: translate(50%, 0);
        overflow: visible; }
        #profile .container .wrapper .nav {
          float: right;
          width: 150px;
          height: 100%;
          position: fixed;
          margin: 0 12.5px; }
          #profile .container .wrapper .nav .profilePic {
            width: 100%;
            height: 150px;
            float: right;
            margin-bottom: 10px;
            position: relative;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border: 2px solid #444444;
            box-sizing: border-box; }
            #profile .container .wrapper .nav .profilePic > img {
              width: 100%;
              height: 100%;
              float: right;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              overflow: hidden; }
            #profile .container .wrapper .nav .profilePic label {
              width: 40px;
              height: 40px;
              position: absolute;
              background-color: #ffcc00;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              border: 2px solid #444444;
              box-sizing: border-box;
              top: 0;
              float: right;
              right: 0;
              cursor: pointer;
              transition: all 0.3s linear;
              -webkit-transition: all 0.3s linear;
              -moz-transition: all 0.3s linear; }
              #profile .container .wrapper .nav .profilePic label:hover {
                -moz-transform: scale(1.05);
                -o-transform: scale(1.05);
                -ms-transform: scale(1.05);
                -webkit-transform: scale(1.05);
                transform: scale(1.05); }
              #profile .container .wrapper .nav .profilePic label input {
                display: none; }
              #profile .container .wrapper .nav .profilePic label img {
                width: 100%;
                height: 100%;
                padding: 5px;
                box-sizing: border-box;
                filter: invert(0.22); }
          #profile .container .wrapper .nav ul {
            float: right;
            right: 50%;
            height: calc(100% - 160px);
            position: relative;
            -moz-transform: translate(50%, 0);
            -o-transform: translate(50%, 0);
            -ms-transform: translate(50%, 0);
            -webkit-transform: translate(50%, 0);
            transform: translate(50%, 0);
            margin: 0;
            padding: 0;
            list-style: none; }
            #profile .container .wrapper .nav ul li {
              width: 40px;
              height: 40px;
              float: right;
              clear: both;
              margin: 5px 0;
              position: relative;
              transition: all 0.3s linear;
              -webkit-transition: all 0.3s linear;
              -moz-transition: all 0.3s linear;
              cursor: pointer; }
              #profile .container .wrapper .nav ul li.active .title {
                display: block;
                color: white;
                z-index: -1;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                text-align: center;
                left: calc(100% - 20px); }
              #profile .container .wrapper .nav ul li.active img {
                filter: brightness(0) invert(1);
                -webkit-filter: brightness(0) invert(1); }
              #profile .container .wrapper .nav ul li:hover .title {
                display: block; }
              #profile .container .wrapper .nav ul li:hover img {
                opacity: 0.8; }
              #profile .container .wrapper .nav ul li img {
                width: 100%;
                height: 100%;
                float: right;
                padding: 7.5px;
                box-sizing: border-box;
                filter: invert(0.22); }
              #profile .container .wrapper .nav ul li .title {
                display: none;
                width: 150px;
                height: 40px;
                line-height: 40px;
                position: absolute;
                left: calc(100% + 5px);
                top: 0;
                white-space: nowrap;
                font-weight: bold;
                font-size: 0.8em;
                text-align: left; }
            #profile .container .wrapper .nav ul span.desktop,
            #profile .container .wrapper .nav ul span.phone {
              width: 150px;
              height: 40px;
              float: right;
              position: absolute;
              top: 5px;
              right: -110px;
              border: 2px solid #444444;
              border-radius: 20px;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              box-sizing: border-box;
              background-color: #444444;
              z-index: -2; }
            #profile .container .wrapper .nav ul span.phone {
              display: none; }
        #profile .container .wrapper .left {
          float: left;
          position: relative;
          width: 700px;
          margin: 0 12.5px;
          height: 100%; }
          #profile .container .wrapper .left .nameNCredit {
            width: 100%;
            height: 150px;
            float: right; }
            #profile .container .wrapper .left .nameNCredit .name {
              width: 100%;
              height: 100px;
              float: right;
              line-height: 100px;
              font-weight: bold;
              font-size: 18px; }
            #profile .container .wrapper .left .nameNCredit .credit {
              width: 100%;
              height: 40px;
              line-height: 40px;
              float: right;
              margin-bottom: 10px; }
              #profile .container .wrapper .left .nameNCredit .credit .creditValue {
                max-width: 200px;
                height: 40px;
                line-height: 40px;
                margin-bottom: 10px;
                float: right; }
  @keyframes burst {
    100% {
      -moz-transform: scale(2);
      -o-transform: scale(2);
      -ms-transform: scale(2);
      -webkit-transform: scale(2);
      transform: scale(2);
      opacity: 0; } }
              #profile .container .wrapper .left .nameNCredit .credit .addCreditBtn {
                width: 40px;
                height: 40px;
                float: right;
                border: 2px solid #444444;
                box-sizing: border-box;
                text-align: center;
                line-height: 40px;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                background-color: #ffcc00;
                margin: 0 10px;
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
                -moz-transition: all 0.3s linear;
                cursor: pointer; }
                #profile .container .wrapper .left .nameNCredit .credit .addCreditBtn:hover {
                  -moz-transform: rotate(-180deg);
                  -o-transform: rotate(-180deg);
                  -ms-transform: rotate(-180deg);
                  -webkit-transform: rotate(-180deg);
                  transform: rotate(-180deg); }
                #profile .container .wrapper .left .nameNCredit .credit .addCreditBtn.clicked {
                  animation: burst 0.6s forwards; }
              #profile .container .wrapper .left .nameNCredit .credit .addCredit {
                display: none;
                height: 40px;
                float: right; }
                #profile .container .wrapper .left .nameNCredit .credit .addCredit .inputWrapper {
                  margin: 0 5px; }
                  #profile .container .wrapper .left .nameNCredit .credit .addCredit .inputWrapper input {
                    padding-left: 50px; }
                  #profile .container .wrapper .left .nameNCredit .credit .addCredit .inputWrapper .toman {
                    position: absolute;
                    float: left;
                    left: 15px;
                    color: white; }
                #profile .container .wrapper .left .nameNCredit .credit .addCredit button {
                  font-family: "IRANSans";
                  float: right;
                  position: relative; }
          #profile .container .wrapper .left .info {
            width: 100%;
            float: right;
            height: calc(100% - 150px);
            overflow: auto; }
            #profile .container .wrapper .left .info .field {
              width: 300px;
              float: right;
              margin: 10px 0;
              margin-left: 10px; }
              #profile .container .wrapper .left .info .field .label {
                width: 300px;
                height: 40px;
                float: right;
                line-height: 40px;
                text-align: right; }
              #profile .container .wrapper .left .info .field .selectOptionWrapper {
                width: 75px;
                float: right;
                position: relative;
                text-align: center; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper.day {
                  width: 70px; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper.year {
                  width: 90px; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper.month {
                  width: 120px; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper .title {
                  height: 40px;
                  line-height: 40px;
                  width: 100%;
                  float: left;
                  text-align: center; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper select {
                  -moz-appearance: none;
                  -webkit-appearance: none;
                  width: 100%;
                  height: 40px;
                  background-color: rgba(0, 0, 0, 0.5);
                  color: white;
                  font-size: 14px;
                  outline: none;
                  border: none;
                  border-radius: 20px;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  box-sizing: border-box;
                  padding: 0 20px;
                  padding-left: 35px;
                  font-family: "IRANSans";
                  line-height: 36px;
                  text-align: center;
                  background-image: url("../../media/down-arrow - white.png");
                  background-size: 20px 20px;
                  background-repeat: no-repeat;
                  background-position: 10px center;
                  cursor: pointer; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper img {
                  display: none;
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  float: left;
                  left: 10px;
                  bottom: 10px; }
              #profile .container .wrapper .left .info .field span {
                float: right;
                width: 10px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-weight: bold; }
            #profile .container .wrapper .left .info button {
              margin: 20px 0;
              font-family: "IRANSans";
              position: relative; }
          #profile .container .wrapper .left .editAddresses {
            width: 100%;
            float: right;
            height: calc(100% - 150px);
            overflow: auto; }
            #profile .container .wrapper .left .editAddresses .addresses {
              width: 100%;
              float: right;
              position: relative;
              margin: 10px 0;
              padding: 0;
              list-style: none; }
              #profile .container .wrapper .left .editAddresses .addresses .emptyText {
                width: 100%;
                float: left; }
                #profile .container .wrapper .left .editAddresses .addresses .emptyText img {
                  width: 100px;
                  height: auto;
                  float: left;
                  left: 50%;
                  position: relative;
                  -moz-transform: translate(-50%, 0);
                  -o-transform: translate(-50%, 0);
                  -ms-transform: translate(-50%, 0);
                  -webkit-transform: translate(-50%, 0);
                  transform: translate(-50%, 0); }
                #profile .container .wrapper .left .editAddresses .addresses .emptyText p {
                  text-align: center;
                  margin: 20px 0;
                  width: 100%;
                  text-align: center;
                  float: left; }
              #profile .container .wrapper .left .editAddresses .addresses li {
                width: 100%;
                height: 60px;
                float: right;
                background-color: #ffcc00;
                color: #444444;
                border: 2px solid #444444;
                border-top: none;
                overflow: hidden;
                box-sizing: border-box; }
                #profile .container .wrapper .left .editAddresses .addresses li.active {
                  background-color: #444444;
                  color: #ffcc00; }
                  #profile .container .wrapper .left .editAddresses .addresses li.active .editBtn,
                  #profile .container .wrapper .left .editAddresses .addresses li.active .deleteBtn {
                    background-color: #ffcc00; }
                    #profile .container .wrapper .left .editAddresses .addresses li.active .editBtn img.off,
                    #profile .container .wrapper .left .editAddresses .addresses li.active .deleteBtn img.off {
                      display: none; }
                    #profile .container .wrapper .left .editAddresses .addresses li.active .editBtn img.on,
                    #profile .container .wrapper .left .editAddresses .addresses li.active .deleteBtn img.on {
                      display: block; }
                #profile .container .wrapper .left .editAddresses .addresses li:first-child {
                  border-top-left-radius: 20px;
                  border-top-right-radius: 20px;
                  border-top: 2px solid #444444; }
                #profile .container .wrapper .left .editAddresses .addresses li:last-child {
                  border-bottom-left-radius: 20px;
                  border-bottom-right-radius: 20px; }
                #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper {
                  width: 20px;
                  height: 20px;
                  float: right;
                  background-color: #ffcc00;
                  border: 2px solid #444444;
                  box-sizing: border-box;
                  position: relative;
                  margin: 20px;
                  border-radius: 50%;
                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%; }
                  #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper input {
                    position: relative;
                    width: 0;
                    height: 0;
                    visibility: hidden;
                    top: 0;
                    right: 0; }
                    #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper input:checked ~ label img {
                      display: block; }
                  #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper label {
                    width: 100%;
                    height: 100%;
                    float: right;
                    position: absolute;
                    top: 0;
                    right: 0;
                    cursor: pointer; }
                    #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper label img {
                      display: none;
                      width: 16px;
                      height: 16px;
                      padding: 2px;
                      box-sizing: border-box;
                      float: right;
                      top: 0;
                      right: 0; }
                #profile .container .wrapper .left .editAddresses .addresses li .addressText {
                  width: calc(100% - 120px);
                  padding-right: 20px;
                  line-height: 60px;
                  float: right;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  cursor: pointer;
                  box-sizing: border-box; }
                #profile .container .wrapper .left .editAddresses .addresses li .editBtn,
                #profile .container .wrapper .left .editAddresses .addresses li .deleteBtn {
                  width: 22px;
                  height: 22px;
                  float: right;
                  background-color: #444444;
                  box-sizing: border-box;
                  position: relative;
                  margin: 18px;
                  border-radius: 50%;
                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%;
                  transition: all 0.3s linear;
                  -webkit-transition: all 0.3s linear;
                  -moz-transition: all 0.3s linear; }
                  #profile .container .wrapper .left .editAddresses .addresses li .editBtn img.on,
                  #profile .container .wrapper .left .editAddresses .addresses li .deleteBtn img.on {
                    display: none; }
                  #profile .container .wrapper .left .editAddresses .addresses li .editBtn img,
                  #profile .container .wrapper .left .editAddresses .addresses li .deleteBtn img {
                    width: 22px;
                    height: 22px;
                    padding: 3px;
                    box-sizing: border-box;
                    float: right;
                    top: 0;
                    right: 0;
                    cursor: pointer;
                    filter: invert(1); }
                  #profile .container .wrapper .left .editAddresses .addresses li .editBtn:hover,
                  #profile .container .wrapper .left .editAddresses .addresses li .deleteBtn:hover {
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg); }
            #profile .container .wrapper .left .editAddresses .addAddress {
              width: 100%;
              float: right;
              position: relative;
              margin: 10px 0;
              padding: 0;
              list-style: none;
              margin-top: 20px; }
              #profile .container .wrapper .left .editAddresses .addAddress .label {
                width: auto !important;
                float: right;
                height: 40px;
                position: relative;
                text-align: right;
                line-height: 40px; }
              #profile .container .wrapper .left .editAddresses .addAddress .mapBtn {
                width: auto !important;
                height: auto !important;
                float: right;
                margin: 0 10px; }
                #profile .container .wrapper .left .editAddresses .addAddress .mapBtn img {
                  width: 20px;
                  height: 20px;
                  float: left; }
              #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper {
                margin: 10px 0; }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.alleyField {
                  clear: both; }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.alleyField, #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.noField {
                  width: calc((100% / 3) - (20px / 4)); }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.no2Field, #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.no3Field {
                  width: calc((100% / 6) - (20px / 4)); }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.no2Field {
                  margin-left: calc(20px / 3); }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.noField {
                  margin: 10px calc(20px / 3); }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.nameFiled {
                  width: calc(50% - 5px);
                  margin-left: 10px; }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.phoneField {
                  width: calc(50% - 5px); }
            #profile .container .wrapper .left .editAddresses button {
              position: relative;
              font-family: "IRANSans";
              float: right;
              right: 50%;
              -moz-transform: translate(50%, 0);
              -o-transform: translate(50%, 0);
              -ms-transform: translate(50%, 0);
              -webkit-transform: translate(50%, 0);
              transform: translate(50%, 0); }
          #profile .container .wrapper .left .myOrders {
            width: 100%;
            float: right;
            height: calc(100% - 150px);
            overflow: auto; }
            #profile .container .wrapper .left .myOrders ul {
              width: calc(100% - 20px);
              float: right;
              margin: 0 10px;
              padding: 0;
              list-style: none; }
              #profile .container .wrapper .left .myOrders ul .emptyText {
                width: 100%;
                float: left; }
                #profile .container .wrapper .left .myOrders ul .emptyText img {
                  width: 100px;
                  height: auto;
                  float: left;
                  left: 50%;
                  position: relative;
                  -moz-transform: translate(-50%, 0);
                  -o-transform: translate(-50%, 0);
                  -ms-transform: translate(-50%, 0);
                  -webkit-transform: translate(-50%, 0);
                  transform: translate(-50%, 0); }
                #profile .container .wrapper .left .myOrders ul .emptyText p {
                  text-align: center;
                  margin: 20px 0;
                  width: 100%;
                  text-align: center;
                  float: left; }
              #profile .container .wrapper .left .myOrders ul li {
                width: 100%;
                float: right;
                box-sizing: border-box; }
                #profile .container .wrapper .left .myOrders ul li:first-child .title {
                  border-top-left-radius: 20px;
                  border-top-right-radius: 20px;
                  border-top: 2px solid #444444; }
                #profile .container .wrapper .left .myOrders ul li:last-child .title {
                  border-bottom-left-radius: 20px;
                  border-bottom-right-radius: 20px;
                  border-bottom: 2px solid #444444; }
                #profile .container .wrapper .left .myOrders ul li .title {
                  width: 100%;
                  height: 60px;
                  float: right;
                  border: 2px solid #444444;
                  border-top: 1px solid #444444;
                  border-bottom: 1px solid #444444;
                  background-color: #ffcc00;
                  line-height: 60px;
                  box-sizing: border-box;
                  padding-right: 20px;
                  cursor: pointer; }
                  #profile .container .wrapper .left .myOrders ul li .title span {
                    float: right;
                    line-height: 60px;
                    margin: 0 5px; }
                    #profile .container .wrapper .left .myOrders ul li .title span:first-child {
                      font-weight: bold; }
                    #profile .container .wrapper .left .myOrders ul li .title span:last-child {
                      font-size: 0.8em; }
                  #profile .container .wrapper .left .myOrders ul li .title .downBtn {
                    width: 20px;
                    height: 20px;
                    margin: 20px;
                    float: left;
                    cursor: pointer;
                    transition: all 0.3s linear;
                    -webkit-transition: all 0.3s linear;
                    -moz-transition: all 0.3s linear; }
                    #profile .container .wrapper .left .myOrders ul li .title .downBtn img {
                      width: 100%;
                      height: 100%;
                      float: left; }
                    #profile .container .wrapper .left .myOrders ul li .title .downBtn:hover {
                      -moz-transform: translate(0, 5px);
                      -o-transform: translate(0, 5px);
                      -ms-transform: translate(0, 5px);
                      -webkit-transform: translate(0, 5px);
                      transform: translate(0, 5px); }
                #profile .container .wrapper .left .myOrders ul li .details {
                  display: none;
                  width: 600px;
                  float: right;
                  margin: 0 calc(50% - 300px);
                  background-color: rgba(0, 0, 0, 0.5);
                  opacity: 0;
                  transition: opacity 1s; }
                  #profile .container .wrapper .left .myOrders ul li .details.show {
                    display: block !important;
                    opacity: 1;
                    animation: detailsFadeIn 350ms; }
  @keyframes detailsFadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
                  #profile .container .wrapper .left .myOrders ul li .details .shippingLevel {
                    width: 560px;
                    height: 50px;
                    float: right;
                    right: 50%;
                    position: relative;
                    margin-right: -280px;
                    margin-top: 20px;
                    clear: both;
                    z-index: 5;
                    color: #ffcc00; }
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1,
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2,
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3,
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4,
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5 {
                      width: 50px;
                      height: 50px;
                      float: right;
                      background-color: transparent;
                      border: 2px solid #ffcc00;
                      border-radius: 50%;
                      -webkit-border-radius: 50%;
                      -moz-border-radius: 50%;
                      box-sizing: border-box;
                      position: relative; }
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1.done,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2.done,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3.done,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4.done,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5.done {
                        background-color: #ffcc00; }
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1.done img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2.done img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3.done img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4.done img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5.done img.on {
                          display: none; }
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1.done img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2.done img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3.done img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4.done img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5.done img.off {
                          display: block;
                          filter: invert(0.22); }
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1.active:before,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2.active:before,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3.active:before,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4.active:before,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5.active:before {
                        content: '';
                        position: absolute;
                        width: 55px;
                        height: 55px;
                        float: right;
                        border: 2px dashed #ffcc00;
                        top: -6px;
                        right: -6px;
                        border-radius: 50%;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%; }
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1 img,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2 img,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3 img,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4 img,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5 img {
                        width: 30px;
                        height: 30px;
                        margin: 8px;
                        position: absolute;
                        top: 0;
                        float: right;
                        right: 0;
                        filter: invert(0.7) sepia(1) saturate(5) hue-rotate(362deg) brightness(1); }
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1 img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2 img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3 img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4 img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5 img.off {
                          display: none; }
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1 .levelName,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2 .levelName,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3 .levelName,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4 .levelName,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5 .levelName {
                        width: 150px;
                        height: 20px;
                        line-height: 20px;
                        position: absolute;
                        bottom: -30px;
                        float: right;
                        right: 50%;
                        margin-right: -75px;
                        text-align: center;
                        font-size: 12.6px; }
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel span {
                      width: calc(310px / 4);
                      height: 2px;
                      background-color: #ffcc00;
                      float: right;
                      margin: 23px 0; }
                  #profile .container .wrapper .left .myOrders ul li .details ul {
                    width: calc(100% - 20px);
                    float: right;
                    padding: 0 20px;
                    margin: 30px 0;
                    list-style: none;
                    box-sizing: border-box; }
                    #profile .container .wrapper .left .myOrders ul li .details ul li {
                      width: 100%;
                      height: 70px;
                      float: right;
                      padding: 5px;
                      box-sizing: border-box;
                      position: relative;
                      margin: 5px 0; }
                      #profile .container .wrapper .left .myOrders ul li .details ul li .deleteBtn {
                        display: none;
                        width: 20px;
                        height: 20px;
                        text-align: center;
                        line-height: 20px;
                        float: right;
                        color: white;
                        position: absolute;
                        top: 0;
                        right: 0;
                        cursor: pointer; }
                      #profile .container .wrapper .left .myOrders ul li .details ul li img {
                        width: 60px;
                        height: 60px;
                        float: right; }
                      #profile .container .wrapper .left .myOrders ul li .details ul li .matName {
                        width: calc(100% - 180px);
                        height: 60px;
                        float: right;
                        margin: 0 10px;
                        line-height: 60px;
                        color: white; }
                      #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount {
                        width: 100px;
                        height: 60px;
                        float: left;
                        color: white; }
                        #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount .price,
                        #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount .count {
                          width: 100%;
                          height: 50%;
                          float: left; }
                          #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount .price span,
                          #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount .count span {
                            width: 50%;
                            height: 100%;
                            float: right;
                            text-align: center;
                            line-height: 37.5px; }
                  #profile .container .wrapper .left .myOrders ul li .details .btns {
                    height: 40px;
                    float: right;
                    position: relative;
                    right: 50%;
                    margin: 10px 0;
                    -moz-transform: translate(50%, 0);
                    -o-transform: translate(50%, 0);
                    -ms-transform: translate(50%, 0);
                    -webkit-transform: translate(50%, 0);
                    transform: translate(50%, 0); }
                    #profile .container .wrapper .left .myOrders ul li .details .btns a {
                      float: right;
                      margin: 0 5px;
                      line-height: 30px; }
          #profile .container .wrapper .left .myPizzas {
            width: 100%;
            float: right;
            height: calc(100% - 150px);
            overflow: auto; }
            #profile .container .wrapper .left .myPizzas .pizzas {
              width: 100%;
              float: right;
              margin: 0;
              padding: 0;
              list-style: none; }
              #profile .container .wrapper .left .myPizzas .pizzas .emptyText {
                width: 100%;
                float: left; }
                #profile .container .wrapper .left .myPizzas .pizzas .emptyText img {
                  width: 100px;
                  height: auto;
                  float: left;
                  left: 50%;
                  position: relative;
                  -moz-transform: translate(-50%, 0);
                  -o-transform: translate(-50%, 0);
                  -ms-transform: translate(-50%, 0);
                  -webkit-transform: translate(-50%, 0);
                  transform: translate(-50%, 0); }
                #profile .container .wrapper .left .myPizzas .pizzas .emptyText p {
                  text-align: center;
                  margin: 20px 0;
                  width: 100%;
                  text-align: center;
                  float: left; }
              #profile .container .wrapper .left .myPizzas .pizzas li {
                width: 300px;
                height: 225px;
                margin: 10px calc(((100% / 2) - 300px) / 2);
                float: right;
                display: inline-block;
                background-color: rgba(0, 0, 0, 0.5);
                position: relative;
                margin-top: 75px;
                padding: 10px;
                padding-top: 85px;
                box-sizing: border-box;
                border-radius: 20px;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                color: white; }
                #profile .container .wrapper .left .myPizzas .pizzas li img {
                  width: 150px;
                  height: 150px;
                  position: absolute;
                  top: -75px;
                  right: 50%;
                  -moz-transform: translate(50%, 0);
                  -o-transform: translate(50%, 0);
                  -ms-transform: translate(50%, 0);
                  -webkit-transform: translate(50%, 0);
                  transform: translate(50%, 0); }
                #profile .container .wrapper .left .myPizzas .pizzas li .pizzaName {
                  width: 100%;
                  line-height: 40px;
                  font-weight: bold;
                  float: right;
                  text-align: center; }
                #profile .container .wrapper .left .myPizzas .pizzas li .ingredients {
                  width: 100%;
                  height: 50px;
                  float: right;
                  font-size: 0.7em;
                  text-align: center;
                  overflow: hidden; }
                #profile .container .wrapper .left .myPizzas .pizzas li button {
                  font-family: "IRANSans";
                  float: right;
                  right: 50%;
                  position: relative;
                  -moz-transform: translate(50%, 0);
                  -o-transform: translate(50%, 0);
                  -ms-transform: translate(50%, 0);
                  -webkit-transform: translate(50%, 0);
                  transform: translate(50%, 0); }
          #profile .container .wrapper .left .invitation {
            width: 100%;
            float: right;
            margin-top: 20px; }
            #profile .container .wrapper .left .invitation .label {
              float: right;
              height: 40px;
              line-height: 40px;
              margin-left: 10px;
              font-weight: bold; }
            #profile .container .wrapper .left .invitation .invitationCode {
              width: 200px;
              height: 40px;
              float: right;
              border: 2px solid #444444;
              box-sizing: border-box;
              border-radius: 20px;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              background-color: #ffcc00;
              text-align: center;
              line-height: 36px;
              padding: 0 20px; }
            #profile .container .wrapper .left .invitation .shareBtns {
              float: right;
              height: 40px;
              margin: 0 10px; }
              #profile .container .wrapper .left .invitation .shareBtns .copyBtn,
              #profile .container .wrapper .left .invitation .shareBtns .shareBtn {
                width: 40px;
                height: 40px;
                float: right;
                margin: 0 5px;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                background-color: #444444;
                cursor: pointer;
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
                -moz-transition: all 0.3s linear; }
                #profile .container .wrapper .left .invitation .shareBtns .copyBtn:hover,
                #profile .container .wrapper .left .invitation .shareBtns .shareBtn:hover {
                  opacity: 0.8; }
                #profile .container .wrapper .left .invitation .shareBtns .copyBtn img,
                #profile .container .wrapper .left .invitation .shareBtns .shareBtn img {
                  width: 100%;
                  height: 100%;
                  padding: 10px;
                  box-sizing: border-box; }
              #profile .container .wrapper .left .invitation .shareBtns .copyBtn img {
                filter: invert(1); }
            #profile .container .wrapper .left .invitation .desc {
              width: 100%;
              float: right;
              margin-top: 10px; }
          #profile .container .wrapper .left .setting {
            width: 100%;
            float: right;
            margin-top: 20px; }
            #profile .container .wrapper .left .setting button {
              position: relative; }
            #profile .container .wrapper .left .setting .setLang {
              width: 100%;
              float: right;
              height: 40px;
              margin: 10px 0; }
              #profile .container .wrapper .left .setting .setLang .label {
                width: 100px;
                float: right;
                margin-left: 10px;
                line-height: 40px; }
              #profile .container .wrapper .left .setting .setLang .selectOptionWrapper {
                width: 200px;
                float: right;
                position: relative;
                text-align: center; }
                #profile .container .wrapper .left .setting .setLang .selectOptionWrapper select {
                  -moz-appearance: none;
                  -webkit-appearance: none;
                  width: 100%;
                  height: 40px;
                  background-color: rgba(0, 0, 0, 0.5);
                  color: white;
                  font-size: 14px;
                  outline: none;
                  border: none;
                  border-radius: 20px;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  box-sizing: border-box;
                  padding: 0 20px;
                  padding-left: 35px;
                  font-family: "IRANSans";
                  line-height: 36px;
                  text-align: center;
                  background-image: url("../../media/down-arrow - white.png");
                  background-size: 20px 20px;
                  background-repeat: no-repeat;
                  background-position: 10px center;
                  cursor: pointer; }
                #profile .container .wrapper .left .setting .setLang .selectOptionWrapper img {
                  display: none;
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  float: left;
                  left: 10px;
                  bottom: 10px; }
            #profile .container .wrapper .left .setting .settingOption {
              width: 100%;
              float: right;
              margin: 10px 0;
              height: 40px; }
              #profile .container .wrapper .left .setting .settingOption .label {
                float: right;
                width: 200px;
                line-height: 40px; }
              #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper {
                width: 50px;
                height: 30px;
                float: right;
                margin-right: 60px !important;
                margin: 5px 0; }
                #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper input {
                  width: 0;
                  height: 0;
                  padding: 0;
                  margin: 0;
                  visibility: hidden; }
                  #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper input:checked ~ label {
                    opacity: 1; }
                    #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper input:checked ~ label .circle {
                      right: 2px; }
                #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper label {
                  width: 100%;
                  height: 100%;
                  float: right;
                  position: relative;
                  background-color: #ffcc00;
                  border: 2px solid #444444;
                  border-radius: 15px;
                  -webkit-border-radius: 15px;
                  -moz-border-radius: 15px;
                  box-sizing: border-box;
                  opacity: 0.5;
                  cursor: pointer;
                  transition: all 0.3s linear;
                  -webkit-transition: all 0.3s linear;
                  -moz-transition: all 0.3s linear; }
                  #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper label .circle {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    float: right;
                    top: 50%;
                    margin-top: -10px;
                    background-color: #444444;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    right: calc(100% - 22px); } }

@media screen and (max-width: 1200px) and (min-width: 768px) and (orientation: portrait) {
  ::-webkit-scrollbar {
    width: 5px; }
  ::-webkit-scrollbar-thumb {
    background-color: #444444;
    border-radius: 2.5px;
    -webkit-border-radius: 2.5px;
    -moz-border-radius: 2.5px; }
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 2.5px;
    -webkit-border-radius: 2.5px;
    -moz-border-radius: 2.5px; }
  .En #profile {
    direction: ltr; }
    .En #profile .copyRight {
      float: left !important; }
    .En #profile .socials {
      float: right !important; }
    .En #profile .locationName img {
      float: left !important; }
    .En #profile .wrapper .nav {
      float: left !important;
      left: 0 !important; }
      .En #profile .wrapper .nav .profilePic .fileBtn {
        float: left !important;
        left: 0 !important;
        right: auto; }
      .En #profile .wrapper .nav ul li {
        float: left !important; }
      .En #profile .wrapper .nav ul span.phone {
        left: 0;
        right: auto !important; }
    .En #profile .wrapper .left {
      float: right !important; }
      .En #profile .wrapper .left .creditValue,
      .En #profile .wrapper .left .addCreditBtn,
      .En #profile .wrapper .left .addCredit {
        float: left !important; }
        .En #profile .wrapper .left .creditValue span,
        .En #profile .wrapper .left .addCreditBtn span,
        .En #profile .wrapper .left .addCredit span {
          float: left !important; }
        .En #profile .wrapper .left .creditValue .toman,
        .En #profile .wrapper .left .addCreditBtn .toman,
        .En #profile .wrapper .left .addCredit .toman {
          float: right !important;
          right: 15px !important;
          left: auto !important; }
      .En #profile .wrapper .left .info .field {
        float: left !important;
        margin-left: 10px !important; }
        .En #profile .wrapper .left .info .field:first-child, .En #profile .wrapper .left .info .field:nth-child(3) {
          margin-left: 0 !important; }
        .En #profile .wrapper .left .info .field .label {
          float: left !important;
          text-align: left !important; }
      .En #profile .wrapper .left .editAddresses .selectAddress .label {
        text-align: left !important; }
      .En #profile .wrapper .left .editAddresses .addresses li .radioWrapper {
        float: left !important; }
      .En #profile .wrapper .left .editAddresses .addresses li .addressText {
        float: left !important;
        padding-left: 20px !important; }
      .En #profile .wrapper .left .editAddresses .addresses li .editBtn,
      .En #profile .wrapper .left .editAddresses .addresses li .deleteBtn {
        float: right !important; }
      .En #profile .wrapper .left .editAddresses .addAddress .label {
        float: left !important; }
      .En #profile .wrapper .left .editAddresses .addAddress .mapBtn {
        float: left !important;
        width: 300px !important; }
      .En #profile .wrapper .left .editAddresses .addAddress .inputWrapper.no2Field {
        margin-left: 0 !important;
        margin-right: calc(20px / 3) !important; }
      .En #profile .wrapper .left .myOrders .shippingLevel > div {
        float: left !important; }
      .En #profile .wrapper .left .myOrders .shippingLevel > span {
        float: left !important; }
      .En #profile .wrapper .left .myOrders ul li .title {
        padding-left: 20px !important;
        padding-right: 0 !important; }
        .En #profile .wrapper .left .myOrders ul li .title span {
          float: left !important; }
        .En #profile .wrapper .left .myOrders ul li .title .downBtn {
          float: right !important; }
      .En #profile .wrapper .left .myOrders ul img {
        float: left !important; }
      .En #profile .wrapper .left .myOrders ul .matName {
        float: left !important; }
      .En #profile .wrapper .left .myOrders ul .priceNCount .count span:first-child {
        text-align: left !important;
        padding: 0 2px !important;
        box-sizing: border-box !important; }
      .En #profile .wrapper .left .myOrders ul .priceNCount .count span:last-child {
        text-align: right !important;
        padding: 0 2px !important;
        box-sizing: border-box !important; }
      .En #profile .wrapper .left .myOrders ul .priceNCount .price span:first-child {
        float: left !important;
        text-align: right !important;
        padding: 0 2px !important;
        box-sizing: border-box !important; }
      .En #profile .wrapper .left .myOrders ul .priceNCount .price span:last-child {
        float: left !important;
        text-align: left !important;
        padding: 0 2px !important;
        box-sizing: border-box !important; }
      .En #profile .wrapper .left .invitation .label,
      .En #profile .wrapper .left .setting .label {
        float: left !important;
        margin-left: 0 !important;
        margin-right: 10px !important; }
      .En #profile .wrapper .left .invitation .invitationCode,
      .En #profile .wrapper .left .invitation .shareBtns,
      .En #profile .wrapper .left .invitation .selectOptionWrapper,
      .En #profile .wrapper .left .invitation .title,
      .En #profile .wrapper .left .invitation .checkBtnWrapper,
      .En #profile .wrapper .left .invitation .settingOption,
      .En #profile .wrapper .left .setting .invitationCode,
      .En #profile .wrapper .left .setting .shareBtns,
      .En #profile .wrapper .left .setting .selectOptionWrapper,
      .En #profile .wrapper .left .setting .title,
      .En #profile .wrapper .left .setting .checkBtnWrapper,
      .En #profile .wrapper .left .setting .settingOption {
        float: left !important; }
        .En #profile .wrapper .left .invitation .invitationCode select,
        .En #profile .wrapper .left .invitation .shareBtns select,
        .En #profile .wrapper .left .invitation .selectOptionWrapper select,
        .En #profile .wrapper .left .invitation .title select,
        .En #profile .wrapper .left .invitation .checkBtnWrapper select,
        .En #profile .wrapper .left .invitation .settingOption select,
        .En #profile .wrapper .left .setting .invitationCode select,
        .En #profile .wrapper .left .setting .shareBtns select,
        .En #profile .wrapper .left .setting .selectOptionWrapper select,
        .En #profile .wrapper .left .setting .title select,
        .En #profile .wrapper .left .setting .checkBtnWrapper select,
        .En #profile .wrapper .left .setting .settingOption select {
          padding-right: 35px !important;
          padding-left: 20px !important;
          background-position: 160px center !important; }
  #profile {
    width: 100vw;
    height: 100vh; }
    #profile .popBg2 {
      display: none;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      float: left;
      left: 0;
      z-index: 99; }
    #profile .setLocation {
      width: 90%;
      height: 470px;
      float: right;
      position: absolute;
      background-color: #ffcc00;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      right: 50%;
      top: 50%;
      -moz-transform: translate(50%, -50%);
      -o-transform: translate(50%, -50%);
      -ms-transform: translate(50%, -50%);
      -webkit-transform: translate(50%, -50%);
      transform: translate(50%, -50%);
      z-index: 110;
      padding: 10px; }
      #profile .setLocation .title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        float: right;
        text-align: center; }
      #profile .setLocation #map {
        width: 100%;
        height: 300px;
        background-color: #444444;
        float: right;
        margin: 10px 0; }
      #profile .setLocation .locationName {
        height: 40px;
        float: right;
        position: relative;
        margin: 10px 0;
        right: 50%;
        -moz-transform: translate(50%, 0);
        -o-transform: translate(50%, 0);
        -ms-transform: translate(50%, 0);
        -webkit-transform: translate(50%, 0);
        transform: translate(50%, 0); }
        #profile .setLocation .locationName img {
          width: 40px;
          height: 40px;
          float: right;
          padding: 10px;
          box-sizing: border-box; }
        #profile .setLocation .locationName span {
          line-height: 40px; }
      #profile .setLocation button {
        position: relative;
        clear: both;
        float: right;
        right: 50%;
        transform: translateX(50%);
        font-family: "IRANSans"; }
    #profile .container {
      overflow: auto; }
      #profile .container .wrapper {
        width: 100%;
        height: 100%;
        float: right;
        position: relative;
        overflow: visible; }
        #profile .container .wrapper .nav {
          float: right;
          width: 100%;
          position: relative;
          margin: 0; }
          #profile .container .wrapper .nav .profilePic {
            width: 150px;
            height: 150px;
            float: right;
            margin-bottom: 10px;
            position: relative;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border: 2px solid #444444;
            box-sizing: border-box;
            right: 50%;
            -moz-transform: translate(50%, 0);
            -o-transform: translate(50%, 0);
            -ms-transform: translate(50%, 0);
            -webkit-transform: translate(50%, 0);
            transform: translate(50%, 0); }
            #profile .container .wrapper .nav .profilePic > img {
              width: 100%;
              height: 100%;
              float: right;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              overflow: hidden; }
            #profile .container .wrapper .nav .profilePic label {
              width: 40px;
              height: 40px;
              position: absolute;
              background-color: #ffcc00;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              border: 2px solid #444444;
              box-sizing: border-box;
              top: 0;
              float: right;
              right: 0;
              cursor: pointer;
              transition: all 0.3s linear;
              -webkit-transition: all 0.3s linear;
              -moz-transition: all 0.3s linear; }
              #profile .container .wrapper .nav .profilePic label:hover {
                -moz-transform: scale(1.05);
                -o-transform: scale(1.05);
                -ms-transform: scale(1.05);
                -webkit-transform: scale(1.05);
                transform: scale(1.05); }
              #profile .container .wrapper .nav .profilePic label input {
                display: none; }
              #profile .container .wrapper .nav .profilePic label img {
                width: 100%;
                height: 100%;
                padding: 5px;
                box-sizing: border-box;
                filter: invert(0.22); }
          #profile .container .wrapper .nav ul {
            float: right;
            right: 50%;
            height: calc(100% - 160px);
            position: relative;
            -moz-transform: translate(50%, 0);
            -o-transform: translate(50%, 0);
            -ms-transform: translate(50%, 0);
            -webkit-transform: translate(50%, 0);
            transform: translate(50%, 0);
            margin: 0;
            padding: 0;
            list-style: none;
            clear: both; }
            #profile .container .wrapper .nav ul li {
              width: 40px;
              height: 40px;
              float: right;
              display: inline-block;
              margin: 5px 0;
              position: relative;
              transition: all 0.3s linear;
              -webkit-transition: all 0.3s linear;
              -moz-transition: all 0.3s linear;
              cursor: pointer; }
              #profile .container .wrapper .nav ul li.active .title {
                display: block; }
              #profile .container .wrapper .nav ul li.active img {
                filter: brightness(0) invert(1);
                -webkit-filter: brightness(0) invert(1); }
              #profile .container .wrapper .nav ul li:hover .title {
                display: block; }
              #profile .container .wrapper .nav ul li:hover img {
                opacity: 0.8; }
              #profile .container .wrapper .nav ul li img {
                width: 100%;
                height: 100%;
                float: right;
                padding: 7.5px;
                box-sizing: border-box;
                filter: invert(0.22); }
              #profile .container .wrapper .nav ul li .title {
                display: none;
                width: 150px;
                height: 40px;
                line-height: 40px;
                position: absolute;
                float: left;
                left: 50%;
                -moz-transform: translate(-50%, 0);
                -o-transform: translate(-50%, 0);
                -ms-transform: translate(-50%, 0);
                -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
                top: calc(100% + 5px);
                white-space: nowrap;
                font-weight: bold;
                font-size: 0.8em;
                text-align: center; }
            #profile .container .wrapper .nav ul span.phone {
              width: 40px;
              height: 40px;
              float: right;
              position: absolute;
              top: 5px;
              right: 0;
              border: 2px solid #444444;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              box-sizing: border-box;
              background-color: #444444;
              z-index: -1; }
            #profile .container .wrapper .nav ul span.desktop {
              display: none; }
        #profile .container .wrapper .left {
          float: left;
          position: relative;
          width: 100%;
          margin: 0; }
          #profile .container .wrapper .left .nameNCredit {
            width: 100%;
            height: 150px;
            float: right;
            text-align: center; }
            #profile .container .wrapper .left .nameNCredit .name {
              width: 100%;
              height: 100px;
              float: right;
              line-height: 100px;
              font-weight: bold;
              font-size: 18px; }
            #profile .container .wrapper .left .nameNCredit .credit {
              height: 40px;
              line-height: 40px;
              float: right;
              margin-bottom: 10px;
              position: relative;
              right: 50%;
              -moz-transform: translate(50%, 0);
              -o-transform: translate(50%, 0);
              -ms-transform: translate(50%, 0);
              -webkit-transform: translate(50%, 0);
              transform: translate(50%, 0); }
              #profile .container .wrapper .left .nameNCredit .credit .creditValue {
                max-width: 200px;
                height: 40px;
                line-height: 40px;
                margin-bottom: 10px;
                float: right; }
  @keyframes burst {
    100% {
      -moz-transform: scale(2);
      -o-transform: scale(2);
      -ms-transform: scale(2);
      -webkit-transform: scale(2);
      transform: scale(2);
      opacity: 0; } }
              #profile .container .wrapper .left .nameNCredit .credit .addCreditBtn {
                width: 40px;
                height: 40px;
                float: right;
                border: 2px solid #444444;
                box-sizing: border-box;
                text-align: center;
                line-height: 40px;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                background-color: #ffcc00;
                margin: 0 10px;
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
                -moz-transition: all 0.3s linear;
                cursor: pointer; }
                #profile .container .wrapper .left .nameNCredit .credit .addCreditBtn:hover {
                  -moz-transform: rotate(-180deg);
                  -o-transform: rotate(-180deg);
                  -ms-transform: rotate(-180deg);
                  -webkit-transform: rotate(-180deg);
                  transform: rotate(-180deg); }
                #profile .container .wrapper .left .nameNCredit .credit .addCreditBtn.clicked {
                  animation: burst 0.6s forwards; }
              #profile .container .wrapper .left .nameNCredit .credit .addCredit {
                display: none;
                height: 40px;
                float: right; }
                #profile .container .wrapper .left .nameNCredit .credit .addCredit .inputWrapper {
                  margin: 0 5px; }
                  #profile .container .wrapper .left .nameNCredit .credit .addCredit .inputWrapper input {
                    padding-left: 50px; }
                  #profile .container .wrapper .left .nameNCredit .credit .addCredit .inputWrapper .toman {
                    position: absolute;
                    float: left;
                    left: 15px;
                    color: white; }
                #profile .container .wrapper .left .nameNCredit .credit .addCredit button {
                  font-family: "IRANSans";
                  float: right; }
          #profile .container .wrapper .left .info {
            width: 610px;
            float: right;
            height: calc(100% - 150px);
            overflow: auto;
            position: relative;
            right: 50%;
            -moz-transform: translate(50%, 0);
            -o-transform: translate(50%, 0);
            -ms-transform: translate(50%, 0);
            -webkit-transform: translate(50%, 0);
            transform: translate(50%, 0); }
            #profile .container .wrapper .left .info .field {
              width: 300px;
              float: right;
              margin: 10px 0;
              margin-left: 10px; }
              #profile .container .wrapper .left .info .field:nth-child(2), #profile .container .wrapper .left .info .field:nth-child(4) {
                margin-left: 0; }
              #profile .container .wrapper .left .info .field .label {
                width: 300px;
                height: 40px;
                float: right;
                line-height: 40px;
                text-align: right; }
              #profile .container .wrapper .left .info .field .selectOptionWrapper {
                width: 75px;
                float: right;
                position: relative;
                text-align: center; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper.day {
                  width: 70px; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper.year {
                  width: 90px; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper.month {
                  width: 120px; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper .title {
                  height: 40px;
                  line-height: 40px;
                  width: 100%;
                  float: left;
                  text-align: center; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper select {
                  -moz-appearance: none;
                  -webkit-appearance: none;
                  width: 100%;
                  height: 40px;
                  background-color: rgba(0, 0, 0, 0.5);
                  color: white;
                  font-size: 14px;
                  outline: none;
                  border: none;
                  border-radius: 20px;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  box-sizing: border-box;
                  padding: 0 20px;
                  padding-left: 35px;
                  font-family: "IRANSans";
                  line-height: 36px;
                  text-align: center;
                  background-image: url("../../media/down-arrow - white.png");
                  background-size: 20px 20px;
                  background-repeat: no-repeat;
                  background-position: 10px center;
                  cursor: pointer; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper img {
                  display: none;
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  float: left;
                  left: 10px;
                  bottom: 10px; }
              #profile .container .wrapper .left .info .field span {
                float: right;
                width: 10px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-weight: bold; }
            #profile .container .wrapper .left .info button {
              float: right;
              position: relative;
              right: 50%;
              -moz-transform: translate(50%, 0);
              -o-transform: translate(50%, 0);
              -ms-transform: translate(50%, 0);
              -webkit-transform: translate(50%, 0);
              transform: translate(50%, 0);
              margin: 40px 0;
              font-family: "IRANSans"; }
          #profile .container .wrapper .left .editAddresses {
            width: 100%;
            float: right;
            height: calc(100% - 150px);
            overflow: auto; }
            #profile .container .wrapper .left .editAddresses .addresses {
              width: 100%;
              float: right;
              position: relative;
              margin: 10px 0;
              padding: 0;
              list-style: none; }
              #profile .container .wrapper .left .editAddresses .addresses .emptyText {
                width: 100%;
                float: left; }
                #profile .container .wrapper .left .editAddresses .addresses .emptyText img {
                  width: 100px;
                  height: auto;
                  float: left;
                  left: 50%;
                  position: relative;
                  -moz-transform: translate(-50%, 0);
                  -o-transform: translate(-50%, 0);
                  -ms-transform: translate(-50%, 0);
                  -webkit-transform: translate(-50%, 0);
                  transform: translate(-50%, 0); }
                #profile .container .wrapper .left .editAddresses .addresses .emptyText p {
                  text-align: center;
                  margin: 20px 0;
                  width: 100%;
                  text-align: center;
                  float: left; }
              #profile .container .wrapper .left .editAddresses .addresses li {
                width: 100%;
                height: 60px;
                float: right;
                background-color: #ffcc00;
                color: #444444;
                border: 2px solid #444444;
                border-top: none;
                overflow: hidden;
                box-sizing: border-box; }
                #profile .container .wrapper .left .editAddresses .addresses li.active {
                  background-color: #444444;
                  color: #ffcc00; }
                  #profile .container .wrapper .left .editAddresses .addresses li.active .editBtn,
                  #profile .container .wrapper .left .editAddresses .addresses li.active .deleteBtn {
                    background-color: #ffcc00; }
                    #profile .container .wrapper .left .editAddresses .addresses li.active .editBtn img.off,
                    #profile .container .wrapper .left .editAddresses .addresses li.active .deleteBtn img.off {
                      display: none; }
                    #profile .container .wrapper .left .editAddresses .addresses li.active .editBtn img.on,
                    #profile .container .wrapper .left .editAddresses .addresses li.active .deleteBtn img.on {
                      display: block; }
                #profile .container .wrapper .left .editAddresses .addresses li:first-child {
                  border-top-left-radius: 20px;
                  border-top-right-radius: 20px;
                  border-top: 2px solid #444444; }
                #profile .container .wrapper .left .editAddresses .addresses li:last-child {
                  border-bottom-left-radius: 20px;
                  border-bottom-right-radius: 20px; }
                #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper {
                  width: 20px;
                  height: 20px;
                  float: right;
                  background-color: #ffcc00;
                  border: 2px solid #444444;
                  box-sizing: border-box;
                  position: relative;
                  margin: 20px;
                  border-radius: 50%;
                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%; }
                  #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper input {
                    position: relative;
                    width: 0;
                    height: 0;
                    visibility: hidden;
                    top: 0;
                    right: 0; }
                    #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper input:checked ~ label img {
                      display: block; }
                  #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper label {
                    width: 100%;
                    height: 100%;
                    float: right;
                    position: absolute;
                    top: 0;
                    right: 0;
                    cursor: pointer; }
                    #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper label img {
                      display: none;
                      width: 16px;
                      height: 16px;
                      padding: 2px;
                      box-sizing: border-box;
                      float: right;
                      top: 0;
                      right: 0; }
                #profile .container .wrapper .left .editAddresses .addresses li .addressText {
                  width: calc(100% - 120px);
                  padding-right: 20px;
                  line-height: 60px;
                  float: right;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  cursor: pointer;
                  box-sizing: border-box; }
                #profile .container .wrapper .left .editAddresses .addresses li .editBtn,
                #profile .container .wrapper .left .editAddresses .addresses li .deleteBtn {
                  width: 22px;
                  height: 22px;
                  float: right;
                  background-color: #444444;
                  box-sizing: border-box;
                  position: relative;
                  margin: 17px;
                  border-radius: 50%;
                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%;
                  transition: all 0.3s linear;
                  -webkit-transition: all 0.3s linear;
                  -moz-transition: all 0.3s linear; }
                  #profile .container .wrapper .left .editAddresses .addresses li .editBtn img.on,
                  #profile .container .wrapper .left .editAddresses .addresses li .deleteBtn img.on {
                    display: none; }
                  #profile .container .wrapper .left .editAddresses .addresses li .editBtn img,
                  #profile .container .wrapper .left .editAddresses .addresses li .deleteBtn img {
                    width: 22px;
                    height: 22px;
                    padding: 3px;
                    box-sizing: border-box;
                    float: right;
                    top: 0;
                    right: 0;
                    cursor: pointer;
                    filter: invert(1); }
                  #profile .container .wrapper .left .editAddresses .addresses li .editBtn:hover,
                  #profile .container .wrapper .left .editAddresses .addresses li .deleteBtn:hover {
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg); }
            #profile .container .wrapper .left .editAddresses .addAddress {
              width: 100%;
              float: right;
              position: relative;
              margin: 10px 0;
              padding: 0;
              list-style: none;
              margin-top: 20px; }
              #profile .container .wrapper .left .editAddresses .addAddress .label {
                width: auto !important;
                float: right;
                height: 40px;
                position: relative;
                text-align: right;
                line-height: 40px; }
              #profile .container .wrapper .left .editAddresses .addAddress .mapBtn {
                width: auto !important;
                height: auto !important;
                float: right;
                margin: 0 10px; }
                #profile .container .wrapper .left .editAddresses .addAddress .mapBtn img {
                  width: 20px;
                  height: 20px;
                  float: left; }
              #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper {
                margin: 10px 0; }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.alleyField {
                  clear: both; }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.alleyField, #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.noField {
                  width: calc((100% / 3) - (20px / 3)); }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.no2Field, #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.no3Field {
                  width: calc((100% / 6) - (20px / 4)); }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.no2Field {
                  margin-left: calc(20px / 3); }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.noField {
                  margin: 10px calc(20px / 3); }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.nameFiled {
                  width: calc(50% - 5px);
                  margin-left: 10px; }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.phoneField {
                  width: calc(50% - 5px); }
            #profile .container .wrapper .left .editAddresses button {
              position: relative;
              font-family: "IRANSans";
              float: right;
              right: 50%;
              -moz-transform: translate(50%, 0);
              -o-transform: translate(50%, 0);
              -ms-transform: translate(50%, 0);
              -webkit-transform: translate(50%, 0);
              transform: translate(50%, 0); }
          #profile .container .wrapper .left .myOrders {
            width: 100%;
            float: right;
            height: calc(100% - 150px); }
            #profile .container .wrapper .left .myOrders ul {
              width: calc(100% - 20px);
              float: right;
              margin: 0 10px;
              padding: 0;
              list-style: none; }
              #profile .container .wrapper .left .myOrders ul .emptyText {
                width: 100%;
                float: left; }
                #profile .container .wrapper .left .myOrders ul .emptyText img {
                  width: 100px;
                  height: auto;
                  float: left;
                  left: 50%;
                  position: relative;
                  -moz-transform: translate(-50%, 0);
                  -o-transform: translate(-50%, 0);
                  -ms-transform: translate(-50%, 0);
                  -webkit-transform: translate(-50%, 0);
                  transform: translate(-50%, 0); }
                #profile .container .wrapper .left .myOrders ul .emptyText p {
                  text-align: center;
                  margin: 20px 0;
                  width: 100%;
                  text-align: center;
                  float: left; }
              #profile .container .wrapper .left .myOrders ul li {
                width: 100%;
                float: right;
                box-sizing: border-box; }
                #profile .container .wrapper .left .myOrders ul li:first-child .title {
                  border-top-left-radius: 20px;
                  border-top-right-radius: 20px;
                  border-top: 2px solid #444444; }
                #profile .container .wrapper .left .myOrders ul li:last-child .title {
                  border-bottom-left-radius: 20px;
                  border-bottom-right-radius: 20px;
                  border-bottom: 2px solid #444444; }
                #profile .container .wrapper .left .myOrders ul li .title {
                  width: 100%;
                  height: 60px;
                  float: right;
                  border: 2px solid #444444;
                  border-top: 1px solid #444444;
                  border-bottom: 1px solid #444444;
                  background-color: #ffcc00;
                  line-height: 60px;
                  box-sizing: border-box;
                  padding-right: 20px;
                  cursor: pointer; }
                  #profile .container .wrapper .left .myOrders ul li .title span {
                    float: right;
                    line-height: 60px;
                    margin: 0 5px; }
                    #profile .container .wrapper .left .myOrders ul li .title span:first-child {
                      font-weight: bold; }
                    #profile .container .wrapper .left .myOrders ul li .title span:last-child {
                      font-size: 0.8em; }
                  #profile .container .wrapper .left .myOrders ul li .title .downBtn {
                    width: 20px;
                    height: 20px;
                    margin: 20px;
                    float: left;
                    cursor: pointer;
                    transition: all 0.3s linear;
                    -webkit-transition: all 0.3s linear;
                    -moz-transition: all 0.3s linear; }
                    #profile .container .wrapper .left .myOrders ul li .title .downBtn img {
                      width: 100%;
                      height: 100%;
                      float: left; }
                    #profile .container .wrapper .left .myOrders ul li .title .downBtn:hover {
                      -moz-transform: translate(0, 5px);
                      -o-transform: translate(0, 5px);
                      -ms-transform: translate(0, 5px);
                      -webkit-transform: translate(0, 5px);
                      transform: translate(0, 5px); }
                #profile .container .wrapper .left .myOrders ul li .details {
                  display: none;
                  width: 600px;
                  float: right;
                  margin: 0 calc(50% - 300px);
                  background-color: rgba(0, 0, 0, 0.5); }
                  #profile .container .wrapper .left .myOrders ul li .details.show {
                    display: block !important;
                    opacity: 1;
                    animation: detailsFadeIn 350ms; }
  @keyframes detailsFadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
                  #profile .container .wrapper .left .myOrders ul li .details .shippingLevel {
                    width: 560px;
                    height: 50px;
                    float: right;
                    right: 50%;
                    position: relative;
                    margin-right: -280px;
                    margin-top: 20px;
                    clear: both;
                    z-index: 5;
                    color: #ffcc00; }
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1,
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2,
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3,
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4,
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5 {
                      width: 50px;
                      height: 50px;
                      float: right;
                      background-color: transparent;
                      border: 2px solid #ffcc00;
                      border-radius: 50%;
                      -webkit-border-radius: 50%;
                      -moz-border-radius: 50%;
                      box-sizing: border-box;
                      position: relative; }
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1.done,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2.done,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3.done,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4.done,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5.done {
                        background-color: #ffcc00; }
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1.done img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2.done img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3.done img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4.done img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5.done img.on {
                          display: none; }
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1.done img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2.done img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3.done img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4.done img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5.done img.off {
                          display: block; }
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1.active:before,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2.active:before,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3.active:before,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4.active:before,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5.active:before {
                        content: '';
                        position: absolute;
                        width: 55px;
                        height: 55px;
                        float: right;
                        border: 2px dashed #ffcc00;
                        top: -6px;
                        right: -6px;
                        border-radius: 50%;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%; }
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1 img,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2 img,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3 img,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4 img,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5 img {
                        width: 30px;
                        height: 30px;
                        margin: 8px;
                        position: absolute;
                        top: 0;
                        float: right;
                        right: 0; }
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1 img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2 img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3 img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4 img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5 img.off {
                          display: none; }
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1 .levelName,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2 .levelName,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3 .levelName,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4 .levelName,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5 .levelName {
                        width: 150px;
                        height: 20px;
                        line-height: 20px;
                        position: absolute;
                        bottom: -30px;
                        float: right;
                        right: 50%;
                        margin-right: -75px;
                        text-align: center;
                        font-size: 12.6px; }
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel span {
                      width: calc(310px / 4);
                      height: 2px;
                      background-color: #ffcc00;
                      float: right;
                      margin: 23px 0; }
                  #profile .container .wrapper .left .myOrders ul li .details ul {
                    width: calc(100% - 20px);
                    float: right;
                    padding: 0 20px;
                    margin: 30px 0;
                    list-style: none;
                    box-sizing: border-box; }
                    #profile .container .wrapper .left .myOrders ul li .details ul li {
                      width: 100%;
                      height: 70px;
                      float: right;
                      padding: 5px;
                      box-sizing: border-box;
                      position: relative;
                      margin: 5px 0; }
                      #profile .container .wrapper .left .myOrders ul li .details ul li .deleteBtn {
                        display: none;
                        width: 20px;
                        height: 20px;
                        text-align: center;
                        line-height: 20px;
                        float: right;
                        color: white;
                        position: absolute;
                        top: 0;
                        right: 0;
                        cursor: pointer; }
                      #profile .container .wrapper .left .myOrders ul li .details ul li img {
                        width: 60px;
                        height: 60px;
                        float: right; }
                      #profile .container .wrapper .left .myOrders ul li .details ul li .matName {
                        width: calc(100% - 180px);
                        height: 60px;
                        float: right;
                        margin: 0 10px;
                        line-height: 60px;
                        color: white; }
                      #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount {
                        width: 100px;
                        height: 60px;
                        float: left;
                        color: white; }
                        #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount .price,
                        #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount .count {
                          width: 100%;
                          height: 50%;
                          float: left; }
                          #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount .price span,
                          #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount .count span {
                            width: 50%;
                            height: 100%;
                            float: right;
                            text-align: center;
                            line-height: 37.5px; }
                  #profile .container .wrapper .left .myOrders ul li .details .btns {
                    height: 40px;
                    float: right;
                    position: relative;
                    right: 50%;
                    margin: 10px 0;
                    -moz-transform: translate(50%, 0);
                    -o-transform: translate(50%, 0);
                    -ms-transform: translate(50%, 0);
                    -webkit-transform: translate(50%, 0);
                    transform: translate(50%, 0); }
                    #profile .container .wrapper .left .myOrders ul li .details .btns a {
                      float: right;
                      margin: 0 5px;
                      line-height: 30px; }
          #profile .container .wrapper .left .myPizzas {
            width: 100%;
            float: right;
            height: calc(100% - 150px);
            overflow: auto; }
            #profile .container .wrapper .left .myPizzas .pizzas {
              width: 100%;
              float: right;
              margin: 0;
              padding: 0;
              list-style: none; }
              #profile .container .wrapper .left .myPizzas .pizzas .emptyText {
                width: 100%;
                float: left; }
                #profile .container .wrapper .left .myPizzas .pizzas .emptyText img {
                  width: 100px;
                  height: auto;
                  float: left;
                  left: 50%;
                  position: relative;
                  -moz-transform: translate(-50%, 0);
                  -o-transform: translate(-50%, 0);
                  -ms-transform: translate(-50%, 0);
                  -webkit-transform: translate(-50%, 0);
                  transform: translate(-50%, 0); }
                #profile .container .wrapper .left .myPizzas .pizzas .emptyText p {
                  text-align: center;
                  margin: 20px 0;
                  width: 100%;
                  text-align: center;
                  float: left; }
              #profile .container .wrapper .left .myPizzas .pizzas li {
                width: 300px;
                height: 225px;
                margin: 10px calc(((100% / 2) - 300px) / 2);
                float: right;
                display: inline-block;
                background-color: rgba(0, 0, 0, 0.5);
                position: relative;
                margin-top: 75px;
                padding: 10px;
                padding-top: 85px;
                box-sizing: border-box;
                border-radius: 20px;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                color: white; }
                #profile .container .wrapper .left .myPizzas .pizzas li img {
                  width: 150px;
                  height: 150px;
                  position: absolute;
                  top: -75px;
                  right: 50%;
                  -moz-transform: translate(50%, 0);
                  -o-transform: translate(50%, 0);
                  -ms-transform: translate(50%, 0);
                  -webkit-transform: translate(50%, 0);
                  transform: translate(50%, 0); }
                #profile .container .wrapper .left .myPizzas .pizzas li .pizzaName {
                  width: 100%;
                  line-height: 40px;
                  font-weight: bold;
                  float: right;
                  text-align: center; }
                #profile .container .wrapper .left .myPizzas .pizzas li .ingredients {
                  width: 100%;
                  height: 50px;
                  float: right;
                  font-size: 0.7em;
                  text-align: center;
                  overflow: hidden; }
                #profile .container .wrapper .left .myPizzas .pizzas li button {
                  font-family: "IRANSans";
                  float: right;
                  right: 50%;
                  position: relative;
                  -moz-transform: translate(50%, 0);
                  -o-transform: translate(50%, 0);
                  -ms-transform: translate(50%, 0);
                  -webkit-transform: translate(50%, 0);
                  transform: translate(50%, 0); }
          #profile .container .wrapper .left .invitation {
            width: 100%;
            float: right;
            margin-top: 20px; }
            #profile .container .wrapper .left .invitation .label {
              float: right;
              height: 40px;
              line-height: 40px;
              margin-left: 10px;
              font-weight: bold; }
            #profile .container .wrapper .left .invitation .invitationCode {
              width: 200px;
              height: 40px;
              float: right;
              border: 2px solid #444444;
              box-sizing: border-box;
              border-radius: 20px;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              background-color: #ffcc00;
              text-align: center;
              line-height: 36px;
              padding: 0 20px; }
            #profile .container .wrapper .left .invitation .shareBtns {
              float: right;
              height: 40px;
              margin: 0 10px; }
              #profile .container .wrapper .left .invitation .shareBtns .copyBtn,
              #profile .container .wrapper .left .invitation .shareBtns .shareBtn {
                width: 40px;
                height: 40px;
                float: right;
                margin: 0 5px;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                background-color: #444444;
                cursor: pointer;
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
                -moz-transition: all 0.3s linear; }
                #profile .container .wrapper .left .invitation .shareBtns .copyBtn:hover,
                #profile .container .wrapper .left .invitation .shareBtns .shareBtn:hover {
                  opacity: 0.8; }
                #profile .container .wrapper .left .invitation .shareBtns .copyBtn img,
                #profile .container .wrapper .left .invitation .shareBtns .shareBtn img {
                  width: 100%;
                  height: 100%;
                  padding: 10px;
                  box-sizing: border-box; }
              #profile .container .wrapper .left .invitation .shareBtns .copyBtn img {
                filter: invert(1); }
            #profile .container .wrapper .left .invitation .desc {
              width: 100%;
              float: right;
              margin-top: 10px; }
          #profile .container .wrapper .left .setting {
            float: right;
            margin-top: 20px;
            position: relative;
            right: 50%;
            -moz-transform: translate(50%, 0);
            -o-transform: translate(50%, 0);
            -ms-transform: translate(50%, 0);
            -webkit-transform: translate(50%, 0);
            transform: translate(50%, 0); }
            #profile .container .wrapper .left .setting .setLang {
              float: right;
              height: 40px;
              margin: 10px 0; }
              #profile .container .wrapper .left .setting .setLang .label {
                float: right;
                margin-left: 10px;
                line-height: 40px; }
              #profile .container .wrapper .left .setting .setLang .selectOptionWrapper {
                width: 200px;
                float: left;
                position: relative;
                text-align: center; }
                #profile .container .wrapper .left .setting .setLang .selectOptionWrapper select {
                  -moz-appearance: none;
                  -webkit-appearance: none;
                  width: 100%;
                  height: 40px;
                  background-color: rgba(0, 0, 0, 0.5);
                  color: white;
                  font-size: 14px;
                  outline: none;
                  border: none;
                  border-radius: 20px;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  box-sizing: border-box;
                  padding: 0 20px;
                  padding-left: 35px;
                  font-family: "IRANSans";
                  line-height: 36px;
                  text-align: center;
                  background-image: url("../../media/down-arrow - white.png");
                  background-size: 20px 20px;
                  background-repeat: no-repeat;
                  background-position: 10px center;
                  cursor: pointer; }
                #profile .container .wrapper .left .setting .setLang .selectOptionWrapper img {
                  display: none;
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  float: left;
                  left: 10px;
                  bottom: 10px; }
            #profile .container .wrapper .left .setting .settingOption {
              width: 100%;
              float: right;
              margin: 10px 0;
              height: 40px;
              clear: both; }
              #profile .container .wrapper .left .setting .settingOption .label {
                float: right;
                width: 200px;
                line-height: 40px; }
              #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper {
                width: 50px;
                height: 30px;
                float: left;
                margin: 5px 0; }
                #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper input {
                  width: 0;
                  height: 0;
                  padding: 0;
                  margin: 0;
                  visibility: hidden; }
                  #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper input:checked ~ label {
                    opacity: 1; }
                    #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper input:checked ~ label .circle {
                      right: 2px; }
                #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper label {
                  width: 100%;
                  height: 100%;
                  float: right;
                  position: relative;
                  background-color: #ffcc00;
                  border: 2px solid #444444;
                  border-radius: 15px;
                  -webkit-border-radius: 15px;
                  -moz-border-radius: 15px;
                  box-sizing: border-box;
                  opacity: 0.5;
                  cursor: pointer;
                  transition: all 0.3s linear;
                  -webkit-transition: all 0.3s linear;
                  -moz-transition: all 0.3s linear; }
                  #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper label .circle {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    float: right;
                    top: 50%;
                    margin-top: -10px;
                    background-color: #444444;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    right: calc(100% - 22px); }
            #profile .container .wrapper .left .setting button {
              float: right;
              clear: both;
              right: 50%;
              transform: translateX(50%);
              position: relative; } }

@media screen and (max-width: 767px) {
  ::-webkit-scrollbar {
    width: 0px; }
  ::-webkit-scrollbar-thumb {
    background-color: #444444;
    border-radius: 2.5px;
    -webkit-border-radius: 2.5px;
    -moz-border-radius: 2.5px; }
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 2.5px;
    -webkit-border-radius: 2.5px;
    -moz-border-radius: 2.5px; }
  .En #profile {
    direction: ltr; }
    .En #profile .locationName img {
      float: left !important; }
    .En #profile .container {
      overflow: visible !important;
      overflow-x: hidden !important; }
    .En #profile .wrapper .nav {
      float: left !important;
      left: 0 !important; }
      .En #profile .wrapper .nav .profilePic .fileBtn {
        float: left !important;
        left: 0 !important;
        right: auto; }
      .En #profile .wrapper .nav ul li {
        float: left !important; }
      .En #profile .wrapper .nav ul span.phone {
        left: 0;
        right: auto !important; }
    .En #profile .wrapper .left {
      float: right !important; }
      .En #profile .wrapper .left .addCredit {
        width: 100% !important; }
      .En #profile .wrapper .left .creditValue,
      .En #profile .wrapper .left .addCreditBtn,
      .En #profile .wrapper .left .addCredit {
        float: left !important; }
        .En #profile .wrapper .left .creditValue span,
        .En #profile .wrapper .left .addCreditBtn span,
        .En #profile .wrapper .left .addCredit span {
          float: left !important; }
        .En #profile .wrapper .left .creditValue .inputWrapper,
        .En #profile .wrapper .left .addCreditBtn .inputWrapper,
        .En #profile .wrapper .left .addCredit .inputWrapper {
          margin-left: 0 !important;
          margin-right: 10px; }
        .En #profile .wrapper .left .creditValue button,
        .En #profile .wrapper .left .addCreditBtn button,
        .En #profile .wrapper .left .addCredit button {
          width: 100px !important; }
        .En #profile .wrapper .left .creditValue .toman,
        .En #profile .wrapper .left .addCreditBtn .toman,
        .En #profile .wrapper .left .addCredit .toman {
          float: right !important;
          right: 15px !important;
          left: auto !important; }
      .En #profile .wrapper .left .info {
        position: relative;
        margin-bottom: 20px; }
        .En #profile .wrapper .left .info .field {
          float: left !important;
          margin-left: 0px !important;
          left: 50%;
          position: relative;
          -moz-transform: translate(-50%, 0);
          -o-transform: translate(-50%, 0);
          -ms-transform: translate(-50%, 0);
          -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }
          .En #profile .wrapper .left .info .field .label {
            float: left !important;
            text-align: left !important; }
      .En #profile .wrapper .left .editAddresses {
        margin-bottom: 20px; }
        .En #profile .wrapper .left .editAddresses .selectAddress .label {
          text-align: left !important; }
        .En #profile .wrapper .left .editAddresses .addresses li .radioWrapper {
          float: left !important; }
        .En #profile .wrapper .left .editAddresses .addresses li .addressText {
          float: left !important;
          padding-left: 20px !important; }
        .En #profile .wrapper .left .editAddresses .addresses li .editBtn,
        .En #profile .wrapper .left .editAddresses .addresses li .deleteBtn {
          float: right !important; }
        .En #profile .wrapper .left .editAddresses .addAddress .label {
          float: left !important; }
        .En #profile .wrapper .left .editAddresses .addAddress .mapBtn {
          float: left !important;
          width: 300px !important; }
        .En #profile .wrapper .left .editAddresses .addAddress .inputWrapper.no3Field {
          float: right !important;
          right: 0 !important;
          left: auto;
          position: relative;
          -moz-transform: translate(0, 0);
          -o-transform: translate(0, 0);
          -ms-transform: translate(0, 0);
          -webkit-transform: translate(0, 0);
          transform: translate(0, 0); }
        .En #profile .wrapper .left .editAddresses .addAddress .inputWrapper.no2Field {
          float: left;
          left: 0;
          right: auto;
          position: relative;
          -moz-transform: translate(0, 0);
          -o-transform: translate(0, 0);
          -ms-transform: translate(0, 0);
          -webkit-transform: translate(0, 0);
          transform: translate(0, 0); }
      .En #profile .wrapper .left .myOrders .shippingLevel > div {
        float: left !important; }
      .En #profile .wrapper .left .myOrders .shippingLevel > span {
        float: left !important; }
      .En #profile .wrapper .left .myOrders ul li .title {
        padding-left: 20px !important;
        padding-right: 0 !important; }
        .En #profile .wrapper .left .myOrders ul li .title span {
          float: left !important; }
        .En #profile .wrapper .left .myOrders ul li .title .downBtn {
          float: right !important; }
      .En #profile .wrapper .left .myOrders ul li .details .btns a {
        width: 100% !important; }
      .En #profile .wrapper .left .myOrders ul img {
        float: left !important; }
      .En #profile .wrapper .left .myOrders ul .matName {
        float: left !important; }
      .En #profile .wrapper .left .myOrders ul .priceNCount .count span:first-child {
        text-align: left !important;
        padding: 0 2px !important;
        box-sizing: border-box !important; }
      .En #profile .wrapper .left .myOrders ul .priceNCount .count span:last-child {
        text-align: right !important;
        padding: 0 2px !important;
        box-sizing: border-box !important; }
      .En #profile .wrapper .left .myOrders ul .priceNCount .price span:first-child {
        float: left !important;
        text-align: right !important;
        padding: 0 2px !important;
        box-sizing: border-box !important; }
      .En #profile .wrapper .left .myOrders ul .priceNCount .price span:last-child {
        float: left !important;
        text-align: left !important;
        padding: 0 2px !important;
        box-sizing: border-box !important; }
      .En #profile .wrapper .left .invitation,
      .En #profile .wrapper .left .setting {
        margin-bottom: 20px; }
        .En #profile .wrapper .left .invitation .label,
        .En #profile .wrapper .left .setting .label {
          float: left !important;
          margin-left: 0 !important;
          margin-right: 10px !important; }
        .En #profile .wrapper .left .invitation .selectOptionWrapper,
        .En #profile .wrapper .left .invitation .title,
        .En #profile .wrapper .left .invitation .checkBtnWrapper,
        .En #profile .wrapper .left .invitation .settingOption,
        .En #profile .wrapper .left .setting .selectOptionWrapper,
        .En #profile .wrapper .left .setting .title,
        .En #profile .wrapper .left .setting .checkBtnWrapper,
        .En #profile .wrapper .left .setting .settingOption {
          float: right !important; }
          .En #profile .wrapper .left .invitation .selectOptionWrapper select,
          .En #profile .wrapper .left .invitation .title select,
          .En #profile .wrapper .left .invitation .checkBtnWrapper select,
          .En #profile .wrapper .left .invitation .settingOption select,
          .En #profile .wrapper .left .setting .selectOptionWrapper select,
          .En #profile .wrapper .left .setting .title select,
          .En #profile .wrapper .left .setting .checkBtnWrapper select,
          .En #profile .wrapper .left .setting .settingOption select {
            padding-right: 35px !important;
            padding-left: 20px !important;
            background-position: 160px center !important; }
  #profile {
    width: 100vw;
    height: 100vh; }
    #profile .popBg2 {
      display: none;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      float: left;
      left: 0;
      z-index: 99; }
    #profile .setLocation {
      width: 90%;
      height: 470px;
      float: right;
      position: absolute;
      background-color: #ffcc00;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      right: 50%;
      top: 50%;
      -moz-transform: translate(50%, -50%);
      -o-transform: translate(50%, -50%);
      -ms-transform: translate(50%, -50%);
      -webkit-transform: translate(50%, -50%);
      transform: translate(50%, -50%);
      z-index: 110;
      padding: 10px; }
      #profile .setLocation .title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        float: right;
        text-align: center; }
      #profile .setLocation #map {
        width: 100%;
        height: 300px;
        background-color: #444444;
        float: right;
        margin: 10px 0; }
      #profile .setLocation .locationName {
        height: 40px;
        float: right;
        position: relative;
        margin: 10px 0;
        right: 50%;
        -moz-transform: translate(50%, 0);
        -o-transform: translate(50%, 0);
        -ms-transform: translate(50%, 0);
        -webkit-transform: translate(50%, 0);
        transform: translate(50%, 0); }
        #profile .setLocation .locationName img {
          width: 40px;
          height: 40px;
          float: right;
          padding: 10px;
          box-sizing: border-box; }
        #profile .setLocation .locationName span {
          line-height: 40px; }
      #profile .setLocation button {
        position: relative;
        clear: both;
        float: right;
        right: 50%;
        transform: translateX(50%);
        font-family: "IRANSans"; }
    #profile .container {
      overflow: visible; }
      #profile .container .wrapper {
        width: 100%;
        height: 100%;
        float: right;
        position: relative;
        overflow: visible; }
        #profile .container .wrapper .nav {
          float: right;
          width: 100%;
          position: relative;
          margin: 0; }
          #profile .container .wrapper .nav .profilePic {
            width: 150px;
            height: 150px;
            float: right;
            margin-bottom: 10px;
            position: relative;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border: 2px solid #444444;
            box-sizing: border-box;
            right: 50%;
            -moz-transform: translate(50%, 0);
            -o-transform: translate(50%, 0);
            -ms-transform: translate(50%, 0);
            -webkit-transform: translate(50%, 0);
            transform: translate(50%, 0); }
            #profile .container .wrapper .nav .profilePic > img {
              width: 100%;
              height: 100%;
              float: right;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              overflow: hidden; }
            #profile .container .wrapper .nav .profilePic label {
              width: 40px;
              height: 40px;
              position: absolute;
              background-color: #ffcc00;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              border: 2px solid #444444;
              box-sizing: border-box;
              top: 0;
              float: right;
              right: 0;
              cursor: pointer;
              transition: all 0.3s linear;
              -webkit-transition: all 0.3s linear;
              -moz-transition: all 0.3s linear; }
              #profile .container .wrapper .nav .profilePic label:hover {
                -moz-transform: scale(1.05);
                -o-transform: scale(1.05);
                -ms-transform: scale(1.05);
                -webkit-transform: scale(1.05);
                transform: scale(1.05); }
              #profile .container .wrapper .nav .profilePic label input {
                display: none; }
              #profile .container .wrapper .nav .profilePic label img {
                width: 100%;
                height: 100%;
                padding: 5px;
                box-sizing: border-box;
                filter: invert(0.22); }
          #profile .container .wrapper .nav ul {
            float: right;
            right: 50%;
            height: calc(100% - 160px);
            position: relative;
            -moz-transform: translate(50%, 0);
            -o-transform: translate(50%, 0);
            -ms-transform: translate(50%, 0);
            -webkit-transform: translate(50%, 0);
            transform: translate(50%, 0);
            margin: 0;
            padding: 0;
            list-style: none;
            clear: both; }
            #profile .container .wrapper .nav ul li {
              width: 40px;
              height: 40px;
              float: right;
              display: inline-block;
              margin: 5px 0;
              position: relative;
              transition: all 0.3s linear;
              -webkit-transition: all 0.3s linear;
              -moz-transition: all 0.3s linear;
              cursor: pointer; }
              #profile .container .wrapper .nav ul li.active .title {
                display: block; }
              #profile .container .wrapper .nav ul li.active img {
                filter: brightness(0) invert(1);
                -webkit-filter: brightness(0) invert(1); }
              #profile .container .wrapper .nav ul li:hover .title {
                display: block; }
              #profile .container .wrapper .nav ul li:hover img {
                opacity: 0.8; }
              #profile .container .wrapper .nav ul li img {
                width: 100%;
                height: 100%;
                float: right;
                padding: 7.5px;
                box-sizing: border-box;
                filter: invert(0.22); }
              #profile .container .wrapper .nav ul li .title {
                display: none;
                width: 150px;
                height: 40px;
                line-height: 40px;
                position: absolute;
                float: left;
                left: 50%;
                -moz-transform: translate(-50%, 0);
                -o-transform: translate(-50%, 0);
                -ms-transform: translate(-50%, 0);
                -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
                top: calc(100% + 5px);
                white-space: nowrap;
                font-weight: bold;
                font-size: 0.8em;
                text-align: center; }
            #profile .container .wrapper .nav ul span.phone {
              width: 40px;
              height: 40px;
              float: right;
              position: absolute;
              top: 5px;
              right: 0;
              border: 2px solid #444444;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              box-sizing: border-box;
              background-color: #444444;
              z-index: -1; }
            #profile .container .wrapper .nav ul span.desktop {
              display: none; }
        #profile .container .wrapper .left {
          float: left;
          position: relative;
          width: 100%;
          margin: 0;
          margin-bottom: 20px; }
          #profile .container .wrapper .left .nameNCredit {
            width: 100%;
            float: right;
            text-align: center; }
            #profile .container .wrapper .left .nameNCredit .name {
              width: 100%;
              height: 100px;
              float: right;
              line-height: 100px;
              font-weight: bold;
              font-size: 18px; }
            #profile .container .wrapper .left .nameNCredit .credit {
              line-height: 40px;
              float: right;
              margin-bottom: 10px;
              position: relative;
              right: 50%;
              text-align: center;
              -moz-transform: translate(50%, 0);
              -o-transform: translate(50%, 0);
              -ms-transform: translate(50%, 0);
              -webkit-transform: translate(50%, 0);
              transform: translate(50%, 0); }
              #profile .container .wrapper .left .nameNCredit .credit .creditValue {
                max-width: 200px;
                height: 40px;
                line-height: 40px;
                margin-bottom: 10px;
                float: right; }
  @keyframes burst {
    100% {
      -moz-transform: scale(2);
      -o-transform: scale(2);
      -ms-transform: scale(2);
      -webkit-transform: scale(2);
      transform: scale(2);
      opacity: 0; } }
              #profile .container .wrapper .left .nameNCredit .credit .addCreditBtn {
                width: 40px;
                height: 40px;
                float: right;
                border: 2px solid #444444;
                box-sizing: border-box;
                text-align: center;
                line-height: 40px;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                background-color: #ffcc00;
                margin: 0 10px;
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
                -moz-transition: all 0.3s linear;
                cursor: pointer; }
                #profile .container .wrapper .left .nameNCredit .credit .addCreditBtn:hover {
                  -moz-transform: rotate(-180deg);
                  -o-transform: rotate(-180deg);
                  -ms-transform: rotate(-180deg);
                  -webkit-transform: rotate(-180deg);
                  transform: rotate(-180deg); }
                #profile .container .wrapper .left .nameNCredit .credit .addCreditBtn.clicked {
                  animation: burst 0.6s forwards; }
              #profile .container .wrapper .left .nameNCredit .credit .addCredit {
                display: none;
                float: right; }
                #profile .container .wrapper .left .nameNCredit .credit .addCredit .inputWrapper {
                  margin-left: 10px;
                  width: calc(100% - 110px); }
                  #profile .container .wrapper .left .nameNCredit .credit .addCredit .inputWrapper input {
                    padding-left: 50px; }
                  #profile .container .wrapper .left .nameNCredit .credit .addCredit .inputWrapper .toman {
                    position: absolute;
                    float: left;
                    left: 15px;
                    color: white; }
                #profile .container .wrapper .left .nameNCredit .credit .addCredit button {
                  width: 100px;
                  font-family: "IRANSans";
                  float: right; }
          #profile .container .wrapper .left .info {
            flex-direction: column;
            align-items: center;
            width: 100%;
            float: right;
            height: calc(100% - 150px);
            overflow: auto;
            position: relative;
            right: 50%;
            margin-bottom: 20px;
            -moz-transform: translate(50%, 0);
            -o-transform: translate(50%, 0);
            -ms-transform: translate(50%, 0);
            -webkit-transform: translate(50%, 0);
            transform: translate(50%, 0); }
            #profile .container .wrapper .left .info .field {
              max-width: 300px;
              width: 100%;
              float: left;
              margin: 10px 0;
              left: 50%;
              position: relative;
              -moz-transform: translate(-50%, 0);
              -o-transform: translate(-50%, 0);
              -ms-transform: translate(-50%, 0);
              -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0); }
              #profile .container .wrapper .left .info .field:nth-child(2), #profile .container .wrapper .left .info .field:nth-child(4) {
                margin-left: 0; }
              #profile .container .wrapper .left .info .field .label {
                max-width: 300px;
                width: 100%;
                height: 40px;
                float: right;
                line-height: 40px;
                text-align: right; }
              #profile .container .wrapper .left .info .field .selectOptionWrapper {
                width: 75px;
                float: right;
                position: relative;
                text-align: center; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper.day {
                  width: calc((7 / 30) * 100%);
                  max-width: 70px; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper.year {
                  width: calc((9 / 30) * 100%);
                  max-width: 90px; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper.month {
                  width: calc((12 / 30) * 100%);
                  max-width: 120px; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper .title {
                  height: 40px;
                  line-height: 40px;
                  width: 100%;
                  float: left;
                  text-align: center; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper select {
                  -moz-appearance: none;
                  -webkit-appearance: none;
                  width: 100%;
                  height: 40px;
                  background-color: rgba(0, 0, 0, 0.5);
                  color: white;
                  font-size: 14px;
                  outline: none;
                  border: none;
                  border-radius: 20px;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  box-sizing: border-box;
                  padding: 0 20px;
                  padding-left: 35px;
                  font-family: "IRANSans";
                  line-height: 36px;
                  text-align: center;
                  background-image: url("../../media/down-arrow - white.png");
                  background-size: 20px 20px;
                  background-repeat: no-repeat;
                  background-position: 10px center;
                  cursor: pointer; }
                #profile .container .wrapper .left .info .field .selectOptionWrapper img {
                  display: none;
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  float: left;
                  left: 10px;
                  bottom: 10px; }
              #profile .container .wrapper .left .info .field span {
                float: right;
                width: calc((1 / 30) * 100%);
                max-width: 10px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-weight: bold; }
            #profile .container .wrapper .left .info button {
              float: right;
              position: relative;
              right: 50%;
              -moz-transform: translate(50%, 0);
              -o-transform: translate(50%, 0);
              -ms-transform: translate(50%, 0);
              -webkit-transform: translate(50%, 0);
              transform: translate(50%, 0);
              margin: 40px 0;
              margin-bottom: 0;
              font-family: "IRANSans"; }
          #profile .container .wrapper .left .editAddresses {
            width: 100%;
            float: right;
            height: calc(100% - 150px);
            overflow: auto;
            margin-bottom: 20px; }
            #profile .container .wrapper .left .editAddresses .addresses {
              width: 100%;
              float: right;
              position: relative;
              margin: 10px 0;
              padding: 0;
              list-style: none; }
              #profile .container .wrapper .left .editAddresses .addresses .emptyText {
                width: 100%;
                float: left; }
                #profile .container .wrapper .left .editAddresses .addresses .emptyText img {
                  width: 100px;
                  height: auto;
                  float: left;
                  left: 50%;
                  position: relative;
                  -moz-transform: translate(-50%, 0);
                  -o-transform: translate(-50%, 0);
                  -ms-transform: translate(-50%, 0);
                  -webkit-transform: translate(-50%, 0);
                  transform: translate(-50%, 0); }
                #profile .container .wrapper .left .editAddresses .addresses .emptyText p {
                  text-align: center;
                  margin: 20px 0;
                  width: 100%;
                  text-align: center;
                  float: left; }
              #profile .container .wrapper .left .editAddresses .addresses li {
                width: 100%;
                height: 60px;
                float: right;
                background-color: #ffcc00;
                color: #444444;
                border: 2px solid #444444;
                border-top: none;
                overflow: hidden;
                box-sizing: border-box; }
                #profile .container .wrapper .left .editAddresses .addresses li.active {
                  background-color: #444444;
                  color: #ffcc00; }
                  #profile .container .wrapper .left .editAddresses .addresses li.active .editBtn,
                  #profile .container .wrapper .left .editAddresses .addresses li.active .deleteBtn {
                    background-color: #ffcc00; }
                    #profile .container .wrapper .left .editAddresses .addresses li.active .editBtn img.off,
                    #profile .container .wrapper .left .editAddresses .addresses li.active .deleteBtn img.off {
                      display: none; }
                    #profile .container .wrapper .left .editAddresses .addresses li.active .editBtn img.on,
                    #profile .container .wrapper .left .editAddresses .addresses li.active .deleteBtn img.on {
                      display: block; }
                #profile .container .wrapper .left .editAddresses .addresses li:first-child {
                  border-top-left-radius: 20px;
                  border-top-right-radius: 20px;
                  border-top: 2px solid #444444; }
                #profile .container .wrapper .left .editAddresses .addresses li:last-child {
                  border-bottom-left-radius: 20px;
                  border-bottom-right-radius: 20px; }
                #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper {
                  width: 20px;
                  height: 20px;
                  float: right;
                  background-color: #ffcc00;
                  border: 2px solid #444444;
                  box-sizing: border-box;
                  position: relative;
                  margin: 20px;
                  border-radius: 50%;
                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%; }
                  #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper input {
                    position: relative;
                    width: 0;
                    height: 0;
                    visibility: hidden;
                    top: 0;
                    right: 0; }
                    #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper input:checked ~ label img {
                      display: block; }
                  #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper label {
                    width: 100%;
                    height: 100%;
                    float: right;
                    position: absolute;
                    top: 0;
                    right: 0;
                    cursor: pointer; }
                    #profile .container .wrapper .left .editAddresses .addresses li .radioWrapper label img {
                      display: none;
                      width: 16px;
                      height: 16px;
                      padding: 2px;
                      box-sizing: border-box;
                      float: right;
                      top: 0;
                      right: 0; }
                #profile .container .wrapper .left .editAddresses .addresses li .addressText {
                  width: calc(100% - 60px);
                  padding-right: 20px;
                  line-height: 60px;
                  float: right;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  cursor: pointer;
                  box-sizing: border-box; }
                #profile .container .wrapper .left .editAddresses .addresses li .editBtn,
                #profile .container .wrapper .left .editAddresses .addresses li .deleteBtn {
                  width: 20px;
                  height: 20px;
                  float: right;
                  background-color: #444444;
                  box-sizing: border-box;
                  position: relative;
                  margin: 20px 5px;
                  border-radius: 50%;
                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%;
                  transition: all 0.3s linear;
                  -webkit-transition: all 0.3s linear;
                  -moz-transition: all 0.3s linear; }
                  #profile .container .wrapper .left .editAddresses .addresses li .editBtn img.on,
                  #profile .container .wrapper .left .editAddresses .addresses li .deleteBtn img.on {
                    display: none; }
                  #profile .container .wrapper .left .editAddresses .addresses li .editBtn img,
                  #profile .container .wrapper .left .editAddresses .addresses li .deleteBtn img {
                    width: 20px;
                    height: 20px;
                    padding: 4px;
                    box-sizing: border-box;
                    float: right;
                    top: 0;
                    right: 0;
                    cursor: pointer;
                    filter: invert(1); }
                  #profile .container .wrapper .left .editAddresses .addresses li .editBtn:hover,
                  #profile .container .wrapper .left .editAddresses .addresses li .deleteBtn:hover {
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg); }
            #profile .container .wrapper .left .editAddresses .addAddress {
              width: 100%;
              float: right;
              position: relative;
              margin: 10px 0;
              padding: 0;
              list-style: none;
              margin-top: 20px; }
              #profile .container .wrapper .left .editAddresses .addAddress .label {
                width: auto !important;
                float: right;
                height: 40px;
                position: relative;
                text-align: right;
                line-height: 40px; }
              #profile .container .wrapper .left .editAddresses .addAddress .mapBtn {
                width: 100%;
                max-width: 300px;
                height: auto !important;
                float: right;
                position: relative;
                right: 50%;
                -moz-transform: translate(50%, 0);
                -o-transform: translate(50%, 0);
                -ms-transform: translate(50%, 0);
                -webkit-transform: translate(50%, 0);
                transform: translate(50%, 0); }
                #profile .container .wrapper .left .editAddresses .addAddress .mapBtn img {
                  width: 20px;
                  height: 20px;
                  float: left; }
              #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper {
                width: 100%;
                max-width: 300px;
                position: relative;
                float: left;
                left: 50%;
                -moz-transform: translate(-50%, 0%);
                -o-transform: translate(-50%, 0%);
                -ms-transform: translate(-50%, 0%);
                -webkit-transform: translate(-50%, 0%);
                transform: translate(-50%, 0%);
                margin: 10px 0; }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.alleyField {
                  clear: both;
                  margin-top: 20px; }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.alleyField, #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.noField {
                  width: 100%; }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.no3Field, #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.no2Field {
                  margin-bottom: 20px;
                  width: calc(50% - 10px);
                  max-width: 140px; }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.no2Field {
                  float: left;
                  left: calc(50% + 10px);
                  position: relative;
                  -moz-transform: translate(0, 0);
                  -o-transform: translate(0, 0);
                  -ms-transform: translate(0, 0);
                  -webkit-transform: translate(0, 0);
                  transform: translate(0, 0); }
                #profile .container .wrapper .left .editAddresses .addAddress .inputWrapper.no3Field {
                  float: right;
                  right: calc(50% + 10px);
                  position: relative;
                  -moz-transform: translate(0, 0);
                  -o-transform: translate(0, 0);
                  -ms-transform: translate(0, 0);
                  -webkit-transform: translate(0, 0);
                  transform: translate(0, 0); }
            #profile .container .wrapper .left .editAddresses button {
              position: relative;
              font-family: "IRANSans";
              float: right;
              right: 50%;
              bottom: auto !important;
              -moz-transform: translate(50%, 0);
              -o-transform: translate(50%, 0);
              -ms-transform: translate(50%, 0);
              -webkit-transform: translate(50%, 0);
              transform: translate(50%, 0); }
          #profile .container .wrapper .left .myOrders {
            margin-bottom: 20px;
            width: 100%;
            float: right;
            height: calc(100% - 150px); }
            #profile .container .wrapper .left .myOrders ul {
              width: calc(100% - 20px);
              float: right;
              margin: 0 10px;
              padding: 0;
              list-style: none; }
              #profile .container .wrapper .left .myOrders ul .emptyText {
                width: 100%;
                float: left; }
                #profile .container .wrapper .left .myOrders ul .emptyText img {
                  width: 100px;
                  height: auto;
                  float: left;
                  left: 50%;
                  position: relative;
                  -moz-transform: translate(-50%, 0);
                  -o-transform: translate(-50%, 0);
                  -ms-transform: translate(-50%, 0);
                  -webkit-transform: translate(-50%, 0);
                  transform: translate(-50%, 0); }
                #profile .container .wrapper .left .myOrders ul .emptyText p {
                  text-align: center;
                  margin: 20px 0;
                  width: 100%;
                  text-align: center;
                  float: left; }
              #profile .container .wrapper .left .myOrders ul li {
                width: 100%;
                float: right;
                box-sizing: border-box; }
                #profile .container .wrapper .left .myOrders ul li:first-child .title {
                  border-top-left-radius: 20px;
                  border-top-right-radius: 20px;
                  border-top: 2px solid #444444; }
                #profile .container .wrapper .left .myOrders ul li:last-child .title {
                  border-bottom-left-radius: 20px;
                  border-bottom-right-radius: 20px;
                  border-bottom: 2px solid #444444; }
                #profile .container .wrapper .left .myOrders ul li .title {
                  width: 100%;
                  height: 60px;
                  float: right;
                  border: 2px solid #444444;
                  border-top: 1px solid #444444;
                  border-bottom: 1px solid #444444;
                  background-color: #ffcc00;
                  line-height: 60px;
                  box-sizing: border-box;
                  padding-right: 20px;
                  cursor: pointer; }
                  #profile .container .wrapper .left .myOrders ul li .title span {
                    float: right;
                    line-height: 60px;
                    margin: 0 5px;
                    font-size: 12px; }
                    #profile .container .wrapper .left .myOrders ul li .title span:first-child {
                      font-weight: bold; }
                    #profile .container .wrapper .left .myOrders ul li .title span:last-child {
                      font-size: 8px; }
                  #profile .container .wrapper .left .myOrders ul li .title .downBtn {
                    width: 20px;
                    height: 20px;
                    margin: 20px 10px;
                    float: left;
                    cursor: pointer;
                    transition: all 0.3s linear;
                    -webkit-transition: all 0.3s linear;
                    -moz-transition: all 0.3s linear; }
                    #profile .container .wrapper .left .myOrders ul li .title .downBtn img {
                      width: 100%;
                      height: 100%;
                      float: left; }
                    #profile .container .wrapper .left .myOrders ul li .title .downBtn:hover {
                      -moz-transform: translate(0, 5px);
                      -o-transform: translate(0, 5px);
                      -ms-transform: translate(0, 5px);
                      -webkit-transform: translate(0, 5px);
                      transform: translate(0, 5px); }
                #profile .container .wrapper .left .myOrders ul li .details {
                  display: none;
                  width: calc(100% - 20px);
                  float: right;
                  margin: 0 10px;
                  background-color: rgba(0, 0, 0, 0.5); }
                  #profile .container .wrapper .left .myOrders ul li .details.show {
                    display: block !important;
                    opacity: 1;
                    animation: detailsFadeIn 350ms; }
  @keyframes detailsFadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
                  #profile .container .wrapper .left .myOrders ul li .details .shippingLevel {
                    width: 90%;
                    height: 40px;
                    float: right;
                    right: 50%;
                    position: relative;
                    clear: both;
                    z-index: 5;
                    margin-top: 10px;
                    -moz-transform: translate(50%, 0);
                    -o-transform: translate(50%, 0);
                    -ms-transform: translate(50%, 0);
                    -webkit-transform: translate(50%, 0);
                    transform: translate(50%, 0); }
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1,
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2,
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3,
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4,
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5 {
                      width: 40px;
                      height: 40px;
                      float: right;
                      background-color: #ffcc00;
                      border: 2px solid #444444;
                      border-radius: 50%;
                      -webkit-border-radius: 50%;
                      -moz-border-radius: 50%;
                      box-sizing: border-box;
                      position: relative; }
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1.done,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2.done,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3.done,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4.done,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5.done {
                        background-color: #444444; }
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1.done img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2.done img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3.done img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4.done img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5.done img.on {
                          display: block; }
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1.done img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2.done img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3.done img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4.done img.off,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5.done img.off {
                          display: none; }
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1.active:before,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2.active:before,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3.active:before,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4.active:before,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5.active:before {
                        content: '';
                        position: absolute;
                        width: 45px;
                        height: 45px;
                        float: right;
                        border: 2px dashed #444444;
                        top: -6.5px;
                        right: -6.5px;
                        border-radius: 50%;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%; }
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1 img,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2 img,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3 img,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4 img,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5 img {
                        width: 25px;
                        height: 25px;
                        margin: 5px;
                        position: absolute;
                        top: 0;
                        float: right;
                        right: 0; }
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1 img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2 img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3 img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4 img.on,
                        #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5 img.on {
                          display: none; }
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level1 .levelName,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level2 .levelName,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level3 .levelName,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level4 .levelName,
                      #profile .container .wrapper .left .myOrders ul li .details .shippingLevel .level5 .levelName {
                        display: none;
                        width: 150px;
                        height: 20px;
                        line-height: 20px;
                        position: absolute;
                        bottom: -30px;
                        float: right;
                        right: 50%;
                        margin-right: -75px;
                        text-align: center;
                        font-size: 12.6px; }
                    #profile .container .wrapper .left .myOrders ul li .details .shippingLevel span {
                      width: calc((100% - 200px) / 4);
                      height: 2px;
                      background-color: #444444;
                      float: right;
                      margin: 23px 0; }
                  #profile .container .wrapper .left .myOrders ul li .details ul {
                    width: 100%;
                    float: right;
                    padding: 0;
                    margin: 30px 0;
                    list-style: none;
                    box-sizing: border-box; }
                    #profile .container .wrapper .left .myOrders ul li .details ul li {
                      width: 100%;
                      height: 70px;
                      float: right;
                      padding: 5px;
                      box-sizing: border-box;
                      position: relative;
                      margin: 5px 0; }
                      #profile .container .wrapper .left .myOrders ul li .details ul li .deleteBtn {
                        display: none;
                        width: 20px;
                        height: 20px;
                        text-align: center;
                        line-height: 20px;
                        float: right;
                        color: white;
                        position: absolute;
                        top: 0;
                        right: 0;
                        cursor: pointer; }
                      #profile .container .wrapper .left .myOrders ul li .details ul li img {
                        width: 60px;
                        height: 60px;
                        float: right; }
                      #profile .container .wrapper .left .myOrders ul li .details ul li .matName {
                        width: calc(100% - 170px);
                        height: 60px;
                        float: right;
                        margin: 0 10px;
                        line-height: 60px;
                        color: white; }
                      #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount {
                        width: 90px;
                        height: 60px;
                        float: left;
                        color: white; }
                        #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount .price,
                        #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount .count {
                          width: 100%;
                          height: 50%;
                          float: left; }
                          #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount .price span,
                          #profile .container .wrapper .left .myOrders ul li .details ul li .priceNCount .count span {
                            width: 50%;
                            height: 100%;
                            float: right;
                            text-align: center;
                            line-height: 37.5px; }
                  #profile .container .wrapper .left .myOrders ul li .details .btns {
                    float: right !important;
                    position: relative !important;
                    right: 50% !important;
                    margin: 10px 0 !important;
                    -moz-transform: translate(50%, 0);
                    -o-transform: translate(50%, 0);
                    -ms-transform: translate(50%, 0);
                    -webkit-transform: translate(50%, 0);
                    transform: translate(50%, 0); }
                    #profile .container .wrapper .left .myOrders ul li .details .btns a {
                      width: 100%;
                      float: right !important;
                      margin: 5px !important;
                      line-height: 30px !important;
                      -moz-transform: translate(0, 0);
                      -o-transform: translate(0, 0);
                      -ms-transform: translate(0, 0);
                      -webkit-transform: translate(0, 0);
                      transform: translate(0, 0);
                      bottom: auto !important;
                      position: relative !important;
                      clear: none !important;
                      right: auto !important; }
          #profile .container .wrapper .left .myPizzas {
            width: 100%;
            float: right;
            height: calc(100% - 150px);
            overflow: auto; }
            #profile .container .wrapper .left .myPizzas .pizzas {
              max-width: 300px;
              width: 100%;
              float: right;
              right: 50%;
              margin: 0;
              padding: 0;
              list-style: none;
              position: relative;
              -moz-transform: translate(50%, 0);
              -o-transform: translate(50%, 0);
              -ms-transform: translate(50%, 0);
              -webkit-transform: translate(50%, 0);
              transform: translate(50%, 0); }
              #profile .container .wrapper .left .myPizzas .pizzas .emptyText {
                width: 100%;
                float: left; }
                #profile .container .wrapper .left .myPizzas .pizzas .emptyText img {
                  width: 100px;
                  height: auto;
                  float: left;
                  left: 50%;
                  position: relative;
                  -moz-transform: translate(-50%, 0);
                  -o-transform: translate(-50%, 0);
                  -ms-transform: translate(-50%, 0);
                  -webkit-transform: translate(-50%, 0);
                  transform: translate(-50%, 0); }
                #profile .container .wrapper .left .myPizzas .pizzas .emptyText p {
                  text-align: center;
                  margin: 20px 0;
                  width: 100%;
                  text-align: center;
                  float: left; }
              #profile .container .wrapper .left .myPizzas .pizzas li {
                max-width: 300px;
                width: 100%;
                height: 225px;
                margin: 10px 0;
                float: right;
                display: inline-block;
                background-color: rgba(0, 0, 0, 0.5);
                position: relative;
                margin-top: 75px;
                padding: 10px;
                padding-top: 85px;
                box-sizing: border-box;
                border-radius: 20px;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                color: white; }
                #profile .container .wrapper .left .myPizzas .pizzas li img {
                  width: 150px;
                  height: 150px;
                  position: absolute;
                  top: -75px;
                  right: 50%;
                  -moz-transform: translate(50%, 0);
                  -o-transform: translate(50%, 0);
                  -ms-transform: translate(50%, 0);
                  -webkit-transform: translate(50%, 0);
                  transform: translate(50%, 0); }
                #profile .container .wrapper .left .myPizzas .pizzas li .pizzaName {
                  width: 100%;
                  line-height: 40px;
                  font-weight: bold;
                  float: right;
                  text-align: center; }
                #profile .container .wrapper .left .myPizzas .pizzas li .ingredients {
                  width: 100%;
                  height: 50px;
                  float: right;
                  font-size: 0.7em;
                  text-align: center;
                  overflow: hidden; }
                #profile .container .wrapper .left .myPizzas .pizzas li button {
                  font-family: "IRANSans";
                  float: right;
                  right: 50%;
                  position: relative;
                  -moz-transform: translate(50%, 0);
                  -o-transform: translate(50%, 0);
                  -ms-transform: translate(50%, 0);
                  -webkit-transform: translate(50%, 0);
                  transform: translate(50%, 0); }
          #profile .container .wrapper .left .invitation {
            width: 100%;
            float: right;
            margin-top: 20px;
            margin-bottom: 20px; }
            #profile .container .wrapper .left .invitation .label {
              width: 100%;
              float: right;
              height: 40px;
              line-height: 40px;
              font-weight: bold;
              text-align: center; }
            #profile .container .wrapper .left .invitation .invitationCode {
              width: 200px;
              height: 40px;
              float: right;
              border: 2px solid #444444;
              box-sizing: border-box;
              border-radius: 20px;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              background-color: #ffcc00;
              text-align: center;
              line-height: 36px;
              padding: 0 20px;
              position: relative;
              right: 50%;
              -moz-transform: translate(50%, 0);
              -o-transform: translate(50%, 0);
              -ms-transform: translate(50%, 0);
              -webkit-transform: translate(50%, 0);
              transform: translate(50%, 0); }
            #profile .container .wrapper .left .invitation .shareBtns {
              float: right;
              height: 40px;
              margin: 10px 0;
              clear: both;
              right: 50%;
              position: relative;
              -moz-transform: translate(50%, 0);
              -o-transform: translate(50%, 0);
              -ms-transform: translate(50%, 0);
              -webkit-transform: translate(50%, 0);
              transform: translate(50%, 0); }
              #profile .container .wrapper .left .invitation .shareBtns .copyBtn,
              #profile .container .wrapper .left .invitation .shareBtns .shareBtn {
                width: 40px;
                height: 40px;
                float: right;
                margin: 0 5px;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                background-color: #444444;
                cursor: pointer;
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
                -moz-transition: all 0.3s linear; }
                #profile .container .wrapper .left .invitation .shareBtns .copyBtn:hover,
                #profile .container .wrapper .left .invitation .shareBtns .shareBtn:hover {
                  opacity: 0.8; }
                #profile .container .wrapper .left .invitation .shareBtns .copyBtn img,
                #profile .container .wrapper .left .invitation .shareBtns .shareBtn img {
                  width: 100%;
                  height: 100%;
                  padding: 10px;
                  box-sizing: border-box; }
              #profile .container .wrapper .left .invitation .shareBtns .copyBtn img {
                filter: invert(1); }
            #profile .container .wrapper .left .invitation .desc {
              width: 100%;
              float: right;
              margin-top: 10px;
              text-align: justify; }
          #profile .container .wrapper .left .setting {
            align-items: center;
            width: 100%;
            float: right;
            margin-top: 20px;
            position: relative; }
            #profile .container .wrapper .left .setting .setLang {
              width: 100%;
              float: right;
              height: 40px;
              margin: 10px 0; }
              #profile .container .wrapper .left .setting .setLang .label {
                float: right;
                margin-left: 10px;
                line-height: 40px; }
              #profile .container .wrapper .left .setting .setLang .selectOptionWrapper {
                width: 200px;
                float: left;
                position: relative;
                text-align: center; }
                #profile .container .wrapper .left .setting .setLang .selectOptionWrapper select {
                  -moz-appearance: none;
                  -webkit-appearance: none;
                  width: 100%;
                  height: 40px;
                  background-color: rgba(0, 0, 0, 0.5);
                  color: white;
                  font-size: 14px;
                  outline: none;
                  border: none;
                  border-radius: 20px;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  box-sizing: border-box;
                  padding: 0 20px;
                  padding-left: 35px;
                  font-family: "IRANSans";
                  line-height: 36px;
                  text-align: center;
                  background-image: url("../../media/down-arrow - white.png");
                  background-size: 20px 20px;
                  background-repeat: no-repeat;
                  background-position: 10px center;
                  cursor: pointer; }
                #profile .container .wrapper .left .setting .setLang .selectOptionWrapper img {
                  display: none;
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  float: left;
                  left: 10px;
                  bottom: 10px; }
            #profile .container .wrapper .left .setting button {
              float: left;
              position: relative;
              left: 50%;
              -moz-transform: translate(-50%, 0);
              -o-transform: translate(-50%, 0);
              -ms-transform: translate(-50%, 0);
              -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0);
              margin: 20px 0; }
            #profile .container .wrapper .left .setting .settingOption {
              width: 100%;
              float: right;
              margin: 10px 0;
              height: 40px;
              clear: both; }
              #profile .container .wrapper .left .setting .settingOption .label {
                float: right;
                width: 200px;
                line-height: 40px; }
              #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper {
                width: 50px;
                height: 30px;
                float: left;
                margin: 5px 0; }
                #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper input {
                  width: 0;
                  height: 0;
                  padding: 0;
                  margin: 0;
                  visibility: hidden; }
                  #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper input:checked ~ label {
                    opacity: 1; }
                    #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper input:checked ~ label .circle {
                      right: 2px; }
                #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper label {
                  width: 100%;
                  height: 100%;
                  float: right;
                  position: relative;
                  background-color: #ffcc00;
                  border: 2px solid #444444;
                  border-radius: 15px;
                  -webkit-border-radius: 15px;
                  -moz-border-radius: 15px;
                  box-sizing: border-box;
                  opacity: 0.5;
                  cursor: pointer;
                  transition: all 0.3s linear;
                  -webkit-transition: all 0.3s linear;
                  -moz-transition: all 0.3s linear; }
                  #profile .container .wrapper .left .setting .settingOption .checkBtnWrapper label .circle {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    float: right;
                    top: 50%;
                    margin-top: -10px;
                    background-color: #444444;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    right: calc(100% - 22px); } }
