.En .leaflet-control-geocoder-form input {
  text-align: left !important;
  font-family: IRANSans; }

.modal-container-inner {
  z-index: 10000000;
  width: 100vw;
  height: 100%;
  float: left;
  position: fixed !important;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden; }
  .modal-container-inner .modal-overlay {
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    float: left;
    left: 0;
    z-index: -10; }
  .modal-container-inner .modal-content-small {
    width: 400px;
    height: fit-content;
    float: right;
    position: absolute;
    background-color: #ffcc00;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    right: 50%;
    top: 50%;
    margin-right: -200px;
    margin-top: -100px;
    z-index: 110;
    padding: 10px; }
    .modal-container-inner .modal-content-small .btn {
      margin: 5px; }
    @media screen and (max-width: 767px) {
      .modal-container-inner .modal-content-small {
        width: 90%;
        margin-right: 0;
        margin-left: 0;
        -moz-transform: translate(50%, 0);
        -o-transform: translate(50%, 0);
        -ms-transform: translate(50%, 0);
        -webkit-transform: translate(50%, 0);
        transform: translate(50%, 0); } }
    .modal-container-inner .modal-content-small .pizzaName {
      width: 100%;
      float: left; }
      .modal-container-inner .modal-content-small .pizzaName .inputWrapper {
        float: left !important;
        left: 50%;
        -moz-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0); }
      .modal-container-inner .modal-content-small .pizzaName button {
        margin: 20px 0 !important; }
    .modal-container-inner .modal-content-small .title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      float: right;
      text-align: center; }
    .modal-container-inner .modal-content-small button {
      position: relative;
      clear: both;
      float: right;
      right: 50%;
      transform: translateX(50%); }
  .modal-container-inner .modal-content {
    width: 800px;
    max-height: 570px;
    height: calc(100% - 200px);
    float: right;
    position: absolute;
    background-color: #ffcc00;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    right: 50%;
    top: 50%;
    -moz-transform: translate(50%, -50%);
    -o-transform: translate(50%, -50%);
    -ms-transform: translate(50%, -50%);
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    z-index: 110;
    padding: 10px; }
    @media screen and (max-width: 767px) {
      .modal-container-inner .modal-content {
        width: 80%;
        margin-right: 0;
        margin-left: 0;
        -moz-transform: translate(50%, -50%);
        -o-transform: translate(50%, -50%);
        -ms-transform: translate(50%, -50%);
        -webkit-transform: translate(50%, -50%);
        transform: translate(50%, -50%); }
        .modal-container-inner .modal-content .leaflet-control-geocoder {
          display: none !important; } }
    @media screen and (max-width: 1200px) and (min-width: 768px) and (orientation: portrait) {
      .modal-container-inner .modal-content {
        width: 90%;
        margin-right: 0;
        margin-left: 0; } }
    .modal-container-inner .modal-content .title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      float: right;
      text-align: center; }
    .modal-container-inner .modal-content button {
      position: relative;
      clear: both;
      float: right;
      right: 50%;
      transform: translateX(50%);
      margin-top: 10px;
      margin-bottom: 10px; }

.leaflet-container {
  position: relative; }

.leaflet-marker-icon {
  filter: invert(0.25); }

.leaflet-popup-content-wrapper {
  font-family: IRANSans;
  direction: rtl;
  text-align: right; }

.mapCurrentLocationBtn {
  width: 50px !important;
  height: 50px !important;
  background-color: #ffcc00 !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  font-family: IRANSans;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  position: absolute !important;
  bottom: -15px !important;
  right: 45px !important;
  z-index: 9999 !important;
  border: 2px solid #444444;
  cursor: pointer;
  outline: none;
  margin: 0 !important; }
  .mapCurrentLocationBtn img {
    width: 130%;
    height: auto;
    filter: invert(0.25); }

.leaflet-control-geocoder {
  border: none !important; }
  .leaflet-control-geocoder button {
    display: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    background-color: #ffcc00;
    border: none;
    box-shadow: 0 0 10px #444444;
    padding: 5px;
    box-sizing: border-box; }
  .leaflet-control-geocoder .leaflet-control-geocoder-form input {
    width: 200px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    box-sizing: border-box;
    color: white;
    padding: 0 20px;
    outline: none;
    line-height: 40px; }
    .leaflet-control-geocoder .leaflet-control-geocoder-form input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(255, 255, 255, 0.5);
      opacity: 1;
      /* Firefox */ }
    .leaflet-control-geocoder .leaflet-control-geocoder-form input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(255, 255, 255, 0.5); }
    .leaflet-control-geocoder .leaflet-control-geocoder-form input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(255, 255, 255, 0.5); }
  .leaflet-control-geocoder .leaflet-control-geocoder-form-no-error {
    display: none; }
  .leaflet-control-geocoder ul.leaflet-control-geocoder-alternatives {
    width: 200px;
    max-height: 300px !important;
    overflow: auto;
    padding: 0;
    margin: 0;
    margin-top: 5px;
    height: auto;
    background-color: #ffcc00;
    box-sizing: border-box;
    box-shadow: 0 0 10px #444444;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px; }
    .leaflet-control-geocoder ul.leaflet-control-geocoder-alternatives li {
      width: 100%;
      color: #444444;
      list-style: none;
      background-color: transparent;
      text-align: left;
      padding: 5px 10px;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0, 0, 0, 0.5); }
      .leaflet-control-geocoder ul.leaflet-control-geocoder-alternatives li a {
        width: 100%;
        height: auto !important;
        background-color: transparent;
        text-align: left;
        font-weight: bold;
        cursor: pointer; }
        .leaflet-control-geocoder ul.leaflet-control-geocoder-alternatives li a span {
          display: block;
          width: 100%;
          font-weight: bold; }
          .leaflet-control-geocoder ul.leaflet-control-geocoder-alternatives li a span.leaflet-control-geocoder-address-context {
            font-weight: 300; }
          .leaflet-control-geocoder ul.leaflet-control-geocoder-alternatives li a span:first {
            background-color: rebeccapurple; }
        .leaflet-control-geocoder ul.leaflet-control-geocoder-alternatives li a br {
          display: none; }

.map {
  height: calc(100% - 150px) !important;
  width: auto !important;
  position: relative; }
  .map.supportingRegion {
    height: calc(100% - 100px) !important; }
