@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_Bold.eot");
  src: url("../../fonts/eot/IRANSansWeb_Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_Bold.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_Bold.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_Bold.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_Medium.eot");
  src: url("../../fonts/eot/IRANSansWeb_Medium.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_Medium.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_Medium.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_Medium.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_Light.eot");
  src: url("../../fonts/eot/IRANSansWeb_Light.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_Light.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_Light.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_Light.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_UltraLight.eot");
  src: url("../../fonts/eot/IRANSansWeb_UltraLight.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_UltraLight.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_UltraLight.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_UltraLight.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb.eot");
  src: url("../../fonts/eot/IRANSansWeb.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb.woff") format("woff"); }

@font-face {
  font-family: Lalezar;
  font-display: swap;
  src: url("../../fonts/Lalezar-Regular.ttf") format("truetype"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_Bold.eot");
  src: url("../../fonts/eot/IRANSansWeb_Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_Bold.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_Bold.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_Bold.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_Medium.eot");
  src: url("../../fonts/eot/IRANSansWeb_Medium.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_Medium.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_Medium.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_Medium.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_Light.eot");
  src: url("../../fonts/eot/IRANSansWeb_Light.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_Light.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_Light.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_Light.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb_UltraLight.eot");
  src: url("../../fonts/eot/IRANSansWeb_UltraLight.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb_UltraLight.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb_UltraLight.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb_UltraLight.woff") format("woff"); }

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../../fonts/eot/IRANSansWeb.eot");
  src: url("../../fonts/eot/IRANSansWeb.eot?#iefix") format("embedded-opentype"), url("../../fonts/ttf/IRANSansWeb.ttf") format("truetype"), url("../../fonts/woff2/IRANSansWeb.woff2") format("woff2"), url("../../fonts/woff/IRANSansWeb.woff") format("woff"); }

@font-face {
  font-family: Lalezar;
  font-display: swap;
  src: url("../../fonts/Lalezar-Regular.ttf") format("truetype"); }

@media screen and (min-width: 1200px) {
  .btn {
    width: 200px;
    height: 40px;
    line-height: 26px;
    box-sizing: border-box;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    background-color: #444444;
    color: white !important;
    padding: 5px 20px;
    border: 2px solid #444444;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    font-family: "IRANSans";
    outline: none; }
    .btn:hover {
      opacity: 0.8; }
    .btn:disabled {
      opacity: 0.5 !important;
      cursor: default !important; }
  .yellowBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important; }
  .yellowBorderBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important;
    border: none; }
  .En .inputWrapper {
    float: left !important; }
    .En .inputWrapper input[type='text'],
    .En .inputWrapper input[type='email'],
    .En .inputWrapper textarea {
      padding-right: 20px !important;
      padding-left: 35px !important; }
    .En .inputWrapper img {
      float: left !important;
      left: 10px;
      right: auto !important;
      top: 10px;
      filter: invert(1); }
  .inputWrapper {
    width: 300px;
    height: 40px;
    float: right;
    position: relative; }
    .inputWrapper input[type='text'],
    .inputWrapper input[type='email'],
    .inputWrapper input[type='number'],
    .inputWrapper textarea {
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-size: 14px;
      outline: none;
      border: none;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      padding: 0 20px;
      padding-right: 35px;
      font-family: "IRANSans";
      line-height: 36px; }
      .inputWrapper input[type='text'].success,
      .inputWrapper input[type='email'].success,
      .inputWrapper input[type='number'].success,
      .inputWrapper textarea.success {
        background-color: rgba(0, 100, 0, 0.5); }
      .inputWrapper input[type='text'].error,
      .inputWrapper input[type='email'].error,
      .inputWrapper input[type='number'].error,
      .inputWrapper textarea.error {
        background-color: rgba(100, 0, 0, 0.5); }
      .inputWrapper input[type='text']:focus ~ img,
      .inputWrapper input[type='email']:focus ~ img,
      .inputWrapper input[type='number']:focus ~ img,
      .inputWrapper textarea:focus ~ img {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1); }
      .inputWrapper input[type='text']::-webkit-input-placeholder,
      .inputWrapper input[type='email']::-webkit-input-placeholder,
      .inputWrapper input[type='number']::-webkit-input-placeholder,
      .inputWrapper textarea::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']::-moz-placeholder,
      .inputWrapper input[type='email']::-moz-placeholder,
      .inputWrapper input[type='number']::-moz-placeholder,
      .inputWrapper textarea::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-ms-input-placeholder,
      .inputWrapper input[type='email']:-ms-input-placeholder,
      .inputWrapper input[type='number']:-ms-input-placeholder,
      .inputWrapper textarea:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-moz-placeholder,
      .inputWrapper input[type='email']:-moz-placeholder,
      .inputWrapper input[type='number']:-moz-placeholder,
      .inputWrapper textarea:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.5); }
    .inputWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: right;
      right: 10px;
      top: 10px;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      filter: invert(1); }
  .selectWrapper {
    width: 100px;
    height: 40px;
    float: right;
    margin-left: 10px;
    position: relative; }
    .selectWrapper select {
      -moz-appearance: none;
      -webkit-appearance: none;
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border: none;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      outline: none;
      font-family: "IRANSans";
      padding: 0 20px;
      padding-left: 35px; }
    .selectWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: left;
      left: 10px;
      top: 10px; }
  .tooltip {
    display: none;
    height: 20px;
    position: absolute;
    padding: 0 10px;
    float: right;
    box-sizing: border-box;
    z-index: 90;
    line-height: 20px;
    white-space: nowrap;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    background-color: #444444;
    color: #ffcc00;
    top: calc(100%);
    right: 50%;
    text-align: center;
    font-size: 12px;
    -moz-transform: translate(50%, 0);
    -o-transform: translate(50%, 0);
    -ms-transform: translate(50%, 0);
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0); }
    .tooltip::before {
      width: 0;
      height: 0;
      content: '';
      position: absolute;
      float: right;
      top: -10px;
      right: 50%;
      border-bottom: 5px solid #444444;
      border-top: 5px solid transparent;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      -moz-transform: translate(50%, 0);
      -o-transform: translate(50%, 0);
      -ms-transform: translate(50%, 0);
      -webkit-transform: translate(50%, 0);
      transform: translate(50%, 0); }
  @keyframes downTooltip {
    0% {
      top: calc(100% - 10px); }
    100% {
      top: calc(100%); } } }

@media screen and (max-width: 1200px) and (min-width: 768px) and (orientation: landscape) {
  .btn {
    width: 200px;
    height: 40px;
    line-height: 26px;
    box-sizing: border-box;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    background-color: #444444;
    color: white !important;
    padding: 5px 20px;
    border: 2px solid #444444;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    font-family: "IRANSans";
    outline: none; }
    .btn:hover {
      opacity: 0.8; }
    .btn:disabled {
      opacity: 0.5 !important;
      cursor: default !important; }
  .yellowBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important; }
  .yellowBorderBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important;
    border: none; }
  .En .inputWrapper {
    float: left !important; }
    .En .inputWrapper input[type='text'],
    .En .inputWrapper input[type='email'],
    .En .inputWrapper textarea {
      padding-right: 20px !important;
      padding-left: 35px !important; }
    .En .inputWrapper img {
      float: left !important;
      left: 10px;
      right: auto !important;
      top: 10px;
      filter: invert(1); }
  .inputWrapper {
    width: 300px;
    height: 40px;
    float: right;
    position: relative; }
    .inputWrapper input[type='text'],
    .inputWrapper input[type='email'],
    .inputWrapper input[type='number'],
    .inputWrapper textarea {
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-size: 14px;
      outline: none;
      border: none;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      padding: 0 20px;
      padding-right: 35px;
      font-family: "IRANSans";
      line-height: 36px; }
      .inputWrapper input[type='text'].success,
      .inputWrapper input[type='email'].success,
      .inputWrapper input[type='number'].success,
      .inputWrapper textarea.success {
        background-color: rgba(0, 100, 0, 0.5); }
      .inputWrapper input[type='text'].error,
      .inputWrapper input[type='email'].error,
      .inputWrapper input[type='number'].error,
      .inputWrapper textarea.error {
        background-color: rgba(100, 0, 0, 0.5); }
      .inputWrapper input[type='text']:focus ~ img,
      .inputWrapper input[type='email']:focus ~ img,
      .inputWrapper input[type='number']:focus ~ img,
      .inputWrapper textarea:focus ~ img {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1); }
      .inputWrapper input[type='text']::-webkit-input-placeholder,
      .inputWrapper input[type='email']::-webkit-input-placeholder,
      .inputWrapper input[type='number']::-webkit-input-placeholder,
      .inputWrapper textarea::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']::-moz-placeholder,
      .inputWrapper input[type='email']::-moz-placeholder,
      .inputWrapper input[type='number']::-moz-placeholder,
      .inputWrapper textarea::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-ms-input-placeholder,
      .inputWrapper input[type='email']:-ms-input-placeholder,
      .inputWrapper input[type='number']:-ms-input-placeholder,
      .inputWrapper textarea:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-moz-placeholder,
      .inputWrapper input[type='email']:-moz-placeholder,
      .inputWrapper input[type='number']:-moz-placeholder,
      .inputWrapper textarea:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.5); }
    .inputWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: right;
      right: 10px;
      top: 10px;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      filter: invert(1); }
  .selectWrapper {
    width: 100px;
    height: 40px;
    float: right;
    margin-left: 10px;
    position: relative; }
    .selectWrapper select {
      -moz-appearance: none;
      -webkit-appearance: none;
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border: none;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      outline: none;
      font-family: "IRANSans";
      padding: 0 20px;
      padding-left: 35px; }
    .selectWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: left;
      left: 10px;
      top: 10px; }
  .tooltip {
    display: none;
    height: 20px;
    position: absolute;
    padding: 0 10px;
    float: right;
    box-sizing: border-box;
    z-index: 90;
    line-height: 20px;
    white-space: nowrap;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    background-color: #444444;
    color: #ffcc00;
    top: calc(100%);
    right: 50%;
    text-align: center;
    font-size: 12px;
    -moz-transform: translate(50%, 0);
    -o-transform: translate(50%, 0);
    -ms-transform: translate(50%, 0);
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0); }
    .tooltip::before {
      width: 0;
      height: 0;
      content: '';
      position: absolute;
      float: right;
      top: -10px;
      right: 50%;
      border-bottom: 5px solid #444444;
      border-top: 5px solid transparent;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      -moz-transform: translate(50%, 0);
      -o-transform: translate(50%, 0);
      -ms-transform: translate(50%, 0);
      -webkit-transform: translate(50%, 0);
      transform: translate(50%, 0); }
  @keyframes downTooltip {
    0% {
      top: calc(100% - 10px); }
    100% {
      top: calc(100%); } } }

@media screen and (max-width: 1200px) and (min-width: 768px) and (orientation: portrait) {
  .btn {
    width: 200px;
    height: 40px;
    line-height: 26px;
    box-sizing: border-box;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    background-color: #444444;
    color: white !important;
    padding: 5px 20px;
    border: 2px solid #444444;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    font-family: "IRANSans"; }
    .btn:hover {
      opacity: 0.8; }
    .btn:disabled {
      opacity: 0.5; }
  .yellowBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important; }
  .yellowBorderBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important;
    border: none; }
  .En .inputWrapper {
    float: left !important; }
    .En .inputWrapper input[type='text'],
    .En .inputWrapper input[type='email'],
    .En .inputWrapper input[type='number'],
    .En .inputWrapper textarea {
      padding-right: 20px !important;
      padding-left: 35px !important; }
    .En .inputWrapper img {
      float: left !important;
      left: 10px;
      right: auto !important;
      top: 10px;
      filter: invert(1); }
  .inputWrapper {
    width: 300px;
    height: 40px;
    float: right;
    position: relative; }
    .inputWrapper input[type='text'],
    .inputWrapper input[type='email'],
    .inputWrapper textarea {
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-size: 14px;
      outline: none;
      border: none;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      padding: 0 20px;
      padding-right: 35px;
      font-family: "IRANSans";
      line-height: 36px; }
      .inputWrapper input[type='text'].success,
      .inputWrapper input[type='email'].success,
      .inputWrapper textarea.success {
        background-color: rgba(0, 100, 0, 0.5); }
      .inputWrapper input[type='text'].error,
      .inputWrapper input[type='email'].error,
      .inputWrapper textarea.error {
        background-color: rgba(100, 0, 0, 0.5); }
      .inputWrapper input[type='text']:focus ~ img,
      .inputWrapper input[type='email']:focus ~ img,
      .inputWrapper textarea:focus ~ img {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1); }
      .inputWrapper input[type='text']::-webkit-input-placeholder,
      .inputWrapper input[type='email']::-webkit-input-placeholder,
      .inputWrapper textarea::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']::-moz-placeholder,
      .inputWrapper input[type='email']::-moz-placeholder,
      .inputWrapper textarea::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-ms-input-placeholder,
      .inputWrapper input[type='email']:-ms-input-placeholder,
      .inputWrapper textarea:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-moz-placeholder,
      .inputWrapper input[type='email']:-moz-placeholder,
      .inputWrapper textarea:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.5); }
    .inputWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: right;
      right: 10px;
      top: 10px;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      filter: invert(1); }
  .selectWrapper {
    width: 100px;
    height: 40px;
    float: right;
    margin-left: 10px;
    position: relative; }
    .selectWrapper select {
      -moz-appearance: none;
      -webkit-appearance: none;
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      border: none;
      color: white;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      outline: none;
      font-family: "IRANSans";
      padding: 0 20px;
      padding-left: 35px; }
      .selectWrapper select:focus ~ img {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1); }
      .selectWrapper select::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.5); }
      .selectWrapper select::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.5); }
      .selectWrapper select:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.5); }
      .selectWrapper select:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.5); }
    .selectWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: left;
      left: 10px;
      top: 10px;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear; } }

@media screen and (max-width: 767px) {
  .btn {
    width: 250px;
    height: 40px;
    line-height: 26px;
    box-sizing: border-box;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    background-color: #444444;
    color: white !important;
    padding: 5px 20px;
    border: 2px solid #444444;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    font-family: "IRANSans" !important; }
    .btn:active {
      opacity: 0.8; }
    .btn:disabled {
      opacity: 0.5; }
  .yellowBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important; }
  .yellowBorderBtn {
    background-color: #ffcc00 !important;
    color: #444444 !important;
    border: none; }
  .En .inputWrapper {
    float: left !important; }
    .En .inputWrapper input[type='text'],
    .En .inputWrapper input[type='email'],
    .En .inputWrapper textarea {
      padding-right: 20px !important;
      padding-left: 35px !important; }
    .En .inputWrapper img {
      float: left !important;
      left: 10px;
      right: auto !important;
      top: 10px;
      filter: invert(1); }
  .inputWrapper {
    max-width: 300px;
    width: 100%;
    height: 40px;
    float: right;
    position: relative; }
    .inputWrapper input[type='text'],
    .inputWrapper input[type='email'],
    .inputWrapper input[type='number'],
    .inputWrapper textarea {
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-size: 12px;
      outline: none;
      border: none;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      padding: 0 20px;
      padding-right: 35px;
      font-family: "IRANSans";
      line-height: 36px; }
      .inputWrapper input[type='text'].success,
      .inputWrapper input[type='email'].success,
      .inputWrapper input[type='number'].success,
      .inputWrapper textarea.success {
        background-color: rgba(0, 100, 0, 0.5); }
      .inputWrapper input[type='text'].error,
      .inputWrapper input[type='email'].error,
      .inputWrapper input[type='number'].error,
      .inputWrapper textarea.error {
        background-color: rgba(100, 0, 0, 0.5); }
      .inputWrapper input[type='text']:focus ~ img,
      .inputWrapper input[type='email']:focus ~ img,
      .inputWrapper input[type='number']:focus ~ img,
      .inputWrapper textarea:focus ~ img {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1); }
      .inputWrapper input[type='text']::-webkit-input-placeholder,
      .inputWrapper input[type='email']::-webkit-input-placeholder,
      .inputWrapper input[type='number']::-webkit-input-placeholder,
      .inputWrapper textarea::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']::-moz-placeholder,
      .inputWrapper input[type='email']::-moz-placeholder,
      .inputWrapper input[type='number']::-moz-placeholder,
      .inputWrapper textarea::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-ms-input-placeholder,
      .inputWrapper input[type='email']:-ms-input-placeholder,
      .inputWrapper input[type='number']:-ms-input-placeholder,
      .inputWrapper textarea:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.5); }
      .inputWrapper input[type='text']:-moz-placeholder,
      .inputWrapper input[type='email']:-moz-placeholder,
      .inputWrapper input[type='number']:-moz-placeholder,
      .inputWrapper textarea:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.5); }
    .inputWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: right;
      right: 10px;
      top: 10px;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      filter: invert(1); }
  .selectWrapper {
    width: 100px;
    height: 40px;
    float: right;
    margin-left: 10px;
    position: relative; }
    .selectWrapper select {
      -moz-appearance: none;
      -webkit-appearance: none;
      width: 100%;
      height: 40px;
      float: right;
      background-color: rgba(0, 0, 0, 0.5);
      border: none;
      color: white;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      box-sizing: border-box;
      outline: none;
      font-family: "IRANSans";
      padding: 0 20px;
      padding-left: 35px; }
      .selectWrapper select::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.5); }
      .selectWrapper select::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.5); }
      .selectWrapper select:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.5); }
      .selectWrapper select:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.5); }
    .selectWrapper img {
      width: 20px;
      height: 20px;
      position: absolute;
      float: left;
      left: 10px;
      top: 10px;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear; } }

@media screen and (min-width: 1200px) {
  ::-webkit-scrollbar {
    width: 10px; }
  ::-webkit-scrollbar-thumb {
    background-color: #444444;
    border-radius: 2.5px;
    -webkit-border-radius: 2.5px;
    -moz-border-radius: 2.5px; }
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 2.5px;
    -webkit-border-radius: 2.5px;
    -moz-border-radius: 2.5px; }
  .En #notFound {
    direction: ltr; }
  #notFound.container {
    overflow: auto;
    padding: 20px calc(50% - 600px) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box; }
    #notFound.container .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 50px;
      box-sizing: border-box;
      flex-direction: row-reverse; }
      #notFound.container .content img {
        height: 400px; }
      #notFound.container .content .description {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }
        #notFound.container .content .description h1 {
          font-size: 7em;
          margin: 20px 0; }
        #notFound.container .content .description p {
          font-size: 2em;
          margin: 0;
          margin-bottom: 20px; } }

@media screen and (max-width: 1200px) and (min-width: 768px) and (orientation: landscape) {
  ::-webkit-scrollbar {
    width: 10px; }
  ::-webkit-scrollbar-thumb {
    background-color: #444444;
    border-radius: 2.5px;
    -webkit-border-radius: 2.5px;
    -moz-border-radius: 2.5px; }
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 2.5px;
    -webkit-border-radius: 2.5px;
    -moz-border-radius: 2.5px; }
  .En #notFound {
    direction: ltr; }
  #notFound.container {
    overflow: auto;
    padding: 20px calc(50% - 600px) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box; }
    #notFound.container .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 50px;
      box-sizing: border-box;
      flex-direction: row-reverse; }
      #notFound.container .content img {
        height: 400px; }
      #notFound.container .content .description {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }
        #notFound.container .content .description h1 {
          font-size: 7em;
          margin: 20px 0; }
        #notFound.container .content .description p {
          font-size: 2em;
          margin: 0;
          margin-bottom: 20px; } }

@media screen and (max-width: 1200px) and (min-width: 768px) and (orientation: portrait) {
  ::-webkit-scrollbar {
    width: 5px; }
  ::-webkit-scrollbar-thumb {
    background-color: #444444;
    border-radius: 2.5px;
    -webkit-border-radius: 2.5px;
    -moz-border-radius: 2.5px; }
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 2.5px;
    -webkit-border-radius: 2.5px;
    -moz-border-radius: 2.5px; }
  .En #notFound {
    direction: ltr; }
  #notFound.container {
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box; }
    #notFound.container .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 50px;
      box-sizing: border-box;
      flex-direction: row-reverse; }
      #notFound.container .content img {
        height: 400px; }
      #notFound.container .content .description {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }
        #notFound.container .content .description h1 {
          font-size: 7em;
          margin: 20px 0; }
        #notFound.container .content .description p {
          font-size: 2em;
          margin: 0;
          margin-bottom: 20px; } }

@media screen and (max-width: 767px) {
  ::-webkit-scrollbar {
    width: 5px; }
  ::-webkit-scrollbar-thumb {
    background-color: #444444;
    border-radius: 2.5px;
    -webkit-border-radius: 2.5px;
    -moz-border-radius: 2.5px; }
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 2.5px;
    -webkit-border-radius: 2.5px;
    -moz-border-radius: 2.5px; }
  .En #notFound {
    direction: ltr; }
  #notFound.container .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    box-sizing: border-box;
    flex-direction: column; }
    #notFound.container .content img {
      height: 400px; }
    #notFound.container .content .description {
      display: flex;
      flex-direction: column;
      align-items: center; }
      #notFound.container .content .description h1 {
        font-size: 4em;
        margin: 20px 0; }
      #notFound.container .content .description p {
        font-size: 1em;
        margin: 0;
        margin-bottom: 20px;
        text-align: center; } }
